
// Reset state to default sendSpecificGift
export const resetSendSpecificGift = (sendSpecificGift, setSendSpecificGift) => {

  if (sendSpecificGift.link.length > 0) {
    setSendSpecificGift({
      type: 'oneItem',
      image: '',
      link: '',
      brand: '',
      product: '',
    })

  }
}

// Set states for new specificGift
export const makeSpecificGift = (setSendSpecificGift, link = '', image = '', brand = '', product = '') => {
  console.log('make specific gift runs')

  setSendSpecificGift({
    type: 'oneItem',
    link: link,
    image: image,
    brand: brand,
    product: product,
  })
}
