/* eslint-disable no-prototype-builtins */
import React from 'react'
import { Helmet } from 'react-helmet-async'

// Change Header of a page for title, seo, and analytics purposes
export const seoPageTags = (title) => {
  return (
    <>
      <Helmet>

        { /* Standard metadata tags */ }
        <title>3D Wrap — Digital Gift Wrapping — {title}</title>
        <meta name='description' content={'Digitally wrap and unwrap presents online, in 3D. The best, easiest, greenest way to give, receive, and open a gift. The best virtual giftwrap experience.'} />

        {/* Referrer Policy */}
        <meta name="referrer" content="origin" />

        { /* Facebook tags */ }
        <meta property="og:type" content={'website'} />
        <meta property="og:title" content={`3D Wrap — Digital Gift Wrapping — ${title}`} />
        <meta property="og:description" content={'Digitally wrap and unwrap presents online, in 3D. The best, easiest, greenest way to give, receive, and open a gift. The best virtual giftwrap experience.'} />

        {/* Yandex Verification */}
        <meta name="yandex-verification" content="08a79e53272c53b3" />

        { /* Twitter tags */ }
        <meta name="twitter:creator" content={'3D Wrap'} />
        <meta name="twitter:card" content={'website'} />
        <meta name="twitter:title" content={`3D Wrap — Digital Gift Wrapping — ${title}`} />
        <meta name="twitter:description" content={'Digitally wrap and unwrap presents online, in 3D. The best, easiest, greenest way to give, receive, and open a gift. The best virtual giftwrap experience.'} />

        {/* <!-- Google Sign In — Creates Global google Variable --> */}
        <script src="https://accounts.google.com/gsi/client" async defer></script>

        {/* <!-- Facebook Sign In --> */}
        <script>
          {`
            window.fbAsyncInit = function() {
              FB.init({
                appId      : '683776696442101',
                // cookie     : true,
                // xfbml      : true,
                // version    : 'v1.x'
              })
              // FB.AppEvents.logPageView() 
            }

            (function(d, s, id){
              var js, fjs = d.getElementsByTagName(s)[0]
              if (d.getElementById(id)) {return}
              js = d.createElement(s); js.id = id
              js.src = "https://connect.facebook.net/en_US/all.js"
              fjs.parentNode.insertBefore(js, fjs)
            }(document, 'script', 'facebook-jssdk'))
          `}
        </script>

        {/* Google Analytics — Must be at bottom of Helmet */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
          `}
        </script>
      </Helmet>
    </>
  )
}

// Send a custom analytics event to Google Analytics
export const customAnalyticsEvent = (eventName, value, category, label, screenName) => {
  if (typeof window !== 'undefined' && window.hasOwnProperty('gtag')) {
    window.gtag('event', eventName, {
      value: value,
      event_category: category,
      event_label: label,
      screen_name: screenName,
    })
  }
}