import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import { getPayload, getTokenFromLocalStorage, userIsAuthenticated, getUsernameFromLocalStorage, setCurrentProfileToLocalStorage } from '../../helpers/storage.js'
import { userProfileImportantDates } from '../../helpers/userProfileImportantDates.js'
import { tabElement } from '../../helpers/profileTabLists.js'
import { holidays, holidayEmojis, positionChangeWidthSm } from '../../helpers/variableDefaults'
import { standardButton } from '../../helpers/buttons'
import { seoPageTags } from '../../helpers/analytics.js'

import useWindowDimensions from '../../helpers/windowDimensions.js'

//mui
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'


// User Profile
const UserProfile = (props) => {

  // For sending gifts directly from wishlist, gift given/received cards
  const { sendSpecificGift, setSendSpecificGift } = props

  // Navigate
  const navigate = useNavigate()
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  //Params — username for profile
  const { username } = useParams()

  // My username
  const myUsername = getUsernameFromLocalStorage()

  //Payload
  const payload = getPayload()

  //Keeps track of which tab we are in, default is Wishlist at index 0
  const [value, setValue] = useState(0)

  //loading and error state
  const [loading, setLoading] = useState(false) 
  const [errors, setErrors] = useState(false)

  //tab arrays  
  const [wishlist, setWishlist] = useState([])
  const [giftsGiven, setGiftsGiven] = useState([])
  const [giftsReceived, setGiftsReceived] = useState([])
  const [friends, setFriends] = useState([])

  // Friendship States 
  const [friendButtonText, setFriendButtonText] = useState(username === myUsername ? 'Find Friends' : 'Add Friend')
  const [friendshipId, setFriendshipId] = useState('')
  
  // User data
  const [user, setUser] = useState({
    id: '',
    username: username,
    bio: '',
    profilePicture: '',
    accountPrivate: false,
    showGiftsGiven: true,
    showGiftsReceived: true,
    showWishlist: true,
    showFriends: true,
    importantDates: {},
    holidays: [],
  })

  // Called in the Use Effect, populates the wishlist and friends arrays
  const getArrayItemsFromIds = async (tabName = '', arrayOfIndexes = [], profileUserId = '') => { 
    const notFriendsArray = [] //For wishlist items
    const acceptedFriendsArray = [] //For accepted friends
    const requestedFriendsArray = [] //For pending friends requested by current user
    const pendingFriendsArray = [] //For pending friends requested to current user
    let arrayToReturn = []
    for (let i = 0; i < arrayOfIndexes.length; i++) {
      // const response = await axios.get(`/api/${tabName}/${arrayOfIndexes[i]}`)
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/${tabName}/${arrayOfIndexes[i]}`)

      if (tabName === 'friends') {

        // Get profile of person whose profile is not being viewed
        const friendProfile = response.data.profiles.filter(profile => profile.username !== username )
        response.data.friendProfile = friendProfile[0]
        delete response.data.profiles

        // Determine text for friend button
        if (response.data.friendProfile.username === myUsername) {
          if (response.data.requestStatus === 'Accepted') {
            setFriendButtonText('Remove Friend')
            setFriendshipId(response.data._id)
          } else if (response.data.requestStatus === 'Pending' && response.data.requestedBy === payload.sub) {
            setFriendButtonText('Requested')
            setFriendshipId(response.data._id)
          } else if (response.data.requestStatus === 'Pending') {
            setFriendButtonText('Accept Friend')
            setFriendshipId(response.data._id)
          }
        }

        // Segment friends by request Status
        if (response.data.requestStatus === 'Accepted') {
          acceptedFriendsArray.push(response.data)
        } else if (response.data.requestStatus === 'Pending' && response.data.requestedBy === profileUserId) {
          requestedFriendsArray.push(response.data)
        } else if (response.data.requestStatus === 'Pending') {
          pendingFriendsArray.push(response.data)
        }

      } else {
        // make wishlist array from response data
        notFriendsArray.push(response.data)
      }

    }

    
    if (tabName === 'friends') {
      if (username === myUsername) {
        // Show pending friends on person's own profile
        arrayToReturn = [ ...pendingFriendsArray, ...acceptedFriendsArray, ...requestedFriendsArray ]
      } else {
        // Only show accepted friends to the public
        arrayToReturn = acceptedFriendsArray
      }
    } else {
      // Wishlist Items — sorted to show promotions first
      arrayToReturn = await notFriendsArray.sort((a, b) => a.isPromotion < b.isPromotion ? 1 : -1)
    }

    return arrayToReturn
  }

  
  // Get User Data
  useEffect(() => {

    if (!userIsAuthenticated()) {
      navigate(`/login`)

    } else {

      const getData = async () => {
        try {
          //Set loading while retrieving data
          setLoading(true)

          // Reset to Wishlist tab
          setValue(0)

          // Get data for specified user, by username
          // const { data } = await axios.get(`/api/profile/user/${username}`, {
          const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/profile/user/${username}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          const retrievedUser = data[0]
          // console.log('retrieved user ->', retrievedUser)
          
          // Set Gifts Given and Gifts received
          setGiftsGiven(retrievedUser.giftsGiven)
          setGiftsReceived(retrievedUser.giftsReceived)

          // Search for wishlist items and set it
          if (retrievedUser.wishlist.length > 0) {
            setWishlist(await getArrayItemsFromIds('giftideas', retrievedUser.wishlist, retrievedUser._id))
          }

          // Search for friends and set friend states
          setFriendButtonText('Add Friend')
          setFriends([])
          if (retrievedUser.friends.length > 0) {

            const userFriends = await getArrayItemsFromIds('friends', retrievedUser.friends, retrievedUser._id)
            setFriends(userFriends)

            // ?  Only friends can view each others profiles
            // if (username !== myUsername) {
            //   let areFriends = 0
            //   for (let i = 0; i < userFriends.length; i++) {
            //     if (userFriends[i].friendProfile.username === myUsername) {
            //       areFriends = 1
            //     }
            //   }
            //   if (areFriends === 0) {
            //     navigate(`/profile/${myUsername}`)
            //   }
            // }

          } else if (username !== myUsername) {
            // ? Only friends can view each others profiles
            // navigate(`/profile/${myUsername}`)
          }
          
          //Update user
          const currentUser = { 
            id: retrievedUser._id,
            username: retrievedUser.username,
            bio: retrievedUser.bio,
            profilePicture: retrievedUser.profilePicture,
            accountPrivate: retrievedUser.accountPrivate,
            showGiftsGiven: retrievedUser.showGiftsGiven,
            showGiftsReceived: retrievedUser.showGiftsReceived,
            showWishlist: retrievedUser.showWishlist,
            showFriends: retrievedUser.showFriends,
            importantDates: retrievedUser.importantDates,
            holidays: retrievedUser.holidays,
          }
          setUser(currentUser)

          // Update current profile in local storage if it is the user's own profile
          if (username === myUsername) {
            window.localStorage.removeItem('currentProfile')
            setCurrentProfileToLocalStorage(currentUser)
          }

          // Unset loading after successfully retrieving data
          setLoading(false)

        } catch (error) {
          console.log(error)

          // Unset loading after and report errors retrieving data
          setLoading(false)
          setErrors(true)
        }

        // Unset loading in case try/catch malfunctions somehow
        setLoading(false)
      }
      
      getData()
    }

  }, [navigate])

  // Helps define the tab indexes
  const makeTabIndex = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  // Handles tab changes
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  // Handles the edit button
  const handleEdit = (e) => {
    e.preventDefault()
    if (username === myUsername) {
      navigate(`/profile/${username}/edit`)
    }
  }

  // Execute friend button click
  const handleFriendButtonClick = async (e) => {
    e.preventDefault()

    if (e.currentTarget.innerText === 'ACCEPT FRIEND') {
      // Accept Friend
      try {
        const acceptedRequest = {
          requestStatus: 'Accepted',
        }
        // await axios.put(`/api/friends/${friendshipId}`, acceptedRequest, {
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/friends/${friendshipId}`, acceptedRequest, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })
        
        setFriendButtonText('REMOVE FRIEND')
  
      } catch (error) {
        console.log(error)

        setErrors(true)
      }

    } else if (e.currentTarget.innerText === 'REJECT' || e.currentTarget.innerText === 'PENDING' || e.currentTarget.innerText === 'REQUESTED' || e.currentTarget.innerText === 'REMOVE FRIEND') {
      // REJECT/PENDING/REQUESTED/REMOVE FRIEND
      try {
        // await axios.delete(`/api/friends/${friendshipId}`, {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/friends/${friendshipId}`, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        setFriendButtonText('ADD FRIEND')

        // Navigate automatically to your own profile after Removing a friend
        navigate(`/profile/${myUsername}`)
  
      } catch (error) {
        console.log(error)

        setErrors(true)
      }
    } else if (e.currentTarget.innerText === 'ADD FRIEND') {
      // ADD FRIEND
      try {
        const friendRequestData = {
          users: [payload.sub, user.id],
          requestedBy: payload.sub,
        }
        // await axios.post(`/api/friends`, friendRequestData, {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/friends`, friendRequestData, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        setFriendButtonText('REQUESTED')
  
      } catch (error) {
        console.log(error)

        setErrors(true)
      }
    } 
  }

  // navigate to /findFriends when find friends button pressed
  const handleFindFriends = async (e) => { 
    e.preventDefault()

    navigate(`/findfriends`)
  }

  // navigate to /sendgift when send gift button pressed
  const handleSendGiftButtonClick = async (e) => {
    e.preventDefault()
    
    navigate(`/sendgift`)
  }

  // navigate to /giftideas when wishlist button pressed
  const handleWishlistButtonClick = async (e) => {
    e.preventDefault()
    
    navigate('/giftideas')
  }


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Profile'
      )}

      {/* UserProfile Body */}
      <Container key={0} maxWidth='lg' sx={{ flexGrow: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
        
        {/* Upper Profile, above tabs */}
        <Box key={1} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          
          {/* Profile Picture */}
          <Grid key={3} item xs={3} sm={4} >
            <Avatar alt={user.username} src={user.profilePicture} sx={{ width: 96, height: 96, boxShadow: 4 }} />
          </Grid>

          {/* About Me */}
          <Grid key={4} item xs={8} sm={6} sx={{ ml: width >= positionChangeWidthSm ? 8 : 2 }} >
            <Stack spacing={0} >

              {/* Username; if it's a user's own profile, an edit button will show beside it */}
              {username === myUsername ?
                <>
                  <Box key={5} sx={{ flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                    <Typography key={6} variant="h6">{user.username}</Typography>
                    {standardButton(
                      'Edit', 
                      'button',
                      'text',
                      false,
                      'primary', 
                      0,
                      0,
                      0,
                      '60px',
                      '30px',
                      handleEdit
                    )}
                  </Box>
                </>
                :
                <>
                  <Typography key={8} variant="h6">{user.username}</Typography>
                </>
              }

              {/* Bio */}
              <Box key={9} sx={{ maxWidth: '245px', flexGrow: 1, display: 'flex', flexWrap: 'wrap' }} >
                {errors ? 
                  <Typography key={10} sx={{ mt: 0, mb: 0, width: '100%', color: 'red' }}> Error! Could not fetch bio </Typography>
                  :
                  <Typography key={11} sx={{ mt: 0, mb: 0, width: '100%' }}>{user.bio}</Typography>
                }
              </Box>

              {/* Important Dates */}
              <Box key={12} sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', mt: 0, mb: 0 }}>
                {user.importantDates.birthday !== undefined && userProfileImportantDates(user.importantDates)}
              </Box>

              {/* Holidays */}
              {user.holidays.length > 0 &&
                <Typography>
                  {user.holidays.map((holiday, index) => holidayEmojis[user.holidays[index]])}
                </Typography>
              }

              {/* Friend Button; only shows when not on User's own profile */}
              {/* {username !== myUsername &&
                <Box key={13} sx={{ mt: 0.5, mb: 0.5, display: 'flex', justifyContent: 'flex-start' }}>
                  {standardButton(
                    friendButtonText, 
                    'button',
                    'contained',
                    false,
                    'primary', 
                    1,
                    0,
                    0,
                    '150px',
                    '38px',
                    handleFriendButtonClick
                  )}
                </Box>
              } */}
            </Stack>
          </Grid>
        </Box>


        {/* Tabs */}
        <Box key={15} sx={{ mt: 2 }}>
          <Tabs
            key={16}
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
            variant="scrollable"
            sx={{ 
              width: '90vw', maxWidth: '375px',
              borderTop: 1, borderColor: 'divider',
            }}
          >
            <Tab key={17} label="Wishlist" {...makeTabIndex(0)} />
            <Tab key={18} label="Given" {...makeTabIndex(1)} />
            <Tab key={19} label="Received" {...makeTabIndex(2)} />
            <Tab key={20} label="Friends" {...makeTabIndex(3)} />
          </Tabs>
        </Box>

        {/* Tab Panels */}
        {/* Wishlist  */}
        {tabElement('wishlist', wishlist, setWishlist, handleWishlistButtonClick, value, 0, loading, errors, username, myUsername, user.accountPrivate, user.showWishlist, user.showGiftsGiven, user.showGiftsReceived, user.showFriends, setSendSpecificGift, width)}
        
        {/* Gifts Given  */}
        {tabElement('given', giftsGiven, setGiftsGiven, handleSendGiftButtonClick, value, 1, loading, errors, username, myUsername, user.accountPrivate, user.showWishlist, user.showGiftsGiven, user.showGiftsReceived, user.showFriends, setSendSpecificGift, width)}
        
        {/* Gifts Received  */}
        {tabElement('received', giftsReceived, setGiftsReceived, handleSendGiftButtonClick, value, 2, loading, errors, username, myUsername, user.accountPrivate, user.showWishlist, user.showGiftsGiven, user.showGiftsReceived, user.showFriends, setSendSpecificGift, width)}
        
        {/* Friends  */}
        {tabElement('friends', friends, setFriends, handleFindFriends, value, 3, loading, errors, username, myUsername, user.accountPrivate, user.showGiftsGiven, user.showWishlist, user.showGiftsReceived, user.showFriends, setSendSpecificGift, width)}
        
      </Container>
    </>
  )
}

export default UserProfile