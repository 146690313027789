import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { makeSquareImage, uploadAWSFileReactS3 } from '../../helpers/imageHandling'
import { getPayload, getTokenFromLocalStorage, setProfPicToLocalStorage, setCurrentProfileToLocalStorage, getCurrentProfileFromLocalStorage, getUsernameFromLocalStorage } from '../../helpers/storage'
import { handleChange } from '../../helpers/globalHelpers'
import { holidaySelection, birthdayAnniversaryElements, privacyElements } from '../../helpers/editProfileElements'
import { profPicDefaultURL } from '../../helpers/variableDefaults'
import { replaceImageWithIconButton, standardSubmitButton, standardButton } from '../../helpers/buttons'
import { editBioTextField } from '../../helpers/textfields'
import { standardErrorContainer } from '../../helpers/errors'
import useWindowDimensions from '../../helpers/windowDimensions'
import { standardSpinner } from '../../helpers/spinners'
import { seoPageTags } from '../../helpers/analytics'

//mui
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { Input } from '../../helpers/globalHelpers'


const EditProfile = (props) => {

  // UseNavigate
  const navigate = useNavigate()

  // Payload
  const payload = getPayload()
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Username
  const { username } = useParams()
  const myUsername = getUsernameFromLocalStorage()

  // Current profile from local storage
  const cp = JSON.parse(getCurrentProfileFromLocalStorage())

  // Form Data
  const [ formData, setFormData ] = useState(cp ? 
    cp 
    : 
    {
      id: 'no-id',
      username: myUsername,
      bio: 'no-bio',
      profilePicture: profPicDefaultURL,
      accountPrivate: false,
      showGiftsGiven: true,
      showGiftsReceived: true,
      showWishlist: true,
      showFriends: true,
      importantDates: {},
      holidays: [],
    }
  )

  //loading and error state
  const [loading, setLoading] = useState(false) 
  const [errors, setErrors] = useState(false) //General errors

  // Form states
  const [birthdayChecked, setBirthdayChecked] = useState(cp ? cp.importantDates.birthday.visible ? cp.importantDates.birthday.visible : false : false)
  const [anniversaryChecked, setAnniversaryChecked] = useState(cp ? cp.importantDates.anniversary.visible ? cp.importantDates.anniversary.visible : false : false)
  const [accountPrivateChecked, setAccountPrivateChecked] = useState(cp ? cp.accountPrivate ? cp.accountPrivate : false : false)
  const [showWishlistChecked, setShowWishlistChecked] = useState(cp ? cp.showWishlist ? cp.showWishlist : true : true)
  const [showGiftsGivenChecked, setShowGiftsGivenChecked] = useState(cp ? cp.showGiftsGiven ? cp.showGiftsGiven : true : true)
  const [showGiftsReceivedChecked, setShowGiftsReceivedChecked] = useState(cp ? cp.showGiftsReceived ? cp.showGiftsReceived : true : true)
  const [showFriendsChecked, setShowFriendsChecked] = useState(cp ? cp.showFriends ? cp.showFriends : true : true)

  // Dates
  const [birthdayValue, setBirthdayValue] = useState(cp ? cp.importantDates.birthday.date ? cp.importantDates.birthday.date : null : null)
  const [anniversaryValue, setAnniversaryValue] = useState(cp ? cp.importantDates.anniversary.date ? cp.importantDates.anniversary.date : null : null)


  // Adding a new image when the user selects the PhotoCamera button
  const handleImageSelect = async (e) => {
    setErrors(false)

    // Center image and crop it into a 600x600 jpg; save a dataURL onto the formdata as profilePicture
    makeSquareImage(e.target.files[0], formData, setFormData)
  }

  // Holiday Selection
  const handleHolidaySelection = (e) => {

    setErrors(false)

    // Add or Remove the selected holiday to or from the holidays array
    const updatedHolidays = formData.holidays
    if (updatedHolidays.includes(e.target.textContent)) {
      const indexOfHoliday = updatedHolidays.indexOf(e.target.textContent)
      if (indexOfHoliday > -1) {
        updatedHolidays.splice(indexOfHoliday, 1)
      }
    } else {
      updatedHolidays.push(e.target.textContent)
    }

    // Set the form
    setFormData({ ...formData, holidays: updatedHolidays })

    // toggle the 'styled' class for the event target
    e.target.classList.toggle('styled')
  }

  // Submitting the new profile
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Set loading to true and errors to false
    setLoading(true)
    setErrors(false)

    if (formData.profilePicture === '') {
      // Profiles must include a profile image

      setErrors(true)
      setLoading(false)
    } else if (formData !== cp) {
      // Create a new form so that you can pass up the AWS URL
      let newForm = { ...formData }

      // Update the Important Dates
      if (birthdayValue) {
        newForm.importantDates.birthday.date = birthdayValue
      }
      if (anniversaryValue) {
        newForm.importantDates.anniversary.date = anniversaryValue
      }

      // Get AWS image url for profile picure if the profile picture has changed
      if (cp && (formData.profilePicture !== cp.profilePicture)) {
        
        // AWS Upload
        const awsImageLink = await uploadAWSFileReactS3(formData.profilePicture, 'user', `${myUsername}-${Date.now()}`)
        
        // Set AWS url to the newForm.profile picture and delete the old profile picture from the AWS server 
        if (awsImageLink && awsImageLink.length > 0 && awsImageLink !== 'No URL') {
          
          newForm = { ...newForm, profilePicture: awsImageLink }

          // Delete old prof pic from AWS if it is not the default prof pic
          if (cp 
            && cp.profilePicture.includes(myUsername) 
            && cp.profilePicture.includes(process.env.REACT_APP_S3_USER_BUCKET)
            && !cp.profilePicture.includes(`seed-${myUsername}`)
          ) {
            const requestObject = {
              bucketType: 'user',
              urlString: cp.profilePicture,
            }
            // axios.delete(`/api/awsImageURL`, {
            axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/awsImageURL`, {
              headers: {
                Authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
              data: requestObject,
            })
          }
        } else {
          console.log('image link failed')
        }

      }

      // Modify Profile
      try {

        // PUT request with updated profile object
        // await axios.put(`/api/profile/${payload.sub}`, newForm, {
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/profile/${payload.sub}`, newForm, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        // update local storage
        window.localStorage.removeItem('currentProfile')
        setCurrentProfileToLocalStorage(newForm)

        window.localStorage.removeItem('profPic')
        setProfPicToLocalStorage(newForm.profilePicture)

        // Navigate to: UserAccount
        setLoading(false)
        navigate(`/profile/${formData.username}`, { replace: true })

      } catch (error) {
        console.log(error)

        // error message posting new profile
        setLoading(false)
        setErrors(true)
      }

      setLoading(false)
    } else {
      // Navigate to: UserAccount
      setLoading(false)
      navigate(`/profile/${formData.username}`, { replace: true })
    }
  }

  // Navigate back to user profile if the user clicks cancel
  const handleCancel = async (e) => {
    e.preventDefault()
    navigate(`/profile/${formData.username}`)
  }

  // On pageload, navigate to login if user does not have a username; to profile if they don't have a currentProfile; or to their own edits if the username doesn't match the username in params
  useEffect(() => {
    if (!myUsername) {
      navigate(`/login`)
    } else if (!cp) {
      navigate(`/profile/${myUsername}`)
    } else if (username !== myUsername) {
      navigate(`/profile/${myUsername}/edit`)
    }
  }, [navigate])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Edit Profile'
      )}

      {/* EditProfile Body */}
      <Container width='sm' sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper elevation={6} sx={{ m: 5, py: 3, backgroundColor: 'cream' }} >
          {
            loading ?
              standardSpinner()
              :
              <>
                {/* Edit Profile Form */}
                <Box
                  component='form'
                  sx={{
                    width: '80vw',
                    maxWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onSubmit={handleSubmit}
                >

                  {/* Title */}
                  <Typography variant='h4' sx={{ pb: 2, mb: 1 }}>Edit Profile</Typography>
                  
                  <Box
                    sx={{ 
                      width: .90, 
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center', 
                      alignItems: 'center',
                    }}
                  >

                    {/* This must be first input So that the file upload only fires when you press the button */}
                    <Box sx={{ width: '100%' }}>
                      <Input type="text" autoFocus="autoFocus" />
                    </Box>

                    {/* Profile Picture */}
                    {replaceImageWithIconButton(
                      formData.profilePicture, 
                      width > 399 ? 300 : width > 299 ? 225 : 175, 
                      'icon-button-file', 
                      'replace-prof-pic', 
                      handleImageSelect,
                      -1
                    )}

                    {/* Bio Textfield */}
                    <Box sx={{ mb: 2, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      {editBioTextField(
                        'bio',
                        'Bio',
                        'Bio * (max 500 characters)',
                        formData.bio,
                        handleChange,
                        setErrors, 
                        setFormData, 
                        formData
                      )}
                    </Box>

                    {/* Important Dates */}

                    {/* Title */}
                    <Box sx={{ width: '90%', display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant='h6' sx={{ mb: 2, mt: 3 }}>
                        Important Dates
                      </Typography>
                    </Box>
                    
                    {/* Birthday, Anniversary, and Holidays */}
                    <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                      {/* Birthday */}
                      {birthdayAnniversaryElements('Birthday', 'birthdaySwitch', birthdayValue, setBirthdayValue, birthdayChecked, setBirthdayChecked, formData, setFormData, setErrors)}
                      
                      {/* Anniversary */}
                      {birthdayAnniversaryElements('Anniversary', 'anniversarySwitch', anniversaryValue, setAnniversaryValue, anniversaryChecked, setAnniversaryChecked, formData, setFormData, setErrors)}
                      
                      {/* Holidays */}
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', my: 2 }}>
                        {holidaySelection(formData.holidays, handleHolidaySelection)}
                      </Box>
                    </Box>

                    {/* Privacy */}
                    <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                      
                      {/* Title */}
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography variant='h6' sx={{ mb: 1, mt: 3 }}>
                          Privacy
                        </Typography>
                      </Box>
                      
                      {/* Account Private */}
                      {/* {privacyElements('accountPrivate', 'Account Private', formData.accountPrivate, setAccountPrivateChecked, formData, setFormData, setErrors )} */}
                      
                      
                      {/* Show Wishlist */}
                      {/* {privacyElements('showWishlist', 'Show Wishlist', formData.showWishlist, setShowWishlistChecked, formData, setFormData, setErrors )} */}
                      

                      {/* Show Gifts Given */}
                      {privacyElements('showGiftsGiven', 'Show Gifts Given', formData.showGiftsGiven, setShowGiftsGivenChecked, formData, setFormData, setErrors )}
                      

                      {/* Show Gifts Received */}
                      {privacyElements('showGiftsReceived', 'Show Gifts Received', formData.showGiftsReceived, setShowGiftsReceivedChecked, formData, setFormData, setErrors )}
                      
                      
                      {/* Show Friends */}
                      {privacyElements('showFriends', 'Show Friends', formData.showFriends, setShowFriendsChecked, formData, setFormData, setErrors )}
                      
                    </Box>

                    {/* Error Message */}
                    {errors &&
                      standardErrorContainer(
                        'Error. Failed to upload updated profile',
                        1,
                        0
                      )
                    }


                    {/* Submit and Cancel Buttons */}
                    <Box textAlign='center' sx={{ mt: 1, mb: 4, width: .9, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {/* Submit Button */}
                      <Box sx={{ width: .45 }}>
                        {standardSubmitButton('Submit', 'submit', false, 'primary', 0, 0, 1, 1)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
          }
        </Paper>
      </Container >
    </>
  )
}

export default EditProfile