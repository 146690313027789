import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { customQuestionTextField } from '../../../helpers/textfields'
import { questionChoiceCheckbox } from '../../../helpers/checkboxes'
import { dummyBox } from '../../../helpers/globalHelpers'

const GiftQuestions = (props) => {

  // Destructure Props
  const { questions, setQuestions, typeChosen } = props

  // Update view whenever the questions object changes
  useEffect(() => {

  }, [questions])

  // Handles updates to the questions object
  const handleSetQuestions = async (e, question, questionIndex) => {
    e.preventDefault()

    // Destructure questions
    const editedQuestions = [ ...questions ]

    // If it's the last question (custom question), set question.q equla to the text and checked to true
    if (questionIndex === questions.length - 1) {

      // question.checked = question.q ? true : false
      if (e.target.value.length === 0) {
        question.checked = false
        question.q = e.target.value
      } else if ((e.target.value.length > 0 ) && e.target.value === 'on') {
        question.checked = !question.checked
      } else if (e.target.value.length > 0) {
        question.checked = true
        question.q = e.target.value
      }
      
    } else {
      // Otherwise, just toggle the checkmark
      question.checked = !question.checked
    }

    // Update the question at the selected index
    editedQuestions[questionIndex] = question

    // Uncheck everything else if 'No questions' is checked; Uncheck 'No questions' if something else is checked; Check 'No questions' if nothing else is checked; Otherwise just setQuestions
    if (editedQuestions[questionIndex].q === 'No questions' && editedQuestions[questionIndex].checked) {
      const otherQuestionChecksToFalse = editedQuestions.map( (questionInsideMap, indexInsideMap) => {
        return questionInsideMap.q === 'No questions' ? { q: questionInsideMap.q, checked: true } : { q: indexInsideMap === questions.length - 1 ? '' : questionInsideMap.q, checked: false }
      })
      setQuestions(otherQuestionChecksToFalse)
    } else if (questions[0].q === 'No questions' && editedQuestions[questionIndex].checked) {
      const noQuestionsIsFalse = [ ...editedQuestions ]
      noQuestionsIsFalse[0].checked = false
      setQuestions(noQuestionsIsFalse)
    } else if (questions[0].q === 'No questions' && !editedQuestions[questionIndex].checked) {
      // Check if nothing is checked
      let questionsCheckedCount = 0
      for (let i = 0; i < questions.length; i++) {
        questionsCheckedCount = editedQuestions[i].checked ? questionsCheckedCount + 1 : questionsCheckedCount
      }

      // If no questions are checked, check the 'No questions' box; else set questions
      if (questionsCheckedCount === 0) {
        const noQuestionsShouldBeTrue = [ ...editedQuestions ]
        noQuestionsShouldBeTrue[0].checked = true
        setQuestions(noQuestionsShouldBeTrue)
      } else {
        setQuestions(editedQuestions)
      }

    } else {
      setQuestions(editedQuestions)
    }
  }

  return (
    <>

      {/* Dummy Box to get spacing right */}
      {dummyBox('40px')}

      {/* Question and Checkbox elements */}
      <Box 
        sx={{ 
          width: '100%', maxWidth: '500px',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch', 
        }}
      >
        <Box 
          sx={{
            width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Typography sx={{ mb: 0, mt: 2 }}><strong>Questions for Recipient</strong></Typography>



          {/* Questions Instructions */}
          <Typography
            textAlign={'center'}
            component="pre"
            sx={{
              mb: 1,
              width: '95%',
              pl: '5px', pr: '5px',
              fontSize: '14px',
              fontStyle: 'italic',
              color: 'purple',
            }}
          >
            {
              `*To minimize the likelihood of returns`.replace(/ {5} +/g, '') //the regex is for deleting the indentation spaces in the string literal
            }
          </Typography>
        </Box>
        
        {/* Questions */}
        {questions[0] && questions.map((question, questionIndex) => (
          <Box key={questionIndex} 
            sx={{ 
              ml: 1, mr: 1, 
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap',
              overflow: 'visible',
            }}
          >
            {/* Checkbox */}
            {questionChoiceCheckbox(
              (
                (typeChosen === 'chooseOne' && questionIndex === 0) 
                || ((questionIndex === questions.length - 1 && question.q === '') ? true : false)
              ),
              question.q,
              question.checked,
              handleSetQuestions,
              question,
              questionIndex
            )}

            {/* Question Typopraphy for everything except the final custom question; Textfield for the final custom question */}
            {questionIndex !== questions.length - 1 ? 
              <Typography variant='p' sx={{ ml: 0, mr: 0, width: 'calc(100% - 60px)' }}>
                {question.q}
              </Typography>
              :
              customQuestionTextField(
                'custom', 
                'Custom question', 
                questions[questionIndex].q, 
                handleSetQuestions, 
                question, 
                questionIndex
              )
            }
          </Box>
        ))}
      </Box>
    </>
  )
}

export default GiftQuestions