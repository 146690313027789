/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

import React from 'react'

// mui
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { Link } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { makeOutsideGiftLink } from './cardHelpers'

import PhotoCamera from '@mui/icons-material/PhotoCamera'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { ReactComponent as TikTokIcon } from '../assets/tiktok-logo.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

import { Input } from './globalHelpers'

// Standard Button
export const standardButton = (buttonText, type, variant, disabled = false, color, topMargin, bottomMargin, marginX, buttonWidth, buttonHeight, clickHandler) => {
  return (
    <Button 
      disabled={disabled}
      type={type}
      fullWidth
      variant={variant}
      color={color}
      onClick={clickHandler} 
      sx={{ 
        mt: topMargin, 
        mb: bottomMargin, 
        mx: marginX, 
        width: buttonWidth,
        height: buttonHeight,
      }}
    >
      {buttonText}
    </Button>
  )
}

// Standard Submit Button
export const standardSubmitButton = (buttonText, type, disabled = false, color, topMargin, bottomMargin, buttonWidth, buttonHeight) => {
  return (
    <Button
      disabled={disabled}
      type={type}
      fullWidth
      variant="contained"
      color={color}
      sx={{ 
        mt: topMargin, 
        mb: bottomMargin, 
        width: buttonWidth,
        height: buttonHeight,
      }}
    >
      {buttonText}
    </Button>
  )
}

// Handle Switch Between Login and Register
export const handleSwitchLoginRegisterButton = (buttonText, changeHandler, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister) => {
  return (
    <Button
      onClick={(e) => changeHandler(e, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)} 
      color='primary'
    >
      {buttonText}
    </Button>
  )
}

// Replace an Existing image  — Image element with photoCamera IconButton 
export const replaceImageWithIconButton = (srcImage, dimension, id, name, changeHandler, mb) => {
  return (
    <Box sx={{ mb: mb, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
      <Box component='img' src={srcImage} alt='Image to upload' sx={{ height: dimension, width: dimension, objectFit: 'cover', boxShadow: 2 }} />
      <label htmlFor={id}>
        <Input 
          id={id} 
          name={name} 
          className={name} 
          accept="image/*" 
          type="file" 
          onChange={changeHandler} 
        />
        <IconButton aria-label="upload picture" component="span" sx={{ bottom: 50, border: 2, borderColor: 'white', boxShadow: 3, backgroundColor: 'rgba(170,170,170,0.5)' }} >
          <PhotoCamera fontSize="small"/>
        </IconButton>
      </label>
    </Box>
  )
}

// Add new Image Button — button with text inside that's intertwined with an add image label/Input
export const addImageWithTextButton = (buttonText, id, name, changeHandler) => {
  return (
    <label htmlFor={id}>
      <Input 
        id={id} 
        name={name} 
        className={name} 
        accept="image/*" 
        type="file" 
        onChange={changeHandler}
      />
      <Button variant="contained" component="span" sx={{ mb: 1 }}>
        {buttonText}
      </Button>
    </label>
  )
}

// Delete a gift item; Occurs in the Options view of the send gift process
export const deleteItemIconButton = (itemIndex, optionIndex, clickHandler) => {
  return (
    <Box 
      className={`${itemIndex}-item ${optionIndex}-option`} 
      onClick={clickHandler} 
      sx=
        {{ 
          borderRadius: '50%', display: 'flex', alignItems: 'center', 
        }} 
    >
      <IconButton 
        aria-label="delete-item" 
        name={`${itemIndex}-index`}
        className={`${itemIndex}-index`} 
        sx={{ 
          width: '30px', height: '30px', 
          mb: 1, mt: 1, bottom: 0, left: 0, 
          border: 2, borderColor: 'white', boxShadow: 3, 
        }} 
      >
        <CloseOutlinedIcon 
          key={`${itemIndex}`} 
          fontSize="small" 
          color='error' 
          sx={{  }} 
        />
      </IconButton>
    </Box>
  )
}

// Social Media Buttons Elements
export const socialMediaButtons = (flexDirection = 'row', justifyContent = 'flex-end', alignItems = 'center', bottomMargin = 0, fromFooter = false) => {
  return (
    <Box sx={{ 
      mb: bottomMargin,
      display: 'flex', justifyContent: { justifyContent }, flexDirection: { flexDirection }, alignItems: { alignItems }, 
    }}>
            
      <Link href="https://www.instagram.com/3d.wrap/" className='tiktok' target="_blank" >
        <IconButton id='instagram' aria-label="upload picture" component="span" sx={{ width: '40px', height: '40px', m: 1, border: 2, borderColor: fromFooter ? 'pink' : 'mediumorchid', boxShadow: 3, backgroundColor: fromFooter ? 'white' : 'rgba(170,170,170,0.5)', '&:hover': { backgroundColor: fromFooter ? 'pink' : 'mediumorchid' } }} >
          <InstagramIcon sx={{ color: '#000000' }}/>
        </IconButton>
      </Link>

      <Link href="https://www.tiktok.com/@3d.wrap" className='tiktok' target="_blank" >
        <IconButton id='tiktok' aria-label="upload picture" component="span" sx={{ width: '40px', height: '40px', m: 1, border: 2, borderColor: fromFooter ? 'pink' : 'mediumorchid', boxShadow: 3, backgroundColor: fromFooter ? 'white' : 'rgba(170,170,170,0.5)', '&:hover': { backgroundColor: fromFooter ? 'pink' : 'mediumorchid' } }} >
          <TikTokIcon color='primary' sx={{ color: '#000000' }}/>
        </IconButton>
      </Link>
            
      <Link href="https://www.youtube.com/channel/UCSxlfIDKJu2FT2BoqnHHUXw" className='youtube' target="_blank" >
        <IconButton id='youtube' aria-label="upload picture" component="span" sx={{ width: '40px', height: '40px', mr: fromFooter ? 0 : 1, ml: 1, my: 1, border: 2, borderColor: fromFooter ? 'pink' : 'mediumorchid', boxShadow: 3, backgroundColor: fromFooter ? 'white' : 'rgba(170,170,170,0.5)', '&:hover': { backgroundColor: fromFooter ? 'pink' : 'mediumorchid' } }} >
          <YouTubeIcon sx={{ color: '#000000' }}/>
        </IconButton>
      </Link>
    </Box>
  )
}

// Back/Forward and forward arrow IconButton in wrap and unwrap processes
export const arrowElement = (type, disabled, clickHandler, forwardArrowBgColor = 'white') => {

  return (
    <IconButton 
      disabled={disabled}
      color='primary' 
      onClick={clickHandler} 
      sx={{ 
        backgroundColor: type === 'forward' ? forwardArrowBgColor : 'white',
        boxShadow: 3, 
        mb: 0, 
        mx: 4, 
      }}
    >
      {type === 'back' 
        ? 
        <ArrowBackIosNewIcon /> 
        : 
        <ArrowForwardIosIcon /> 
      }
    </IconButton>
  )
}

export const viewImageInNewTabIcon = (link, width, iconSize) => {
  return (
    <Box 
      sx={{
        width: width, height: '30px',
        mt: '-40px',
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', 
      }}
    >
      <Link 
        href={makeOutsideGiftLink(link)} 
        className='gift-link' 
        target="_blank" 
      >
        <OpenInNewIcon 
          sx={{ 
            fontSize: iconSize, borderRadius: '5px',
            color: 'gray', backgroundColor: 'whitesmoke',
            '&:hover': {
              backgroundColor: 'mediumorchid', color: 'whitesmoke',
            }, 
          }} 
        />
      </Link>
    </Box>
  )
}