/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

// Images and GIFs
import neatOpen from '../assets/neatWrapSpinner320.gif'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// Standard Error Container
export const standardErrorContainer = (errorText, marginBottom, marginTop) => {
  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      mb: marginBottom, mt: marginTop,
    }}>
      {/* <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
      <Typography variant='p' sx={{ color: 'red' }}>
        {errorText}
      </Typography>
      {/* </Container> */}
    </Box>
  )
}

// Email/Password login/registration errors
export const loginRegisterErrors = (isError, errorText, marginTop, marginBottom) => {
  return (
    <>
      {isError && 
        <Grid item xs={12} sx={{ mt: marginTop, mb: marginBottom }}>
          {standardErrorContainer(errorText)}
        </Grid>  
      }
    </>
  )
}

// Request Error
export const requestError = () => {
  return (
    <Box textAlign={'center'} sx=
      {{ 
        height: '100%', width: '90vw', 
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
      }}
    >
      {/* Neat wrapping present GIF */}
      <Box component='img' src={neatOpen} alt='neat open' sx={{ width: 150 }} />
      
      {/* Request Error text */}
      <Typography variant='h4'>Error! Could not fetch data!</Typography>
      <Typography>Check your Internet connection.</Typography>
      
    </Box>
  )
}