import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { populateWishlistTab } from '../../helpers/profileTabLists'
import { searchTextField } from '../../helpers/textfields'
import { standardSpinner } from '../../helpers/spinners'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestError } from '../../helpers/errors'
import { seoPageTags } from '../../helpers/analytics'

//mui
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'


// Gift Ideas Page
const GiftIdeas = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // URL Path
  const urlPath = window.location.pathname

  // For sending a specific gift when the send IconButton is pressed on a GiftIdeasCard
  const { sendSpecificGift, setSendSpecificGift } = props

  //loading and error state
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState(false)

  // Show search-by-tag pop-out
  const [show, setShow] = useState('none') 

  //gift state 
  const [giftIdeas, setGiftIdeas] = useState([]) //list of gift ideas
  const [filteredGiftIdeas, setFilteredGiftIdeas] = useState([]) //list gift ideas matching the search criteria
  const [tags, setTags] = useState([]) //list of tags that appear in the gift ideas

  const [wishlist, setWishlist] = useState([]) //Not used in this view; Here to normalize inputs in populateWishlist function

  // Filters Object
  const [filters, setFilters] = useState({
    tagFilter: [], //tags selected
    searchTerm: '', //search text
  })

  // Get all GiftIdeas and Tags upon pageload
  useEffect(() => {
    const getData = async () => {
      try {
        // Get all gift ideas; Backend narrows search to active gift ideas and sorts it so promotions show first, and then they're sorted by recency
        // const { data } = await axios.get('/api/giftIdeas')
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/giftIdeas`)

        // Set gift ideas array
        setGiftIdeas(data)

        // Get an array of all tags on gift ideas
        const tagsArray = []
        if (data.length > 0) {
          data.forEach(idea => {
            idea.tags.forEach(tag => { 
              tagsArray.push(tag.charAt(0).toUpperCase() + tag.slice(1))
            })
          })
        }
        // Remove duplicate tags, and display tags in alphabetical order
        const tagsWithoutDuplicatesArray = [...new Set(tagsArray)].sort()

        // Set the tags
        setTags(tagsWithoutDuplicatesArray)

      } catch (error) {
        console.log(error)
        setErrors(true)
      }
      setLoading(false) //Loading to false after try/catch runs
    }
    getData() //Call the getData function
  }, [])

  //Execute filtering by new search text or selected tags
  const handleInput = (e) => {
    e.stopPropagation()

    // Get name and value from the event target
    const name = e.target.getAttribute('name')
    const value = name === 'searchTerm' ? e.target.value : e.target.innerText.toLowerCase()

    // New object for newest search criteria
    let newObj
    
    if (e.target.name === 'searchTerm') {
      //if search term changed, set filters with new search term
      newObj = {
        ...filters,
        [name]: value,
      }

    } else if (tags.includes(e.target.textContent)) {
      //changes box for selected tag to purple border, or removes purple border if tag is deselected
      e.target.classList.toggle('styled')

      // if selected tags changed, set filters with new selected tags array
      newObj = {
        ...filters,
      }
      newObj.tagFilter.includes(value) ? newObj.tagFilter.splice(newObj.tagFilter.indexOf(value), 1) : newObj.tagFilter.push(value)
    }

    // Set filters with newObj
    setFilters(newObj)
  }

  // filter gift ideas by tags and searchTerm
  useEffect(() => {

    //regexp search term for creating an array of all items that the fit the searchTerm criteria
    const regExpSearch = new RegExp(filters.searchTerm, 'i')
    regExpSearch.lastIndex = 0
    const filteredArray = giftIdeas.filter(giftIdea => (regExpSearch.test(giftIdea.subtitle) || regExpSearch.test(giftIdea.brand)))

    //Array of tags in the filters obj tagFilter array
    const tagFilterArray = []

    if (filters.tagFilter.length) {
      // If there are tag filters

      // For each tag filter, only keep items in filtered array that include selected tag
      filters.tagFilter.forEach(tag => {

        // Further filter the array of objects that match searchTerm criteria to array of objects that match both searchTerm and tagFilter
        (filteredArray.length ? filteredArray : giftIdeas).forEach(giftIdea => {
          if (giftIdea.tags.includes(tag) && !tagFilterArray.includes(giftIdea)) {
            tagFilterArray.push(giftIdea)
          }
        })
      })

      // set filtered gift idea with array that fits both searchTerm and tagFilter criteria
      setFilteredGiftIdeas(tagFilterArray)
    } else {
      //if just search term, set filtered gift idea with array that fits searchTerm criteria
      setFilteredGiftIdeas(filteredArray)
    }

  }, [filters, giftIdeas])

  // Opens tags popout
  const handleOpen = (e) => {
    e.stopPropagation()
    setShow('')
  }
  
  // Closes tags popout
  const handleClose = (e) => {
    setShow('none')
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {urlPath === '/giftideas' && 
        seoPageTags(
          'Gift Ideas'
        )
      }

      {/* Gift Ideas Body */}
      <Box onClick={handleClose}>
        <Container key={0} maxWidth='md' 
          sx={{ 
          //   backgroundColor: 'yellow',
            flexGrow: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', 
          }} 
        >
          
          {/* Search bar and tags container; Does not appear on Home.js */}
          {urlPath !== '/' &&
            <Box sx={{ width: '95%' }} >
              
              {/* Search Bar */}
              <Box onClick={handleOpen}>
                {searchTextField('searchTerm', 'Search by item...', handleInput, filters.searchTerm)}
              </Box>

              {/* Tags */}
              <Card sx={{ display: [show], p: 2, maxHeight: '250px', overflow: 'auto' }}>
                <Container>
                  <Box textAlign='center' sx={{ mt: 2  }}>
                    <Grid container spacing={1}>
                      {tags.map((tag, i) => {
                        return (
                          <Grid item xs={4} sm={4} key={i}>
                            <Box
                              onClick={handleInput}
                              name='tagFilter'
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#ebebeb',
                                borderRadius: 10,
                                p: 1,
                                fontFamily: 'Lato',
                                fontSize: width >= 375 ? '16px' : width >= 300 ? '12px' : '10px',
                                '&:hover': {
                                  cursor: 'pointer',
                                  backgroundColor: '#e0e0e0',
                                },
                              }}>
                              {tag}
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box >
          }

          {/* Gift Ideas, Loading, or Errors */}
          {
            loading ?
              standardSpinner()
              : errors ?
                requestError()
                :
                // Gift Ideas
                <Box 
                  sx={{ 
                    // backgroundColor: 'red',
                    my: urlPath !== '/' ? 4 : 0, width: '95%', 
                    display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', 
                  }}
                >
                  {populateWishlistTab('giftIdeas', filteredGiftIdeas, setSendSpecificGift, wishlist, setWishlist, width)}
                </Box>
          }
        </Container>
      </Box>
    </>
  )
}

export default GiftIdeas