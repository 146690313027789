import React from 'react'

import { socialMediaButtons } from '../../../../helpers/buttons'
import { standardButton } from '../../../../helpers/buttons'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { userIsAuthenticated } from '../../../../helpers/storage'


// Successful Send
export const successfulSend = (send, securityCode, handleSendAnother, giftLink, senderEmailAddress, recipientEmailAddress = '', width) => {
  
  return (
    <>
      {/* Title */}
      <Typography variant='h4' textAlign={'center'} sx={{ mt: 2, mb: 2 }}>
        Your Gift is Wrapped!
      </Typography>

      {/* Gift Details */}
      <Box sx={{ 
        width: '95%',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}>
        
        {/* Link */}
        <Typography variant='p' sx={{ mt: 0, mb: 1, width: '100%', overflowWrap: 'break-word' }}>
          <strong>Link:</strong>  {giftLink}
        </Typography>

        {/* Security Code */}
        <Typography variant='p' sx={{ mt: 0, mb: 1, width: '100%' }}>
          <strong>Security Code:</strong>  {securityCode}
        </Typography>

        {/* Emailed To */}
        {!send.copyLinkOnly &&
          <Typography variant='p' sx={{ mt: 0, mb: 1, width: '100%' }}>
            <strong>Emailed to:</strong> {senderEmailAddress} {recipientEmailAddress ? `, ${recipientEmailAddress}` : ''}
          </Typography>
        }
        
        {/* More Info */}
        {userIsAuthenticated() ?
          <Typography variant='p' sx={{ mt: 0, mb: 1, width: '100%' }}>
            **You will be able to see all your 3Dwraps in the &apos;Given&apos; tab on your user profile.
          </Typography>
          :
          <Typography variant='p' sx={{ mt: 0, mb: 1, width: '100%' }}>
            **You can create a profile at any time, and your gift giving/receiving history will appear there.
          </Typography>
        }

      </Box>

      {/* Buttons */}
      <Box sx={{ 
        width: '95%', mt: 2, mb: 2,
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
      }}>
        {/* Send Another Button */}
        {width >= 300 &&
          <Box>
            {standardButton(
              'Send Another',
              'button',
              'contained',
              false,
              'secondary',
              0,
              0,
              0,
              width >= 350 ? '150px' : '138px',
              width >= 350 ? '50px' : '45px',
              handleSendAnother
            )}
          </Box>
        }
        
        {/* Social Media Buttons */}
        {socialMediaButtons('row', width >= 300 ? 'flex-end' : 'center', 'center', 0, false)}

      </Box>

    </>
  )
}