import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { makeOutsideGiftLink } from './cardHelpers'
import { viewImageInNewTabOverlay } from './typographies'
import { viewImageInNewTabIcon } from './buttons'

// Map Items within Option in revealGift.js during unwrapping process
export const mapItemsWithinOptionUnwrap = (options, optionIndex) => {
  return (
    <>
      {options[optionIndex].map((item, itemIndex) => (
        <Box key={itemIndex} sx={{  
          mb: 0, mt: itemIndex === 0 ? 0 : 4,
          width: '100%',
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        }}>

          {/* Image and link */}
          <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}
          >
            <Box 
              component='img'
              src={options[optionIndex][itemIndex].image} 
              alt='gift-image'
              sx={{ mt: 0, mb: 0, width: '90%', maxWidth: 300, boxShadow: 3, display: 'inline-block' }} 
            />

            {viewImageInNewTabIcon(options[optionIndex][itemIndex].link, '85%', '25px')}

          </Box>

          {/* Brand and Product */}
          <Box sx={{
            width: '90%',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
          }}>
            <Typography sx={{ mt: 0.5 }}>
              {options[optionIndex][itemIndex].brand}
            </Typography>
            {options[optionIndex][itemIndex].product &&
              <Typography>
                {options[optionIndex][itemIndex].product}
              </Typography>
            }
          </Box>
        </Box>
      ))}
    </>
  )
}

// Map Items within Option in reviewResponses.js during unwrapping process
export const mapItemsWithinOptionReviewResponses = (option) => {
  return (
    <>
      {option.map((item, itemIndex) => (
        <Box key={itemIndex} sx={{  
          mb: 0, mt: itemIndex === 0 ? 0 : 4,
          width: '100%',
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        }}>
          
          {/* Image and link */}
          <Link href={makeOutsideGiftLink(option[itemIndex].link)} className='gift-link' target="_blank" 
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
          >
            <Box 
              component='img'
              src={option[itemIndex].image} 
              alt='gift-image'
              sx={{ mt: 0, mb: 0, cursor: 'pointer', width: '90%', maxWidth: 300, boxShadow: 3 }} 
            />
          </Link>
          
          {/* Brand and Product */}
          <Box sx={{
            width: '90%',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
          }}>
            <Typography sx={{ mt: 0.5 }}>
              {option[itemIndex].brand}
            </Typography>
            {option[itemIndex].product &&
              <Typography>
                {option[itemIndex].product}
              </Typography>
            }
          </Box>
        </Box>
      ))}
    </>
  )
}

// Map Items Within Options in Review.js during wrapping process
export const mapItemsElement = (objectIndexToMap, title, giftOptions) => {
  return (
    <>
      {/* Title */}
      <Typography sx={{ ml: 0, mt: 0 }}>
        <strong>{title}</strong>
      </Typography>

      {/* Gift Item */}
      <Box sx={{  
        width: '100%',
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      }}>
        {giftOptions[objectIndexToMap].map((item, itemIndex) => (
          <Box key={itemIndex} sx={{ 
            backgroundColor: 'whitesmoke', boxShadow: 3,
            width: '200px', maxWidth: '200px',
            p: 2,
            mb: 2, 
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
          }}>

            {/* Image and link */}
            <Box >
              <Box 
                component='img'
                src={giftOptions[objectIndexToMap][itemIndex].image} 
                alt='gift-image'
                sx={{ 
                  mt: 0, mb: 0, width: '100%', maxWidth: 125, boxShadow: 3, 
                  display: 'inline-block',
                }} 
              />

              {giftOptions[objectIndexToMap][itemIndex].link !== 'No gift link' &&
                viewImageInNewTabIcon(giftOptions[objectIndexToMap][itemIndex].link, '95%', '18px')
              }

            </Box>

            {/* Brand and Product */}
            <Typography sx={{ mt: 2 }}>
              {giftOptions[objectIndexToMap][itemIndex].brand}
            </Typography>
            <Typography>
              {giftOptions[objectIndexToMap][itemIndex].product}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  )
}