
// Gets token from local storage
export const getTokenFromLocalStorage = () => {
  return window.localStorage.getItem('3dwrap')
}

// Set new token to local storage
export const setTokenToLocalStorage = (token) => {
  window.localStorage.setItem('3dwrap', token)
}

// Setting profile pic to local storage
export const setProfPicToLocalStorage = (imageURL) => {
  window.localStorage.setItem('profPic', imageURL)
}

// Retrieving profile pic from local storage
export const getProfPicFromLocalStorage = () => {
  return window.localStorage.getItem('profPic')
}

// Setting username to local storage
export const setUsernameToLocalStorage = (usernameString) => {
  window.localStorage.setItem('username', usernameString)
}

// Retrieving username from local storage
export const getUsernameFromLocalStorage = () => {
  return window.localStorage.getItem('username')
}

// Setting email to local storage
export const setEmailToLocalStorage = (emailString) => {
  window.localStorage.setItem('email', emailString)
}

// Retrieving email from local storage
export const getEmailFromLocalStorage = () => {
  return window.localStorage.getItem('email')
}

// Setting current profile to local storage
export const setCurrentProfileToLocalStorage = (profileObject) => {
  window.localStorage.setItem('currentProfile', JSON.stringify(profileObject))
}

// Retrieving current profile from local storage
export const getCurrentProfileFromLocalStorage = () => {
  return window.localStorage.getItem('currentProfile')
}

// Splits the token then returns the payload encoded using base64
export const getPayload = () => {
  const token = getTokenFromLocalStorage()
  if (!token) return
  const payload = token.split('.')[1]
  return JSON.parse(atob(payload))
}

// Checks if user is authenticated
export const userIsAuthenticated = () => {
  const payload = getPayload()
  if (!payload) return false
  const currentTime = Math.floor(Date.now() / 1000)
  return currentTime < payload.exp
}