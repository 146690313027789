import React from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { seoPageTags } from '../../helpers/analytics'

// TermsOfService Page
const TermsOfService = () => {
  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'TOS'
      )}

      {/* TOS Body */}
      <Container sx={{ width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
        
        {/* Header */}
        <Box 
          sx={{
            width: '100%',
            maxWidth: '600px',
            display: 'flex', justifyContent: 'center',
          }}
        >
          <h1>Terms of Service</h1>
        </Box>

        {/* Body */}
        <Box 
          sx={{
            pb: 8,
            width: '100%',
            maxWidth: '600px',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>1. Terms</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>By accessing this Website, accessible from www.3dwrap.com, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>
          </Box>
          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>2. Use License</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
            }}
          >
            <p>Permission is granted to temporarily download one copy of the materials on 3D Wrap&apos;s Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

            <ul>
              <li>modify or copy the materials;</li>
              <li>use the materials for any commercial purpose or for any public display;</li>
              <li>attempt to reverse engineer any software contained on 3D Wrap&apos;s Website;</li>
              <li>remove any copyright or other proprietary notations from the materials; or</li>
              <li>transferring the materials to another person or mirror the materials on any other server.</li>
            </ul>

            <p>This will let 3D Wrap to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format. These Terms of Service has been created with the help of the <a href="https://www.termsofservicegenerator.net">Terms Of Service Generator</a>.</p>
          </Box>

          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>3. Disclaimer</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>All the materials on 3D Wrap’s Website are provided as is. 3D Wrap makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, 3D Wrap does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
          </Box>

          

          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>4. Limitations</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>3D Wrap or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on 3D Wrap’s Website, even if 3D Wrap or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
          </Box>

          

          
          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>5. Revisions and Errata</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>The materials appearing on 3D Wrap’s Website may include technical, typographical, or photographic errors. 3D Wrap will not promise that any of the materials in this Website are accurate, complete, or current. 3D Wrap may change the materials contained on its Website at any time without notice. 3D Wrap does not make any commitment to update the materials.</p>
          </Box>


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>6. Links</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>3D Wrap has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by 3D Wrap of the site. The use of any linked website is at the user’s own risk.</p>
          </Box>


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>7. Site Terms of Use Modifications</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>3D Wrap may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
          </Box>

          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>8. Your Privacy</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>Please read our <a href='/privacy' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: 'mediumorchid' }} >Privacy Policy</a>.</p>
          </Box>


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>9. Governing Law</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: '600px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>Any claim related to 3D Wrap&apos;s Website shall be governed by the laws of us without regards to its conflict of law provisions.</p>
          </Box>

        </Box>
      </Container>
    </>
  )
}

export default TermsOfService