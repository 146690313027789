import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from '../helpers/windowDimensions.js'

// Components
import ShowPresent from './canvas/ShowPresent.jsx'
import GiftIdeas from './giftIdeas/GiftIdeas.js'
import FAQ from './faq/FAQ.js'

// Helpers
import { socialMediaButtons } from '../helpers/buttons.js'
import { userIsAuthenticated } from '../helpers/storage.js'
import { profPicDefaultURL, navbarHeight, confettiColorDefault, canvasBgDefaultColorUnwrap, canvasBgDefaultColorWrap, cardTextColorDefault, ribbonColorDefault, envelopeColorDefault, wrappingColorDefault, boxTypeDefault, wrapTypeDefault, positionChangeWidthSm, positionChangeWidthMd, bgColors, wrapColors, ribbonColors, confettiColors, envelopeColors, cardTextColors, wrapTypes, boxTypes, giftTypes } from '../helpers/variableDefaults.js'
import { standardButton } from '../helpers/buttons.js'
import { standardSpinner } from '../helpers/spinners.js'
import { seoPageTags, customAnalyticsEvent } from '../helpers/analytics.js'
import { reloadView } from '../helpers/globalHelpers.js'
import { randomPackage, giftObjectDefault } from '../helpers/randomGift.js'
import { videoInsert } from '../helpers/videos.js'

// mui
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { requestError } from '../helpers/errors.js'


// Home
const Home = (props) => {

  // For sending gifts directly from wishlist, gift given/received cards
  const { sendSpecificGift, setSendSpecificGift } = props

  // use Naviage
  const navigate = useNavigate()

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  //loading and error state
  const [loading, setLoading] = useState(false) 
  const [errors, setErrors] = useState(false)

  const [finishedUnwrap, setFinishedUnwrap] = useState(false)
  const [revealGift, setRevealGift] = useState(false)

  // Package Design
  const [confettiColor, setConfettiColor] = useState(confettiColorDefault)
  const [backgroundColor, setBackgroundColor] = useState(canvasBgDefaultColorWrap)
  const [cardText, setCardText] = useState({
    salutation: '',
    recipientName: '',
    message: '',
    signOff: '',
    senderName: '',
    color: cardTextColorDefault,
  })
  const [envelope, setEnvelope] = useState(envelopeColorDefault)
  const [boxType, setBoxType] = useState(boxTypeDefault)
  const [wrappingColor, setWrappingColor] = useState(wrappingColorDefault)
  const [wrappingType, setWrappingType] = useState(wrapTypeDefault)
  const [ribbon, setRibbon] = useState(ribbonColorDefault)

  // For View consistency
  const sectionWidth = '95%'

  // Default gift object
  const [gift, setGift] = useState(giftObjectDefault())


  // If user is authenticated, navigate to /giftideas
  // If not authenticated, create a random package and gift for unwrapping
  useEffect(() => {

    // Design a random package
    randomPackage(gift, cardText, setWrappingColor, setRibbon, setEnvelope, setCardText, setConfettiColor, setWrappingType, setBoxType, setGift)

  }, [navigate])

  // Navigate to /sendgift when Send Gift button Clicked
  const handleSendGift = async (e) => {
    e.preventDefault()
    navigate('/sendgift')
  }

  // Navigate to /sendgift when Send Gift button Clicked
  const handleSeeExample = async (e) => {
    e.preventDefault()
    navigate('/example')
  }

  return (
    <>
      {/* Helmet — for Page Title, Analytics, and SEO */}
      {seoPageTags(
        'Home'
      )}

      {/* Home — Body */}
      <Box sx={{ 
        width: '100vw',
        height: `calc(100% - ${navbarHeight})`,
        maxHeight: `calc(100% - ${navbarHeight})`,
        // height: '100%',
        // maxHeight: '100%',
        overflow: 'auto',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
      }}>

        {/* Top Portion */}
        <Box sx={{ 
          mt: 2,
          width: sectionWidth,
          maxWidth: 'md', 
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
        }}>

          {/* Main Selection Area */}
          <Paper sx={{ 
            boxShadow: 3, 
            width: '100%', 
            maxWidth: '100vw',
            // height: width < positionChangeWidthMd ? '100%' : '70vh',
            // height: '70vh',
            // maxHeight: width < positionChangeWidthMd ? '100%' : '70vh',
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
          }}>

            {/* 3D Present and Description */}
            
            <Grid container spacing={0} 
              sx={{ 
                height: '100%',
              }}
            >
              {/* Unwrap Present Canvas */}
              <Grid item xs={12} md={6}
                sx={{ 
                  backgroundColor: canvasBgDefaultColorWrap,
                  // height: width < positionChangeWidthMd ? '50%' : '100%', width: width < positionChangeWidthMd ? '100%' : '50%', 
                  // height: '100%',
                  height: width < positionChangeWidthMd ? '35vh' : '50vh',
                  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
                }}
              >
                {
                  loading ?
                    standardSpinner()
                    : 
                    errors ?
                      requestError()
                      :
                      <Box 
                        sx={{ 
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <ShowPresent 
                          finishedUnwrap={finishedUnwrap} setFinishedUnwrap={setFinishedUnwrap} setRevealGift={setRevealGift}
                          confettiColor={confettiColor} backgroundColor={backgroundColor} cardText={cardText} envelope={envelope} ribbon={ribbon} wrappingType={wrappingType} wrappingColor={wrappingColor} boxType={boxType} 
                        />
                        {/* {finishedUnwrap 
                        && revealGift 
                        && reloadView()
                          // <ShowGift 
                          //   finishedUnwrap={finishedUnwrap} setFinishedUnwrap={setFinishedUnwrap} 
                          //   gift={gift} setGift={setGift}
                          // />
                        } */}
                      </Box>
                }
              </Grid>

              {/* Site Description */}
              <Grid item xs={12} md={6} 
                sx={{ 
                  boxShadow: 0, 
                  // height: width < positionChangeWidthMd ? '50%' : '100%', maxHeight: width < positionChangeWidthMd ? '50%' : '100%',
                  // height: '100%', 
                  height: width < positionChangeWidthMd ? '420px' : '50vh',
                  // maxHeight: '100%',
                  width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
                  overflow: 'hidden',
                  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: width < positionChangeWidthMd ? 'center' : 'center', 
                }}
              >
                
                {/* Site Description */}
                <Typography 
                  variant="h1"
                  textAlign={'center'}
                  sx={{  
                    // backgroundColor: 'yellow',
                    width: '90%',
                    mb: 2, mt: -3,
                    fontFamily: 'Londrina Shadow',
                    fontSize: '35px',
                    lineHeight: '35px',
                  }}
                >
                  Wrap and open presents online, in 3D
                </Typography>

                {/* Send Button */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {standardButton(
                    'Send Gift', 
                    'button',
                    'contained',
                    false,
                    'secondary', 
                    0,
                    3,
                    0,
                    '150px', 
                    '55px', 
                    handleSendGift
                  )}
                </Box>

                {/* See Example */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {standardButton(
                    'See Example', 
                    'button',
                    'contained',
                    false,
                    'primary', 
                    0,
                    3,
                    0,
                    '150px', 
                    '55px', 
                    handleSeeExample
                  )}
                </Box>

                {/* Social Media Buttons */}
                {socialMediaButtons('row', 'center', 'center', 0, false)}
                
              </Grid>
            </Grid>  

          </Paper>

        </Box>

        {/* Introduction Video */}
        <Box
          sx={{
            mt: 5,
            width: '100%',
            maxWidth: 'md',
            backgroundColor: 'whitesmoke',
            boxShadow: 3,
            borderRadius: '5px',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Box
            sx={{
              pb: 4, pl: 3,
              width: '100%',
              maxWidth: '600px',
            }}
          >
            {videoInsert('https://www.youtube.com/embed/C5NWxapkOMM', 'Introducing 3D Wrap', sectionWidth, width)}
          </Box>
        </Box>

        {/* Gift Ideas */}
        <Box
          sx={{
            backgroundColor: '#C5C5C5',
            mt: 3, mb: 0, pb: 4,
            boxShadow: 3, borderRadius: '5px',
            width: sectionWidth,
            maxWidth: 'md',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography>
            <h3>Gift Ideas</h3>
          </Typography>

          {/* Gift Ideas */}
          <GiftIdeas sendSpecificGift={sendSpecificGift} setSendSpecificGift={setSendSpecificGift}/>

        </Box>

        {/* FAQ */}
        <FAQ />

        {/* Footer */}
        <Box
          sx={{
            mt: 3,
            width: '100%',
            minHeight: '150px',
            backgroundColor: 'purple',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '95%',
              height: '100%',
              maxWidth: 'md',
              // backgroundColor: 'purple',
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            }}
          >

            {/* Contact */}
            <Typography
              sx={{
                color: 'white',
              }}
            >
              support@mail.3dwrap.com
            </Typography>

            {/* Social Media Buttons */}
            {socialMediaButtons('row', 'center', 'center', 0, true)}

          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Home