import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { colorOptionsElement, wrapCanvasElement } from '../../../helpers/wrapHelpers'
import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { dummyBox, removeColorStyling } from '../../../helpers/globalHelpers'
import { positionChangeWidthMd, ministepsHeight, bgColors, confettiColors } from '../../../helpers/variableDefaults'

import useWindowDimensions from '../../../helpers/windowDimensions'

const Environment = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    environment, setEnvironment, backArrow, forwardArrow,
    wrapping, ribbon, envelope, cardText, boxType,
    miniStepsPackage, activeBottomStep,
  } = props
  
  // Update view when environment changes
  useEffect(() => {

  }, [environment])

  // Execute environment selections
  const handleSetEnvironment = async (e) => {
    e.preventDefault()
    
    // Get name from event target classlist
    const name = e.target.classList[0].split('-')[0] === 'bg' ? 'backgroundColor' : e.target.classList[0].split('-')[0] === 'confetti' ? 'confettiColor' : ''

    // Get value from event target classlist
    const value = name === 'backgroundColor' ? e.target.classList[0].slice(3) : e.target.classList[0].slice(9)

    // Remove color styling and set the environment with new selection values
    if (name === 'backgroundColor') {
      // Remove styling from non-selected background colors, and add styling to newly selected color if different from previous selection
      removeColorStyling(bgColors, value, 'bg', environment.backgroundColor, e)
  
      // Set environment
      setEnvironment({ ...environment, [name]: `#${value}` })

    } else if (name === 'confettiColor') {
      // Remove styling from non-selected confetti colors, and add styling to newly selected color if different from previous selection
      removeColorStyling(confettiColors, value, 'confetti', environment.confettiColor, e)
  
      // Set environment
      setEnvironment({ ...environment, [name]: `#${value}` })
    }

  }

  return (
    <>
      <Grid container spacing={0} 
        sx={{ 
          height: '100%',
        }}
      >
        {/* Wrap Present Canvas */}
        {wrapCanvasElement(width, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep)}

        {/* Background and Confetti Options */}
        <Grid item xs={12} md={6} 
          sx={{ 
            boxShadow: 0, 
            height: width < positionChangeWidthMd ? '60%' : '100%', maxHeight: width < positionChangeWidthMd ? '60%' : '100%',
            width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
          }}
        >

          <Box sx={{
            height: `calc(100% - ${ministepsHeight})`,
            maxHeight: `calc(100% - ${ministepsHeight})`,
            // overflow: 'auto',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              // -webkit-appearance: none,
              WebkitAppearance: 'none',
              width: '7px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            width: '100%', 
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', 
          }}>

            {/* Dummy Box to get spacing right */}
            {dummyBox('0px')}

            {/* Background and Confetti */}
            <Box 
              sx={{
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >

              {/* Background */}
              <Box 
                sx={{ 
                  mb: 4, 
                }}
              >
                {/* Title */}
                <Typography sx={{ mb: 0, mt: 0 }}><strong>Background</strong></Typography>
                
                {/* Colors */}
                {colorOptionsElement('bg', bgColors, environment.backgroundColor, handleSetEnvironment)}
                
              </Box>

              {/* Confetti */}
              <Box sx={{ mb: 2 }}>
                {/* Title */}
                <Typography sx={{ mb: 0, mt: 0 }}><strong>Confetti</strong></Typography>
                
                {/* Colors */}
                {colorOptionsElement('confetti', confettiColors, environment.confettiColor, handleSetEnvironment)}
              </Box>
              
            </Box>

            {/* Dummy Box to get spacing right */}
            {dummyBox('0px')}
            
          </Box>
          
          {/* Mobile stepper and, if the screen is small, arrows */}
          {packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, true)}
          
        </Grid>
      </Grid>  
    </>
  )
}

export default Environment