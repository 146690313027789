import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import chooseOneImage from '../../../assets/chooseOne320.png'
import oneItemImage from '../../../assets/oneItem320.png'

import useWindowDimensions from '../../../helpers/windowDimensions'

import { resetQuestionsOneItem, resetQuestionsChooseOne, resetGiftOptionsOneItem, resetGiftOptionsChooseOne } from '../../../helpers/wrapHelpers'
import { dummyBox, removeStyling } from '../../../helpers/globalHelpers'

const GiftTypes = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { typeChosen, setTypeChosen, setGiftOptions, setQuestions, blinkCount, setBlinkCount } = props

  const handleTypeSelect = async (e) => {
    e.preventDefault()

    // Forward Arrow blinks
    setBlinkCount(blinkCount + 1)

    // Retrieve the chosen type from the event target classList
    const chosenType = e.target.classList[0]

    // Remove 'styled' from the option that was not selected and setting states that correspond to the gift type selected
    if (chosenType === 'one-item') {

      // Remove 'styled' from chooseOne if the styling is there
      removeStyling('.choose-one')

      // Set type chosen
      setTypeChosen('oneItem')

      // Set Gift Options that correspond to 'oneItem'
      resetGiftOptionsOneItem(setGiftOptions)

      // Set Questions that correspond to 'oneItem'
      resetQuestionsOneItem(setQuestions)

    } else if (chosenType === 'choose-one') {

      // Remove 'styled' from oneItem if the styling is there
      removeStyling('.one-item')

      // Set type chosen
      setTypeChosen('chooseOne')

      // Set Gift Options that correspond to 'chooseOne'
      resetGiftOptionsChooseOne(setGiftOptions)

      // Set Questions that correspond to 'chooseOne'
      resetQuestionsChooseOne(setQuestions)
    }

    // If the typeChosen has changed, add 'styled'; Must use this format because JS uses camelCase and html uses dashes, so oneItem !== one-item, but first 3 letters are equal
    if (chosenType.slice(0, 3) !== typeChosen.slice(0, 3)) {
      e.target.classList.toggle('styled')
    }
  }

  // Standardized gift type option element — image with description underneath
  const typeImageAndDescription = (className, srcImage, imageAltText, description) => {
    return (
      <Box 
        sx={{ 
          mt: className.includes('choose-one') ? width < 369 ? '22px' : '10px' : '0px',
          width: '200px', 
          height: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >

        {/* Image */}
        <Box 
          component='img'
          className={className}
          onClick={(e) => handleTypeSelect(e)}
          src={srcImage} 
          alt={imageAltText}
          sx={{ 
            backgroundColor: 'whitesmoke', 
            cursor: 'pointer', 
            width: '90%', maxWidth: 125, 
            boxShadow: 3, mt: 1, mb: 2, 
          }} 
        />

        {/* Description */}
        <Typography align='center' sx={{ width: '90%', mb: 0, mt: 0 }}>
          {description} 
        </Typography>
      </Box>
    )
  }

  return (
    <>
      
      {/* Dummy Box to get spacing right */}
      {dummyBox('40px')}
      
      {/* Main Elements */}
      <Box 
        sx={{ 
          mt: 5, mb: 1, 
          width: '100%', 
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', 
        }}
      >
        {/* One Item */}
        {typeImageAndDescription(
          typeChosen === 'oneItem' ? 'one-item styled' : 'one-item', 
          oneItemImage, 
          'choose-one-image', 
          'Single gift'
        )}

        {/* Choose One */}
        {typeImageAndDescription(
          typeChosen === 'chooseOne' ? 'choose-one styled' : 'choose-one', 
          chooseOneImage, 
          'single-gift-image', 
          'Multiple ideas, recipient chooses one'
        )}
        
      </Box>
    </>
  )
}

export default GiftTypes