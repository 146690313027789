/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import profPicDefault from '../../assets/social-media-logo.png'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getPayload, getTokenFromLocalStorage } from '../../helpers/storage'
import { standardButton } from '../../helpers/buttons'
import { acceptRejectDisappearWidth } from '../../helpers/variableDefaults'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'


// Friend List Item
const FriendListItem = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Payload
  const payload = getPayload()
  
  // Destructure Props
  const { friend, source } = props

  // For updating button displays in real time after pressing them
  const [requestedArray, setRequestedArray] = useState([]) //when user presses "Add Friend" in FindFriends.js
  const [newlyAccepted, setNewlyAccepted] = useState([]) //when user presses "Accept" in UserProfile.js
  const [newlyRejected, setNewlyRejected] = useState([]) //when user presses "Reject" in UserProfile.js

  // Execute Accept, Reject, and Add Friend Button actions
  const handleFriendButtonClick = async (e) => {
    e.preventDefault()
  
    if (e.currentTarget.innerText === 'ACCEPT') { //Executed in the friends tab of UserProfile.js
      try {
        // An object with the part of the Friend Object to Modify in the database
        const acceptedRequest = { requestStatus: 'Accepted' }

        // Upload new object in PUT request
        // await axios.put(`/api/friends/${friend.requestId}`, acceptedRequest, {
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/friends/${friend.requestId}`, acceptedRequest, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        // Add request ID to the newlyAccepted array to make the button no longer appear in the view
        const editedNewlyAcceptedArray = [ ...newlyAccepted ]
        editedNewlyAcceptedArray.push(friend.requestId)
        setNewlyAccepted(editedNewlyAcceptedArray)

      } catch (error) {
        console.log('accept error ->', error)
      }
    } else if (e.currentTarget.innerText === 'REJECT') { //Executed in the friends tab of UserProfile.js
      try {

        // DELETE request — eliminate the Friend object in the database
        // await axios.delete(`/api/friends/${friend.requestId}`, {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/friends/${friend.requestId}`, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        // Add request ID to the newlyRejected array to make the user no longer appear in the friends tab of UserProfile.js
        const editedNewlyRejectedArray = [ ...newlyRejected ]
        editedNewlyRejectedArray.push(friend.requestId)
        setNewlyRejected(editedNewlyRejectedArray)
  
      } catch (error) {
        console.log('reject error ->', error)
      }
    } else if (e.currentTarget.innerText === 'ADD FRIEND') { //Executed in FindFriends.js
      try {
        // Object to upload to create new Friend object in the database
        const friendRequestData = {
          users: [payload.sub, friend.id],
          requestedBy: payload.sub,
        }

        // POST request with the object to upload
        // await axios.post(`/api/friends`, friendRequestData, {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/friends`, friendRequestData, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        // Add the User ID to the requestedArray to make the button no longer appear in the row
        const editedRequestedArray = [ ...requestedArray ]
        editedRequestedArray.push(friend.id)
        setRequestedArray(editedRequestedArray)

      } catch (error) {
        console.log('add friend error ->', error)
      }
    } 
  }
  
  return (
    <>
      {/* Execute code below for all except newly rejected users */}
      {(source === 'findFriends'
      || (source === 'userProfile' && !newlyRejected.includes(friend.requestId))
      ) &&
        // Box sets width, maxWidth, and height
        <Box sx={{ height: 100, width: '80vw', maxWidth: 500, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          
          {/* Avatar and username Container */}
          <Box 
            sx={{ 
              height: 80, 
              width: '100%', //set to 100% so buttons; reset it to 60% in source === userProfile if you want the buttons to show
              display: 'flex', flexDirection: 'row', justifyContent: source === 'userProfile' ? 'flex-start' : 'center', alignItems: 'center', 
            }}
          >
            
            {/* Avatar — links to user's profile */}
            <Link to={`/profile/${friend.username}`}>
              <Avatar sx={{ ml: 2, height: 70, width: 70,  boxShadow: 3, border: 0, borderColor: 'black' }} alt={friend.username} src={friend.profilePicture ? friend.profilePicture : profPicDefault} />
            </Link>

            {/* Username — links to user's profile */}
            <Typography align= 'left' fontSize={22} sx={{ width: 'calc(100% - 80px)', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical', ml: source === 'userProfile' ? 2 : 5 }}>
              <Link to={`/profile/${friend.username}`}>
                {friend.username}
              </Link>
            </Typography>

          </Box>
          
          {/* Accept/Reject buttons — only for UserProfile.js */}
          {/* {source === 'userProfile' && 
          friend.requestStatus === 'Pending' 
          && width >= acceptRejectDisappearWidth 
          && friend.requestedBy !== payload.sub
          && !newlyAccepted.includes(friend.requestId) 
          && !newlyRejected.includes(friend.requestId) 
          &&
            <Box 
              sx={{ 
                height: 100, 
                width: '40%', //set to 0% so it doesn't show
                display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', 
              }}
            >
              {standardButton('Accept', 'button', 'contained', false, 'secondary', 0, 0, 0, .4, .4, handleFriendButtonClick)}
              {standardButton('Reject', 'button', 'contained', false, 'secondary', 0, 0, 0, .4, .4, handleFriendButtonClick)}
            </Box>
          } */}
          
          {/* 'Requested' text next to friends who have been requested by User but not accepted; only appears in UserProfile.js */}
          {/* {source === 'userProfile' && 
          friend.requestStatus === 'Pending' 
          && width > 375 
          && friend.requestedBy === payload.sub
          &&
            <Box 
              sx={{ 
                height: 100, 
                width: '40%', //set to 0% so it doesn't show
                display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', 
              }}
            >
              <Typography>
                <em>Requested</em>
              </Typography>
            </Box>
          } */}
          
          {/* Add Friend button appears in FindFriends.js next to non-friends and non-requested-friends */}
          {/* {source === 'findFriends' 
          && !friend.isFriend 
          && friend.id !== payload.sub 
          && !requestedArray.includes(friend.id) 
          && width > 375 &&
            <Box 
              sx={{ 
                height: 100, 
                width: '40%', //set to 0% so it doesn't show 
                display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', 
              }}
            >
              {standardButton('ADD FRIEND', 'button', 'contained', false, 'secondary', 0, 0, 0, .85, .4, handleFriendButtonClick)}
            </Box>
          } */}
        </Box>
      }
    </>
  )
}

export default FriendListItem