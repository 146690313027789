import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OutsideLink from '@mui/material/Link'

import { Link, useNavigate } from 'react-router-dom'

import { makeSpecificGift } from '../../helpers/sendSpecificGift'
import { makeOutsideGiftLink, ExpandMore } from '../../helpers/cardHelpers'
import { cardWidth } from '../../helpers/variableDefaults'
import { viewImageInNewTabOverlay } from '../../helpers/typographies'
import { viewImageInNewTabIcon } from '../../helpers/buttons'

import profPicDefault from '../../assets/social-media-logo.png'

const GiftsCard = (props) => {

  // Navigate
  const navigate = useNavigate()

  // Keeps track of whether card is expanded
  const [expanded, setExpanded] = useState(false)

  // Tracks if the description overlay above image should show
  // const [show, setShow] = useState(false)
  
  // Toggles card expanded when expand more clicked
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // Destructure Props
  const { gift, setSendSpecificGift, username, myUsername } = props

  // When send pressed, create specific gift from giftIdea and navigate to /send gift
  const handleSendGiftIdea = () => {

    // Create specific gift
    makeSpecificGift(
      setSendSpecificGift, 
      gift.wasOpened && gift.type !== 'oneItem' ? gift.options[gift.feedback.optionChosen][0].link : gift.options[0][0].link,
      gift.wasOpened && gift.type !== 'oneItem' ? gift.options[gift.feedback.optionChosen][0].image : gift.options[0][0].image, 
      gift.wasOpened && gift.type !== 'oneItem' ? gift.options[gift.feedback.optionChosen][0].brand : gift.options[0][0].brand, 
      gift.wasOpened && gift.type !== 'oneItem' ? gift.options[gift.feedback.optionChosen][0].product : gift.options[0][0].product
    )

    // Navigate to /sendgift
    navigate('/sendgift')
  }

  return (
    <Card align="left" sx={{ width: '100%', maxWidth: cardWidth, boxShadow: 3 }}>
      
      {/* Avatar, title, and subtitle */}
      <CardHeader
        avatar={
          // Avatar — links to user profile
          gift.senderUsername === 'guest' ?
            <Avatar sx={{ boxShadow: 3, border: 0, borderColor: 'black' }} alt="prof-pic" 
              src={ gift.senderProfilePicture ? gift.senderProfilePicture : profPicDefault } 
            />
            :
            <Link to={`/profile/${gift.senderUsername}`}>
              <Avatar sx={{ boxShadow: 3, border: 0, borderColor: 'black' }} alt="prof-pic" 
                src={ gift.senderProfilePicture ? gift.senderProfilePicture : profPicDefault } 
              />
            </Link>
        }
        title={
          // Title — username — links to user profile
          gift.senderUsername === 'guest' ?
            <strong>{gift.senderUsername}</strong>
            :
            <Link to={`/profile/${gift.senderUsername}`}>
              <strong>{gift.senderUsername}</strong>
            </Link>
        }
        subheader= {
          // Subtitle — cardText.message — 'Happy Birthday', 'Congratulations', etc.
          `${gift.package.cardText.message}`
        }
      />

      {/* Image — clicking it opens link to product url */}
      <Box 
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <CardMedia
          component="img"
          height={cardWidth}
          image={gift.wasOpened && gift.type !== 'oneItem' ? gift.options[gift.feedback.optionChosen][0].image : gift.options[0][0].image}
          alt={gift.wasOpened && gift.type !== 'oneItem' ? gift.options[gift.feedback.optionChosen][0].brand : gift.options[0][0].brand}
          sx={{ borderRadius: 0 }}
        />
        {/* {show && 
          viewImageInNewTabOverlay(cardWidth, cardWidth)
        } */}

        {viewImageInNewTabIcon(gift.wasOpened && gift.type !== 'oneItem' ? gift.options[gift.feedback.optionChosen][0].link : gift.options[0][0].link, '85%', '25px')}
      </Box>

      {/* Message Beneath Image */}
      <CardContent>
        <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}>
          {gift.package.cardText.salutation} {gift.package.cardText.recipientName}, {gift.package.cardText.message} <br />{gift.package.cardText.signOff} {gift.package.cardText.senderName}
        </Typography>
      </CardContent>

      {/* Buttons */}
      <CardActions disableSpacing>
        
        {/* Send as Specific Gift */}
        <IconButton onClick={handleSendGiftIdea} aria-label="share">
          <SendIcon />
        </IconButton>

        {/* If on User's own profile, expand more is possible */}
        {username === myUsername &&
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      </CardActions>

      {/* Shows when card is expanded — gift link, security code, and note */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Lato',
              fontSize: '16px',
            }}
          >
            {/* Gift Link */}
            <OutsideLink href={`https://www.3dwrap.com/unwrap/${gift._id}`} className='giftLink' target="_blank" sx={{ color: 'mediumorchid', textDecoration: 'none' }}>
              <strong>View Gift</strong>
            </OutsideLink>
            <br/>

            {/* Security Code */}
            <strong>Code: </strong>{gift.securityCode}
            <br/>

            {/* Note */}
            <strong>Note:</strong>
            <br/>
            {gift.note}
            
          </Typography>
        </CardContent>
      </Collapse>
      
    </Card>
  )
}

export default GiftsCard