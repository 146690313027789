/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import { standardSpinner } from '../../helpers/spinners'
import { inProcessAllAuth, createGoogleSignInButton } from '../../helpers/inProcessLoginRegister'
import { seoPageTags } from '../../helpers/analytics'

// All Auth Component — 
// Set states here, most of the code is in the inProcessLoginRegister helper; 
// I do it this way because login occurs in multiple places throughout text, and states must be defined in components, but having the functions in helper views makes it so they only have to be written once
const AllAuth = (props) => {
  
  // Navigate
  const navigate = useNavigate()

  // Email/Password Login
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
    loginMethod: 'emailLogin',
    acceptedTOS: true,
  })
  const [ loginErrors, setLoginErrors ] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)

  // Email/Password Register
  const [registerForm, setRegisterForm] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    loginMethod: 'emailRegister',
    acceptedTOS: true,
  })
  const [ registerErrors, setRegisterErrors ] = useState({})
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [acceptedTOS, setAcceptedTOS] = useState(false)

  // For toggling between Login and Register screens
  const [loginViewIndex, setLoginViewIndex] = useState(0)

  // Google Login
  const [ loadingGoogle, setLoadingGoogle ] = useState(false)
  const [ googleLoginErrors, setGoogleLoginErrors ] = useState(false)

  // Facebook Login
  const [ loadingFacebook, setLoadingFacebook ] = useState(false)
  const [ facebookLoginErrors, setFacebookLoginErrors ] = useState(false)

  // Here to make the inProcessAuth consistent for use across multiple views, not used in this view
  const [ sendViewIndex, setSendViewIndex ] = useState(0)
  const [ authenticated, setAuthenticated ] = useState(false)
  const [ continueAsGuest, setContinueAsGuest ] = useState(false)

  // Used to initialize the Google Sign-In button, runs once at the end of pageload
  useEffect(() => {

    createGoogleSignInButton('allAuth', setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)

  }, [])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Login'
      )}
      
      {/* Login Body */}
      <Box
        sx={{
          mt: 4,
          pb: 8,
          maxHeight: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {(loadingGoogle || loadingFacebook) ?
          standardSpinner()
          : 
          <>
            {inProcessAllAuth(
              'allAuth', 
              loginViewIndex,
              googleLoginErrors, facebookLoginErrors, setGoogleLoginErrors, setFacebookLoginErrors, setLoadingGoogle, setLoadingFacebook,
              loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin,
              registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS,
              setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated, setContinueAsGuest
            )}
          </>
        }
      </Box>
    </>
  )
}

export default AllAuth