/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Routes, Route } from 'react-router-dom' //Router components

// Import Components
import PageNavbar from './components/common/PageNavbar'
import NotFound from './components/common/NotFound'
import Home from './components/Home'
import FAQ from './components/faq/FAQ.js'
import FindFriends from './components/friends/FindFriends.js' //Not currently used, but maybe in the future
import GiftIdeas from './components/giftIdeas/GiftIdeas.js'
import SendGift from './components/sendGift/SendGift.js'
import UnwrapGift from './components/unwrap/UnwrapGift.js'
import UserProfile from './components/user/UserProfile.js'
import EditProfile from './components/user/EditProfile.js'
import TermsOfService from './components/auth/TermsOfService.js'
import PrivacyPolicy from './components/auth/PrivacyPolicy.js'
import AllAuth from './components/auth/AllAuth.js'
import ResetPassword from './components/auth/ResetPassword'
import SitePassword from './components/common/SitePassword'
import Example from './components/example/Example'
import ScrollToTop from './components/common/ScrollToTop'

//MUI
import getDesignTokens from './helpers/theme.js' //MUI theming presets
import { createTheme, ThemeProvider } from '@mui/material/styles' //MUI theme creator functions/components
import Box from '@mui/material/Box'

const App = () => {
  // Helmet Context
  const helmetContext = {}

  // Geolocation
  const [countryCode, setCountryCode] = useState('')
  const getData = async () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      // const data = response.data
      // console.log('country code ->', response.data.country_code)
      setCountryCode(
        response.hasOwnProperty('data') && 
        response.data.hasOwnProperty('country_code') && 
        response.data.country_code.length === 2 
          ? 
          response.data.country_code 
          : 
          ''
      )
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  // For sending Gift Ideas from cards on the GiftIdeas.js or UserProfile.js Pages; Setting this state autofills the Option in SendGift.js and skips ahead to Options part of the send gift process
  const [sendSpecificGift, setSendSpecificGift] = useState({
    type: 'oneItem',
    link: '',
    image: '',
    brand: '',
    product: '',
  })

  // Password Protecting the Site
  const sitePassword = 'Wr4p1tUp!'
  const [sitePasswordGuess, setSitePasswordGuess] = useState('')

  return (
    // HelmetProvider must go around everything in order to use dynamic helmets
    <HelmetProvider context={helmetContext}>
      
      {/* Wrap everything in the theme so that these presets cascade down */}
      <ThemeProvider theme={createTheme(getDesignTokens())}>

        {/* The 'wrapper-box' id is referenced in main.scss for global variables that cascade down */}
        <Box id='wrapper-box' bgcolor='background.default' color='text.primary'>
          
          {/* All the routes must be set inside the Browser Router */}
          <BrowserRouter>

            {/* Scroll to top automatically every time route changes */}
            <ScrollToTop />

            {(sitePassword !== sitePasswordGuess 
            && sitePasswordGuess === 'Wr4p1tUp!' // Comment this out to password-protect the site
            ) 
              ?
              // Password Protection Page
              <SitePassword setSitePasswordGuess={setSitePasswordGuess} />
              :
              <>
                {/* The Website */}

                {/* Navbar on all pages */}
                <PageNavbar />

                <Routes>

                  {/* Homepage */}
                  <Route path="/" element={<Home sendSpecificGift={sendSpecificGift} setSendSpecificGift={setSendSpecificGift}/>} />

                  {/* Example */}
                  <Route path="/example" element={<Example />} />

                  {/* Auth routes */}
                  <Route path="/login" element={<AllAuth />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/reset-password/:userId/:token" element={<ResetPassword />} />
                  <Route path="/tos" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />

                  {/* FAQ route */}
                  <Route path="/faq" element={<FAQ />} />

                  {/* Send Gift route */}
                  <Route path="/sendgift" element={<SendGift sendSpecificGift={sendSpecificGift} setSendSpecificGift={setSendSpecificGift} countryCode={countryCode}/>} />

                  {/* Gift Ideas route */}
                  <Route path="/giftideas" element={<GiftIdeas sendSpecificGift={sendSpecificGift} setSendSpecificGift={setSendSpecificGift}/>} />

                  {/* Unwrap route */}
                  <Route path="/unwrap/:giftId" element={<UnwrapGift />} />

                  {/* Friend routes */}
                  {/* <Route path="/findfriends" element={<FindFriends />} /> */}

                  {/* User routes */}
                  <Route path="/profile/:username" element={<UserProfile sendSpecificGift={sendSpecificGift} setSendSpecificGift={setSendSpecificGift} />} />
                  <Route path="/profile/:username/edit" element={<EditProfile />} />

                  {/* Not Found; this path matches any path specified, so it needs to come last */}
                  <Route path="*" element={<NotFound />} />
                  
                </Routes>
              </>
            }
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
