/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { makeOutsideGiftLink } from './cardHelpers'
import { cardWidth } from './variableDefaults'

// Stylized Note Text
export const noteTextTypography = (text, size = '20px', font = 'Caveat') => {
  return (
    <Typography
      component="pre" //in Order to read multiline strings
      sx={{
        fontFamily: font,
        fontSize: size,
      }}
    >
      {text}
    </Typography>
  )
}

export const viewImageInNewTabOverlay = (width, height) => {
  return (
    <Box
      sx={{
        height: height,
        width: width,
        backgroundColor: 'white',
        opacity: .9,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        position: 'absolute', 
      }}
    >
      <Typography
        textAlign={'center'}
        sx={{
          width: '50%',
          fontStyle: 'italic',
          fontSize: '16px',
          color: 'purple',
        }}
      >
        Click to view item in new tab
      </Typography>
    </Box>
  )
}

