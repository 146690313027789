/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

// Components
import ShowPresent from '../canvas/ShowPresent'
import ShowGift from './ShowGift'
import Security from './Security.js'

// mui
import Box from '@mui/material/Box'

import { confettiColorDefault, canvasBgDefaultColorUnwrap, cardTextColorDefault, ribbonColorDefault, envelopeColorDefault, wrappingColorDefault, boxTypeDefault, wrapTypeDefault } from '../../helpers/variableDefaults'
import { standardSpinner } from '../../helpers/spinners'
import { requestError } from '../../helpers/errors'
import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'


// Unwrap Gift
const UnwrapGift = (props) => {

  // Params
  const { giftId } = useParams()

  //loading and error state
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false) 
  
  // States necessary for unwrap process
  const [securityCode, setSecurityCode] = useState('')
  const [securityPassed, setSecurityPassed] = useState(false)
  const [finishedUnwrap, setFinishedUnwrap] = useState(false)
  const [revealGift, setRevealGift] = useState(false)

  // Package Design, setting default values
  const [confettiColor, setConfettiColor] = useState(confettiColorDefault)
  const [backgroundColor, setBackgroundColor] = useState(canvasBgDefaultColorUnwrap)
  const [cardText, setCardText] = useState({
    salutation: 'Dear',
    recipientName: 'Friend',
    message: 'You\'re the best!',
    signOff: 'From',
    senderName: '3D Wrap',
    color: cardTextColorDefault,
  })
  const [envelope, setEnvelope] = useState(envelopeColorDefault)
  const [boxType, setBoxType] = useState(boxTypeDefault)
  const [wrappingColor, setWrappingColor] = useState(wrappingColorDefault)
  const [wrappingType, setWrappingType] = useState(wrapTypeDefault)
  const [ribbon, setRibbon] = useState(ribbonColorDefault)

  // The Gift object
  const [gift, setGift] = useState({})

  // Retrieve gift data upon pageload
  useEffect(() => {

    // Get Gift Data
    const getData = async () => {
      try {

        // Set Loading to true while data fetches
        setLoading(true)

        // Get data for specified unwrap, by gift id
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/gifts/${giftId}`)

        // Set the gift state with the retrieved data
        setGift(data)

        // Set package elements, override defaults
        setConfettiColor(data.package.confettiColor)
        setBackgroundColor(data.package.backgroundColor)
        setCardText(data.package.cardText)
        setEnvelope(data.package.envelopeColor)
        setBoxType(data.package.boxType)
        setWrappingColor(data.package.wrappingColor)
        setWrappingType(data.package.wrappingType)
        setRibbon(data.package.ribbonColor)

        // Set security code
        setSecurityCode(data.securityCode)

        // Reset Loading and Errors
        setLoading(false)
        setErrors(false)

      } catch (error) {
        console.log(error)

        // Reset Loading and Errors
        setLoading(false)
        setErrors(true)
      }
    }

    // Call getData
    getData()

  }, [])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Unwrap'
      )}

      {/* Unwrap Body */}
      <Box sx={{ 
        width: '100vw', height: '100vh',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
      }}>
        {
          loading ?
            standardSpinner()
            : errors ?
              requestError()
              :
              <Box sx={{ width: '100%', height: '100%' }}>
                
                {/* Show Present Canvas */}
                {securityPassed &&
                  <ShowPresent 
                    finishedUnwrap={finishedUnwrap} setFinishedUnwrap={setFinishedUnwrap} setRevealGift={setRevealGift}
                    confettiColor={confettiColor} backgroundColor={backgroundColor} cardText={cardText} envelope={envelope} ribbon={ribbon} wrappingType={wrappingType} wrappingColor={wrappingColor} boxType={boxType} 
                  />
                }

                {/* Security Modal is open until the correct security code is input */}
                {!securityPassed &&
                  <Security 
                    securityCode={securityCode} securityPassed={securityPassed} setSecurityPassed={setSecurityPassed}
                  />
                }

                {/* ShowGift modal pops up after the unwrap finishes */}
                {finishedUnwrap && securityPassed && revealGift &&
                  <ShowGift 
                    finishedUnwrap={finishedUnwrap} 
                    gift={gift} setGift={setGift}
                  />
                }
              </Box>
        }
      </Box>
    </>
  )
}

export default UnwrapGift