//mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { holidays } from './variableDefaults'

// Holiday Options in EditProfile
export const holidaySelection = (holidaysCelebrated = [], handleHolidaySelection) => {
  return (
    <>
      {/* holiday options */}
      <Box textAlign='center' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Grid container spacing={1}>
          {holidays.map((holiday, i) => {
            return (
              <Grid item xs={6} sm={4} key={i} sx={{  }}>
                <Box
                  onClick={(e) => handleHolidaySelection(e)}
                  className= {holidaysCelebrated.includes(holiday) ? `${holiday} styled` : `${holiday}` }
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'lightgray',
                    borderRadius: 10,
                    p: 1,
                    fontSize: 14,
                    fontFamily: 'Lato',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#e0e0e0',
                    },
                  }}>
                  {holiday}
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}  

// Execute state changes when switches are toggled
export const handleSwitchChange = (e, setErrors, setFormData, formData, setSwitchState) => {
  
  const { name } = e.target

  setErrors(false)

  const newForm = { ...formData }
  const boolValue = e.target.checked

  if (name === 'birthdaySwitch') {
    newForm.importantDates.birthday.visible = boolValue
    setFormData({ ...formData, importantDates: newForm.importantDates })
    setSwitchState(e.target.checked)
  } else if (name === 'anniversarySwitch') {
    newForm.importantDates.anniversary.visible = boolValue
    setFormData({ ...formData, importantDates: newForm.importantDates })
    setSwitchState(e.target.checked)
  } else if (name === 'accountPrivate' && boolValue === true) {
    setFormData({ 
      ...formData, 
      [name]: boolValue, 
      showWishlist: false,
      showGiftsGiven: false,
      showGiftsReceived: false,
      showFriends: false,
    })
    setSwitchState(e.target.checked)
  } else if ((name === 'showWishlist' || name === 'showGiftsGiven' || name === 'showGiftsReceived' || name === 'showFriends') && boolValue === true) {
    setFormData({ 
      ...formData, 
      accountPrivate: false, 
      [name]: boolValue,
    })
    setSwitchState(e.target.checked)
  } else {
    setFormData({ ...formData, [name]: boolValue })
    setSwitchState(e.target.checked)
  }
}

// Standard Switch element
export const switchElement = (disabled, checked, setChecked, name, formData, setFormData, setErrors) => {
  return (
    <Switch
      disabled={disabled}
      checked={checked}
      size='large'
      name={name}
      onChange={(e) => handleSwitchChange(e, setErrors, setFormData, formData, setChecked)}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={{ ml: 1 }}
    />
  )
}

// Date selector, and a switch beside it
export const birthdayAnniversaryElements = (label, switchName, dateValue, setDateValue, dateChecked, setDateChecked, formData, setFormData, setErrors) => {
  return (         
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', mb: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disableFuture
          label={label}
          openTo="year"
          views={['year', 'month', 'day']}
          maxDate={dayjs()}
          minDate={dayjs('1920-01-01')}
          value={dateValue}
          onChange={(newValue) => {
            setDateValue(newValue)

            const newForm = { ...formData }
            label === 'Birthday' ? newForm.importantDates.birthday.visible = true : newForm.importantDates.anniversary.visible = true
            setFormData({ ...formData, importantDates: newForm.importantDates })

            setDateChecked(true)
          }}
          format="DD-MM-YYYY"
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      {switchElement(!dateValue, dateChecked, setDateChecked, switchName, formData, setFormData, setErrors)}
      
    </Box>
  )
}

// Prompt for the switch, and also the switch
export const privacyElements = (name, title, checked, setChecked, formData, setFormData, setErrors ) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', mb: 1 }}>
      <Typography sx={{ width: '150px' }}>
        {title}
      </Typography>

      {switchElement(false, checked, setChecked, name, formData, setFormData, setErrors)}

    </Box>
  )
}


