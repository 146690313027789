import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import whiteBox320 from '../../../assets/boxNoPaper320.gif'
import cardboardBox320 from '../../../assets/cardboardBox320.gif'

import useWindowDimensions from '../../../helpers/windowDimensions'

import { wrapCanvasElement, boxTypeOption } from '../../../helpers/wrapHelpers'
import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { dummyBox, removeStyling } from '../../../helpers/globalHelpers'
import { positionChangeWidthMd, ministepsHeight } from '../../../helpers/variableDefaults'

const BoxType = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    boxType, setBoxType, backArrow, forwardArrow,
    wrapping, ribbon, envelope, cardText, environment,
    miniStepsPackage, activeBottomStep,
  } = props
  
  // Updates view whenever boxType updates
  useEffect(() => {

  }, [boxType])

  // Handling boxType Selection
  const handleSetBoxType = async (e) => {
    e.preventDefault()

    // Get the chosen type from the event target classlist
    const chosenType = e.target.classList[0]
    
    if (chosenType === 'cardboard') {
      // Remove the styling from the option that was not selected
      removeStyling('.white')

      // Set type chosen
      setBoxType('cardboard')

    } else if (chosenType === 'white') {
      // Remove the styling from the option that was not selected
      removeStyling('.cardboard')

      // Set type chosen
      setBoxType('white')
    }

    // toggle the 'styled' class for the event target if the box chosen is different from the one previously selected
    if (chosenType !== boxType) {
      e.target.classList.toggle('styled')
    }
  }

  return (
    <>
      <Grid container spacing={0} 
        sx={{ 
          height: '100%', 
        }}
      >

        {/* Wrap Present Canvas */}
        {wrapCanvasElement(width, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep)}
        

        {/* Box Type Options */}
        <Grid item xs={12} md={6}  
          sx={{ 
            boxShadow: 0, 
            height: width < positionChangeWidthMd ? '60%' : '100%', maxHeight: width < positionChangeWidthMd ? '60%' : '100%',
            width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
          }}
        >
          {/* Dummy Box to get spacing right */}
          {dummyBox('0px')}

          {/* Title and Options */}
          <Box 
            sx={{ 
              height: `calc(100% - ${ministepsHeight})`,
              maxHeight: `calc(100% - ${ministepsHeight})`,
              // overflow: 'auto',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                // -webkit-appearance: none,
                WebkitAppearance: 'none',
                width: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, .35)',
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
              },
              width: '100%', 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            {/* Title */}
            <Typography sx={{ mb: 2, mt: 2 }}>
              <strong>Box Type</strong>
            </Typography>

            {/* Options */}
            <Box 
              sx={{ 
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', 
              }}
            >
              {/* Cardboard Box */}
              {boxTypeOption(
                'cardboard', 
                (boxType && boxType === 'cardboard' ? 'cardboard styled' : 'cardboard'), 
                width, 
                handleSetBoxType, 
                cardboardBox320
              )}

              {/* White Box */}
              {boxTypeOption(
                'white', 
                (boxType && boxType === 'white' ? 'white styled' : 'white'), 
                width, 
                handleSetBoxType, 
                whiteBox320
              )}
            </Box>
          </Box>
          
          {/* Mobile stepper and, if the screen is small, arrows */}
          {packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, true)}
          
        </Grid>
      </Grid>  
    </>
  )
}

export default BoxType