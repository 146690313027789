
import Box from '@mui/material/Box'

import { styled } from '@mui/material/styles'

// Increment index by one 
export const incrementIndex = (index, setIndex) => {
  const newIndex = index + 1
  setIndex(newIndex)
}

// Decrement index by one 
export const decrementIndex = (index, setIndex) => {
  const newIndex = index - 1
  setIndex(newIndex)
}

// Reload View
export const reloadView = () => {
  window.location.reload()
}

// Input Element
export const Input = styled('input')({
  display: 'none',
}) 

// Dummy Box for Spacing
export const dummyBox = (height) => {
  return (
    <Box sx={{ height: height }}></Box>
  )
}

// Check if it's a valid email address
export const validEmail = (email) => {
  return email && email.includes('@') && email.includes('.') && email.charAt(email.length - 1) !== '@' && email.charAt(email.length - 1) !== '.' && email.charAt(email.length - 2) !== '@' && email.charAt(email.length - 2) !== '.' && email.charAt(0) !== '@' && email.charAt(0) !== '.'
}

// Handles basic form input changes
export const handleChange = (e, setErrors, setFormData, formData) => {
  const { name, value } = e.target

  setErrors(false)
  setFormData({ ...formData, [name]: value })
  
}

// Remove styling for non-color selection elements, mostly during wrapping process
export const removeStyling = (domName) => {
  // Remove Styling from non-selected options
  const removeStylingElement = document.body.querySelectorAll(`${domName}`)
  removeStylingElement.forEach(item => item.classList.remove('styled'))
}

// Remove styling for color selection elements, mostly during wrapping process; Then add styling to the selected color if not already styled
export const removeColorStyling = (colorArray, colorValue, fromElement, colorState, event) => {

  // Remove Styling from non-selected colors
  const removeStyleColors = colorArray.filter( color => color !== `#${colorValue}`)
  removeStyleColors.forEach( color => {
    const removeColorStylingElement = document.body.querySelectorAll(`.${fromElement}-${color.slice(1)}`)
    removeColorStylingElement.forEach(item => item.classList.remove('color-styled'))
  })

  // toggle the 'styled' class for the event target
  if (colorState.confettiColor !== `#${colorValue}`) {
    event.target.classList.toggle('color-styled')
  }
  
}