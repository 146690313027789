/* eslint-disable react/no-unknown-property */
/* eslint-disable no-prototype-builtins */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

import { confetti300GLB } from '../../../helpers/variableDefaults'
import { confetti300Elements, playConfettiAnimation } from '../elements/confetti300Elements'

export default function Model(props) {

  // URL Path
  const urlPath = window.location.pathname
  
  // Prepping the animation
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(confetti300GLB)
  const { actions } = useAnimations(animations, group)

  // Destructure Props
  const { confettiColor, animationIndex } = props

  // Onload, play animations if animation index is 3
  useEffect(() => {
    if (animationIndex > 3) {

      // Play Animations
      playConfettiAnimation(actions)
    }
  })

  return (
    <>
      {confetti300Elements(confettiColor, group, props, nodes, materials)}
    </>
  )
}

useGLTF.preload(confetti300GLB)
