/* eslint-disable react/no-unknown-property */
/* eslint-disable no-prototype-builtins */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'

import { confettiColorDefault } from '../../../helpers/variableDefaults'

export const playConfettiAnimation = (actions) => {
  Object.keys(actions).forEach(function(key, index) {

    // Edit and play animations that have the 'repetitions' prop
    if (actions[key].hasOwnProperty('repetitions')) {

      // Edit animations
      actions[key].repetitions = 1
      actions[key].clampWhenFinished = true

      // Play animations
      actions[key].play()
    }
  })
}

export const confetti300Elements = (confettiColor, group, props, nodes, materials) => {
  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <mesh name='particle000' geometry={nodes.particle000.geometry} material={materials.confetti} position={[-7.76, 10, 2.11]} rotation={[2.98, -0.53, -0.96]} scale={1.23} 
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle001' geometry={nodes.particle001.geometry} material={materials.confetti} position={[1.11, 10, 3.93]} rotation={[-2.16, -0.47, -2.15]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle002' geometry={nodes.particle002.geometry} material={materials.confetti} position={[5.53, 10, 4.92]} rotation={[2.32, 0.49, -0.6]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle003' geometry={nodes.particle003.geometry} material={materials.confetti} position={[7.48, 10, 6.35]} rotation={[-1.93, 0.28, -1.03]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle004' geometry={nodes.particle004.geometry} material={materials.confetti} position={[-6.53, 10, 1.25]} rotation={[2.52, 0.48, -1.42]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle005' geometry={nodes.particle005.geometry} material={materials.confetti} position={[5.02, 10, 9.41]} rotation={[-0.86, 0.8, 2.96]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle006' geometry={nodes.particle006.geometry} material={materials.confetti} position={[8.71, 10, 7.71]} rotation={[1.97, -0.35, -3.05]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle007' geometry={nodes.particle007.geometry} material={materials.confetti} position={[-0.67, 10, -2.33]} rotation={[1.9, -0.11, 0.12]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle008' geometry={nodes.particle008.geometry} material={materials.confetti} position={[4.68, 10, 4.88]} rotation={[-2.71, -0.33, -1.26]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle009' geometry={nodes.particle009.geometry} material={materials.confetti} position={[0.44, 10, 9.95]} rotation={[-2.58, 0.76, -2.58]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle010' geometry={nodes.particle010.geometry} material={materials.confetti} position={[-1.46, 10, -8.51]} rotation={[-2.2, 0.63, 3.04]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle011' geometry={nodes.particle011.geometry} material={materials.confetti} position={[-9.76, 10, -0.33]} rotation={[1.54, 1.14, -1.1]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle012' geometry={nodes.particle012.geometry} material={materials.confetti} position={[-8.69, 10, 0.2]} rotation={[-2.72, 0.56, -0.52]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle013' geometry={nodes.particle013.geometry} material={materials.confetti} position={[8.33, 10, 6.27]} rotation={[-2.01, 0.17, -1.64]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle014' geometry={nodes.particle014.geometry} material={materials.confetti} position={[-7.13, 10, 6.64]} rotation={[-0.16, 0.71, 3.07]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle015' geometry={nodes.particle015.geometry} material={materials.confetti} position={[-7.59, 10, -8.82]} rotation={[-2.92, -0.94, -2.89]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle016' geometry={nodes.particle016.geometry} material={materials.confetti} position={[-1.62, 10, 1.61]} rotation={[1.98, 1.12, -0.3]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle017' geometry={nodes.particle017.geometry} material={materials.confetti} position={[8.81, 10, 7.09]} rotation={[-1.63, 0.67, -1.08]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle018' geometry={nodes.particle018.geometry} material={materials.confetti} position={[-7.33, 10, -8.04]} rotation={[-2.3, -1.37, -1.08]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle019' geometry={nodes.particle019.geometry} material={materials.confetti} position={[-3.39, 10, 2.33]} rotation={[2.59, -0.43, -0.01]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle020' geometry={nodes.particle020.geometry} material={materials.confetti} position={[-3.1, 10, -5.34]} rotation={[-2.86, 0.35, -1.5]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle021' geometry={nodes.particle021.geometry} material={materials.confetti} position={[9.26, 10, -8.07]} rotation={[-0.71, 0.98, 1.48]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle022' geometry={nodes.particle022.geometry} material={materials.confetti} position={[9.75, 10, -6.74]} rotation={[-1.12, 1.08, -1.88]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle023' geometry={nodes.particle023.geometry} material={materials.confetti} position={[7.75, 10, -3.34]} rotation={[-2.91, -0.89, -2.06]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle024' geometry={nodes.particle024.geometry} material={materials.confetti} position={[-3.28, 10, 1.7]} rotation={[1.45, 0.07, -1.23]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle025' geometry={nodes.particle025.geometry} material={materials.confetti} position={[1.78, 10, 6.59]} rotation={[2.83, 0.22, -1.64]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle026' geometry={nodes.particle026.geometry} material={materials.confetti} position={[-4.54, 10, -0.91]} rotation={[-1.86, -0.22, -2.9]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle027' geometry={nodes.particle027.geometry} material={materials.confetti} position={[-7.33, 10, 1.96]} rotation={[2.57, 0.96, -1.66]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle028' geometry={nodes.particle028.geometry} material={materials.confetti} position={[-0.28, 10, -9.52]} rotation={[2.56, 1.38, -1.04]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle029' geometry={nodes.particle029.geometry} material={materials.confetti} position={[-4.81, 10, -6.23]} rotation={[-1.82, 0.71, -1.3]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle030' geometry={nodes.particle030.geometry} material={materials.confetti} position={[-1.3, 10, -7.57]} rotation={[-2.1, -0.52, -2.77]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle031' geometry={nodes.particle031.geometry} material={materials.confetti} position={[-9.09, 10, 1.37]} rotation={[2.46, 0.84, -1.07]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle032' geometry={nodes.particle032.geometry} material={materials.confetti} position={[3.43, 10, 5.93]} rotation={[-1.15, -0.4, -0.81]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle033' geometry={nodes.particle033.geometry} material={materials.confetti} position={[-5.05, 10, -3.88]} rotation={[2.76, -0.48, -0.76]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle034' geometry={nodes.particle034.geometry} material={materials.confetti} position={[9.48, 10, 4.12]} rotation={[-0.4, 0.95, -2.98]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle035' geometry={nodes.particle035.geometry} material={materials.confetti} position={[-7.5, 10, -4.45]} rotation={[0.68, 0.21, 1.66]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle036' geometry={nodes.particle036.geometry} material={materials.confetti} position={[-8.89, 10, -3.94]} rotation={[1.45, -0.03, 1.23]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle037' geometry={nodes.particle037.geometry} material={materials.confetti} position={[-0.25, 10, 7.51]} rotation={[-0.67, -0.25, -2.83]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle038' geometry={nodes.particle038.geometry} material={materials.confetti} position={[-9.46, 10, -7.85]} rotation={[-2.65, 1.13, -1.97]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle039' geometry={nodes.particle039.geometry} material={materials.confetti} position={[2.2, 10, 6.79]} rotation={[-0.89, 0.56, 1.73]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle040' geometry={nodes.particle040.geometry} material={materials.confetti} position={[-6.89, 10, 4.54]} rotation={[2.93, 1.08, -2.4]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle041' geometry={nodes.particle041.geometry} material={materials.confetti} position={[8.13, 10, 1.11]} rotation={[-0.12, 1.23, -2.63]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle042' geometry={nodes.particle042.geometry} material={materials.confetti} position={[2.56, 10, -5.24]} rotation={[-2.54, -0.76, -1.69]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle043' geometry={nodes.particle043.geometry} material={materials.confetti} position={[3.23, 10, 1.24]} rotation={[2.33, 0.38, -0.08]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle044' geometry={nodes.particle044.geometry} material={materials.confetti} position={[-3.97, 10, -8.69]} rotation={[2.98, 0.08, -0.52]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle045' geometry={nodes.particle045.geometry} material={materials.confetti} position={[2.64, 10, -3.37]} rotation={[0.76, -0.76, 2.37]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle046' geometry={nodes.particle046.geometry} material={materials.confetti} position={[5.2, 10, 1.6]} rotation={[-0.6, 0.19, -3.08]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle047' geometry={nodes.particle047.geometry} material={materials.confetti} position={[7.09, 10, -1]} rotation={[-2.77, 0.43, -2.29]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle048' geometry={nodes.particle048.geometry} material={materials.confetti} position={[3.78, 10, 3.4]} rotation={[-0.56, 1.07, 0.77]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle049' geometry={nodes.particle049.geometry} material={materials.confetti} position={[-1.68, 10, -4.01]} rotation={[-0.28, -0.88, 1.86]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle050' geometry={nodes.particle050.geometry} material={materials.confetti} position={[9.7, 10, -5.49]} rotation={[3.04, -0.03, 3.09]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle051' geometry={nodes.particle051.geometry} material={materials.confetti} position={[8.45, 10, 0.64]} rotation={[1.61, 0.97, -1.32]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle052' geometry={nodes.particle052.geometry} material={materials.confetti} position={[0.08, 10, -3.43]} rotation={[0.37, -0.86, 1.81]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle053' geometry={nodes.particle053.geometry} material={materials.confetti} position={[6.37, 10, -2.66]} rotation={[1.99, -0.32, 0.3]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle054' geometry={nodes.particle054.geometry} material={materials.confetti} position={[-2.02, 10, 9.27]} rotation={[2.78, 0.87, -1.05]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle055' geometry={nodes.particle055.geometry} material={materials.confetti} position={[4.3, 10, -8.95]} rotation={[-1.12, -0.15, 1.28]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle056' geometry={nodes.particle056.geometry} material={materials.confetti} position={[-8.68, 10, 6.45]} rotation={[-0.75, -0.17, 2.2]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle057' geometry={nodes.particle057.geometry} material={materials.confetti} position={[-6.28, 10, 6.57]} rotation={[-2.78, 0.51, -2.88]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle058' geometry={nodes.particle058.geometry} material={materials.confetti} position={[-1.39, 10, -5.38]} rotation={[1.11, 0.96, -0.66]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle059' geometry={nodes.particle059.geometry} material={materials.confetti} position={[-3.57, 10, -9.86]} rotation={[-1.66, -0.62, -0.66]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle060' geometry={nodes.particle060.geometry} material={materials.confetti} position={[-3.04, 10, -1.59]} rotation={[-2.09, 0.49, -1.66]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle061' geometry={nodes.particle061.geometry} material={materials.confetti} position={[-0.11, 10, -5.62]} rotation={[-1.7, 0.25, 3.1]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle062' geometry={nodes.particle062.geometry} material={materials.confetti} position={[-4.16, 10, -1.34]} rotation={[2.1, 0.11, -1.43]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle063' geometry={nodes.particle063.geometry} material={materials.confetti} position={[-0.26, 10, 7.98]} rotation={[-0.27, -0.48, 1.66]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle064' geometry={nodes.particle064.geometry} material={materials.confetti} position={[3.81, 10, -2.54]} rotation={[1.52, 0.85, 1.58]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle065' geometry={nodes.particle065.geometry} material={materials.confetti} position={[9.17, 10, -4.94]} rotation={[-2.16, -0.52, -2.95]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle066' geometry={nodes.particle066.geometry} material={materials.confetti} position={[-5.01, 10, -1.38]} rotation={[0.94, 1.01, 0.2]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle067' geometry={nodes.particle067.geometry} material={materials.confetti} position={[1.42, 10, 0.5]} rotation={[-2.05, 1.11, -3.14]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle068' geometry={nodes.particle068.geometry} material={materials.confetti} position={[9.85, 10, -8.93]} rotation={[1.31, 0.41, 1.98]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle069' geometry={nodes.particle069.geometry} material={materials.confetti} position={[-4.88, 10, 2.52]} rotation={[1.41, 1.42, 1.11]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle070' geometry={nodes.particle070.geometry} material={materials.confetti} position={[7.55, 10, -8.03]} rotation={[-2.6, 0.82, -0.73]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle071' geometry={nodes.particle071.geometry} material={materials.confetti} position={[-9.15, 10, 2.62]} rotation={[1.43, -0.35, -0.11]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle072' geometry={nodes.particle072.geometry} material={materials.confetti} position={[-4.26, 10, 8.34]} rotation={[2.45, -0.38, -1.29]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle073' geometry={nodes.particle073.geometry} material={materials.confetti} position={[-3.88, 10, -4.31]} rotation={[-2.22, 0.09, -1.55]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle074' geometry={nodes.particle074.geometry} material={materials.confetti} position={[9.71, 10, -0.49]} rotation={[-2.66, 0.63, -1.16]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle075' geometry={nodes.particle075.geometry} material={materials.confetti} position={[5.56, 10, 6.17]} rotation={[-1.53, 0.52, -1.57]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle076' geometry={nodes.particle076.geometry} material={materials.confetti} position={[-0.99, 10, -3.04]} rotation={[-1.49, -0.08, -2.71]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle077' geometry={nodes.particle077.geometry} material={materials.confetti} position={[-3.35, 10, 6.08]} rotation={[2.46, 0.37, -1.33]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle078' geometry={nodes.particle078.geometry} material={materials.confetti} position={[-9.87, 10, -5.65]} rotation={[-2.02, 0.54, -1.12]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle079' geometry={nodes.particle079.geometry} material={materials.confetti} position={[-3.36, 10, -0.17]} rotation={[-2.39, 0.08, -1.29]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle080' geometry={nodes.particle080.geometry} material={materials.confetti} position={[-7.56, 10, 6.8]} rotation={[1.61, 0.59, 2.13]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle081' geometry={nodes.particle081.geometry} material={materials.confetti} position={[2.52, 10, 7.26]} rotation={[0.35, 0.37, 1.98]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle082' geometry={nodes.particle082.geometry} material={materials.confetti} position={[-0.44, 10, 4.85]} rotation={[2.32, 0.48, 0.79]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle083' geometry={nodes.particle083.geometry} material={materials.confetti} position={[-0.1, 10, 9.38]} rotation={[2.84, 0.04, -0.49]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle084' geometry={nodes.particle084.geometry} material={materials.confetti} position={[4.22, 10, 8.24]} rotation={[-2.28, 0.77, -1.33]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle085' geometry={nodes.particle085.geometry} material={materials.confetti} position={[-7.25, 10, 8.21]} rotation={[2.64, 0.1, -2.19]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle086' geometry={nodes.particle086.geometry} material={materials.confetti} position={[9.43, 10, 3.5]} rotation={[0.03, -0.03, 0.61]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle087' geometry={nodes.particle087.geometry} material={materials.confetti} position={[8.78, 10, -8.54]} rotation={[-2.21, 0.81, -1.42]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle088' geometry={nodes.particle088.geometry} material={materials.confetti} position={[2.45, 10, -5.56]} rotation={[1.99, 0.04, -2.61]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle089' geometry={nodes.particle089.geometry} material={materials.confetti} position={[7.01, 10, -2.25]} rotation={[-0.88, 0.43, -2.06]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle090' geometry={nodes.particle090.geometry} material={materials.confetti} position={[-5.73, 10, -7.4]} rotation={[2.23, 0.18, -1.26]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle091' geometry={nodes.particle091.geometry} material={materials.confetti} position={[1.36, 10, -3.25]} rotation={[3.11, -0.86, -2.87]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle092' geometry={nodes.particle092.geometry} material={materials.confetti} position={[-2.32, 10, -3.55]} rotation={[1.6, 0.58, -1.09]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle093' geometry={nodes.particle093.geometry} material={materials.confetti} position={[1.1, 10, -8.57]} rotation={[-2.57, -1.39, -0.9]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle094' geometry={nodes.particle094.geometry} material={materials.confetti} position={[-8.16, 10, -0.58]} rotation={[1.11, -0.36, 1.07]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle095' geometry={nodes.particle095.geometry} material={materials.confetti} position={[1.44, 10, -2]} rotation={[1.2, 1.09, 1.23]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle096' geometry={nodes.particle096.geometry} material={materials.confetti} position={[0.6, 10, 7.61]} rotation={[-2.33, 0.02, 2.59]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle097' geometry={nodes.particle097.geometry} material={materials.confetti} position={[-2.8, 10, 2.63]} rotation={[2.33, 0.54, -0.91]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle098' geometry={nodes.particle098.geometry} material={materials.confetti} position={[-5.91, 10, 9.95]} rotation={[3.06, 0.98, 0.06]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle099' geometry={nodes.particle099.geometry} material={materials.confetti} position={[-5.74, 10, -2.4]} rotation={[-1.22, 0.61, 2.94]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle100' geometry={nodes.particle100.geometry} material={materials.confetti} position={[4.16, 10, -5.51]} rotation={[0.92, 0.02, 2.07]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle101' geometry={nodes.particle101.geometry} material={materials.confetti} position={[-2.43, 10, -4.56]} rotation={[1.39, 1, 1.38]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle102' geometry={nodes.particle102.geometry} material={materials.confetti} position={[-3.75, 10, 3.5]} rotation={[-2.98, 0.82, -0.29]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle103' geometry={nodes.particle103.geometry} material={materials.confetti} position={[-2.64, 10, -4.25]} rotation={[-2.67, -0.44, 0.29]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle104' geometry={nodes.particle104.geometry} material={materials.confetti} position={[8.03, 10, 0.8]} rotation={[-0.86, 1.12, 2.92]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle105' geometry={nodes.particle105.geometry} material={materials.confetti} position={[-6.67, 10, -0.78]} rotation={[1.34, 0.98, -1.26]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle106' geometry={nodes.particle106.geometry} material={materials.confetti} position={[-8.32, 10, -1.99]} rotation={[-2.11, 0.81, -1.63]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle107' geometry={nodes.particle107.geometry} material={materials.confetti} position={[6.02, 10, -6.48]} rotation={[-2.84, 0.25, -1.43]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle108' geometry={nodes.particle108.geometry} material={materials.confetti} position={[9.81, 10, -0.18]} rotation={[1.23, 1.33, 2.34]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle109' geometry={nodes.particle109.geometry} material={materials.confetti} position={[2.13, 10, -5.09]} rotation={[-2.46, -0.34, -1.51]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle110' geometry={nodes.particle110.geometry} material={materials.confetti} position={[3.48, 10, 8.43]} rotation={[2.72, -0.14, 0.09]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle111' geometry={nodes.particle111.geometry} material={materials.confetti} position={[-4.52, 10, 5.34]} rotation={[2.38, 0.71, -1.2]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle112' geometry={nodes.particle112.geometry} material={materials.confetti} position={[-4.49, 10, -7.16]} rotation={[0.19, 0.08, 1.75]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle113' geometry={nodes.particle113.geometry} material={materials.confetti} position={[8.83, 10, 2.09]} rotation={[-1.97, -0.3, 3.02]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle114' geometry={nodes.particle114.geometry} material={materials.confetti} position={[-4.31, 10, 5.84]} rotation={[2.34, 1.23, -0.94]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle115' geometry={nodes.particle115.geometry} material={materials.confetti} position={[-8.94, 10, -2.69]} rotation={[-2.14, 0.45, -0.17]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle116' geometry={nodes.particle116.geometry} material={materials.confetti} position={[-5.87, 10, -1.3]} rotation={[-0.84, 0.84, 1.14]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle117' geometry={nodes.particle117.geometry} material={materials.confetti} position={[-2.62, 10, -6.75]} rotation={[-1.44, -0.09, -1.54]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle118' geometry={nodes.particle118.geometry} material={materials.confetti} position={[-2.24, 10, -5.42]} rotation={[-1.31, 0.47, -2.74]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle119' geometry={nodes.particle119.geometry} material={materials.confetti} position={[2.61, 10, -6.49]} rotation={[-2.31, 1.17, 3.11]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle120' geometry={nodes.particle120.geometry} material={materials.confetti} position={[-1.65, 10, -1.51]} rotation={[-1.39, -0.55, -1.87]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle121' geometry={nodes.particle121.geometry} material={materials.confetti} position={[-6.89, 10, -5.46]} rotation={[2.85, 0, -1.35]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle122' geometry={nodes.particle122.geometry} material={materials.confetti} position={[4.87, 10, 7.54]} rotation={[0.16, -0.61, 0.9]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle123' geometry={nodes.particle123.geometry} material={materials.confetti} position={[5.19, 10, 6.6]} rotation={[-0.23, -0.64, -2.95]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle124' geometry={nodes.particle124.geometry} material={materials.confetti} position={[0.32, 10, -5.36]} rotation={[-2.69, 0.22, -2.09]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle125' geometry={nodes.particle125.geometry} material={materials.confetti} position={[5.44, 10, -0.39]} rotation={[-0.9, 1.27, 1.09]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle126' geometry={nodes.particle126.geometry} material={materials.confetti} position={[-7.09, 10, 9.14]} rotation={[-1.87, -1.15, -0.05]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle127' geometry={nodes.particle127.geometry} material={materials.confetti} position={[8.58, 10, -6.54]} rotation={[2.13, 0.17, 1]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle128' geometry={nodes.particle128.geometry} material={materials.confetti} position={[7.4, 10, 3.07]} rotation={[2.97, 1.01, -0.85]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle129' geometry={nodes.particle129.geometry} material={materials.confetti} position={[3.69, 10, 9.02]} rotation={[1.72, 1.19, 1.35]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle130' geometry={nodes.particle130.geometry} material={materials.confetti} position={[1.94, 10, 6.47]} rotation={[1.24, 0.07, 1.36]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle131' geometry={nodes.particle131.geometry} material={materials.confetti} position={[-2.49, 10, 6.69]} rotation={[2.38, -0.06, 0.13]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle132' geometry={nodes.particle132.geometry} material={materials.confetti} position={[-8.71, 10, -7.3]} rotation={[-3.07, -0.36, -1.01]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle133' geometry={nodes.particle133.geometry} material={materials.confetti} position={[8.1, 10, -5.14]} rotation={[-1.24, 0.35, -2.12]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle134' geometry={nodes.particle134.geometry} material={materials.confetti} position={[-7.57, 10, 8.68]} rotation={[2.95, 0.6, -1.61]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle135' geometry={nodes.particle135.geometry} material={materials.confetti} position={[7.97, 10, -2.95]} rotation={[-0.8, 0.29, -2.28]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle136' geometry={nodes.particle136.geometry} material={materials.confetti} position={[-3.23, 10, -2.22]} rotation={[-2.74, 0.15, -1.73]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle137' geometry={nodes.particle137.geometry} material={materials.confetti} position={[9.19, 10, 6.31]} rotation={[1.75, 0.03, -1.16]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle138' geometry={nodes.particle138.geometry} material={materials.confetti} position={[-8.43, 10, -1.36]} rotation={[-1.86, -0.48, -1.74]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle139' geometry={nodes.particle139.geometry} material={materials.confetti} position={[8.93, 10, 2.4]} rotation={[-2.91, -0.02, -2.07]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle140' geometry={nodes.particle140.geometry} material={materials.confetti} position={[-2, 10, 0.52]} rotation={[-0.78, 0.59, 1.91]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle141' geometry={nodes.particle141.geometry} material={materials.confetti} position={[7.1, 10, 9]} rotation={[2.67, 1.18, 0.99]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle142' geometry={nodes.particle142.geometry} material={materials.confetti} position={[3.14, 10, -7.04]} rotation={[-1.94, -0.41, -1.72]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle143' geometry={nodes.particle143.geometry} material={materials.confetti} position={[-5.08, 10, 4.87]} rotation={[2.77, -0.32, -1.22]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle144' geometry={nodes.particle144.geometry} material={materials.confetti} position={[2.22, 10, -8.21]} rotation={[-1.56, -0.13, -1.53]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle145' geometry={nodes.particle145.geometry} material={materials.confetti} position={[9.16, 10, 5.06]} rotation={[-2.97, -0.13, -1.21]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle146' geometry={nodes.particle146.geometry} material={materials.confetti} position={[4.59, 10, -0.43]} rotation={[-0.91, 1.15, 2.06]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle147' geometry={nodes.particle147.geometry} material={materials.confetti} position={[5.71, 10, -1.95]} rotation={[-3.02, 0.74, 2.95]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle148' geometry={nodes.particle148.geometry} material={materials.confetti} position={[1.3, 10, -3.88]} rotation={[-0.41, 0.55, 1.35]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle149' geometry={nodes.particle149.geometry} material={materials.confetti} position={[7.89, 10, -4.82]} rotation={[-1.54, 0.01, -1.79]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle150' geometry={nodes.particle150.geometry} material={materials.confetti} position={[-7.74, 10, -5.39]} rotation={[0.28, 0.42, 2.51]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle151' geometry={nodes.particle151.geometry} material={materials.confetti} position={[-4.12, 10, 4.28]} rotation={[2.16, 0.87, 0.71]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle152' geometry={nodes.particle152.geometry} material={materials.confetti} position={[9.69, 10, 4.51]} rotation={[0.88, 0.93, 1.1]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle153' geometry={nodes.particle153.geometry} material={materials.confetti} position={[5.38, 10, -6.02]} rotation={[2.6, 1.39, 0.8]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle154' geometry={nodes.particle154.geometry} material={materials.confetti} position={[-9.53, 10, -3.47]} rotation={[2.27, -1.06, 2.58]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle155' geometry={nodes.particle155.geometry} material={materials.confetti} position={[2.62, 10, -1.49]} rotation={[2.26, -0.28, -0.69]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle156' geometry={nodes.particle156.geometry} material={materials.confetti} position={[-7.63, 10, -0.07]} rotation={[-3.01, 0.09, -1.42]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle157' geometry={nodes.particle157.geometry} material={materials.confetti} position={[-8.15, 10, 5.67]} rotation={[0.61, 0.08, -0.24]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle158' geometry={nodes.particle158.geometry} material={materials.confetti} position={[-5.75, 10, 8.07]} rotation={[-1.74, 1.16, -2.33]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle159' geometry={nodes.particle159.geometry} material={materials.confetti} position={[7.77, 10, 4.16]} rotation={[2.95, 0.66, -2.4]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle160' geometry={nodes.particle160.geometry} material={materials.confetti} position={[-9.02, 10, -5.5]} rotation={[1.06, 0.73, -0.57]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle161' geometry={nodes.particle161.geometry} material={materials.confetti} position={[5.51, 10, 7.42]} rotation={[-2.58, 0.67, -0.82]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle162' geometry={nodes.particle162.geometry} material={materials.confetti} position={[3.05, 10, 8.58]} rotation={[2.24, -0.85, -1.41]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle163' geometry={nodes.particle163.geometry} material={materials.confetti} position={[5.04, 10, 0.66]} rotation={[-0.09, 0.98, 2.22]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle164' geometry={nodes.particle164.geometry} material={materials.confetti} position={[7.33, 10, -3.18]} rotation={[-2.09, -0.43, -2.96]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle165' geometry={nodes.particle165.geometry} material={materials.confetti} position={[-3.72, 10, -3.37]} rotation={[-3.04, 1.36, -1.8]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle166' geometry={nodes.particle166.geometry} material={materials.confetti} position={[-3.48, 10, -5.48]} rotation={[-0.94, 0.57, -1.69]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle167' geometry={nodes.particle167.geometry} material={materials.confetti} position={[-2.79, 10, -7.37]} rotation={[-2.61, 0.02, -1.53]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle168' geometry={nodes.particle168.geometry} material={materials.confetti} position={[0.36, 10, -9.11]} rotation={[1.95, 0.78, -0.31]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle169' geometry={nodes.particle169.geometry} material={materials.confetti} position={[-7.77, 10, 7.11]} rotation={[2.44, 0.88, 0.38]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle170' geometry={nodes.particle170.geometry} material={materials.confetti} position={[-6.84, 10, 3.29]} rotation={[-0.34, 0.58, 2.88]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle171' geometry={nodes.particle171.geometry} material={materials.confetti} position={[6.89, 10, 6.56]} rotation={[-2.9, 0.01, -2.87]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle172' geometry={nodes.particle172.geometry} material={materials.confetti} position={[-7.75, 10, 4.61]} rotation={[2.45, 0.08, -0.74]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle173' geometry={nodes.particle173.geometry} material={materials.confetti} position={[-4.2, 10, 6.16]} rotation={[-1.96, 0.4, -1.36]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle174' geometry={nodes.particle174.geometry} material={materials.confetti} position={[-4.5, 10, 2.84]} rotation={[-1.99, 0.72, -2.7]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle175' geometry={nodes.particle175.geometry} material={materials.confetti} position={[-5.53, 10, 7.29]} rotation={[-1.28, -0.65, -2.58]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle176' geometry={nodes.particle176.geometry} material={materials.confetti} position={[2.25, 10, 9.29]} rotation={[2.78, -0.51, -1.49]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle177' geometry={nodes.particle177.geometry} material={materials.confetti} position={[-5.49, 10, 9.79]} rotation={[1.29, 0.05, 1.34]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle178' geometry={nodes.particle178.geometry} material={materials.confetti} position={[6.62, 10, 10]} rotation={[2.38, 0.04, 0.07]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle179' geometry={nodes.particle179.geometry} material={materials.confetti} position={[-3.6, 10, 2.64]} rotation={[3.08, 0.08, -0.9]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle180' geometry={nodes.particle180.geometry} material={materials.confetti} position={[7.59, 10, 5.72]} rotation={[-1.54, -0.64, -1.34]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle181' geometry={nodes.particle181.geometry} material={materials.confetti} position={[3.48, 10, 6.55]} rotation={[-2.49, 0.1, -1.72]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle182' geometry={nodes.particle182.geometry} material={materials.confetti} position={[-7.11, 10, 4.14]} rotation={[-2.47, 0.48, -2.68]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle183' geometry={nodes.particle183.geometry} material={materials.confetti} position={[-2.34, 10, -9.8]} rotation={[2.81, 0.57, -2.19]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle184' geometry={nodes.particle184.geometry} material={materials.confetti} position={[-7.29, 10, -4.29]} rotation={[-2.81, 1.4, -2.63]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle185' geometry={nodes.particle185.geometry} material={materials.confetti} position={[-1.77, 10, 3.17]} rotation={[-2.87, 0.9, -2.98]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle186' geometry={nodes.particle186.geometry} material={materials.confetti} position={[5.07, 10, -1.84]} rotation={[2.76, 0.81, -2.1]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle187' geometry={nodes.particle187.geometry} material={materials.confetti} position={[-2.3, 10, -6.05]} rotation={[-2.22, 1.13, 2.99]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle188' geometry={nodes.particle188.geometry} material={materials.confetti} position={[6.89, 10, 8.44]} rotation={[-1.49, 0.57, 2.25]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle189' geometry={nodes.particle189.geometry} material={materials.confetti} position={[7.05, 10, -9.75]} rotation={[1.39, -0.11, -1.35]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle190' geometry={nodes.particle190.geometry} material={materials.confetti} position={[-3.31, 10, -1.42]} rotation={[-0.66, -0.89, 1.08]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle191' geometry={nodes.particle191.geometry} material={materials.confetti} position={[-7.59, 10, 3.68]} rotation={[-2.28, -0.3, -3.13]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle192' geometry={nodes.particle192.geometry} material={materials.confetti} position={[1.46, 10, -2.47]} rotation={[1.79, 1.25, -0.76]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle193' geometry={nodes.particle193.geometry} material={materials.confetti} position={[-9.67, 10, 4.04]} rotation={[-1.74, 0.54, -1.23]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle194' geometry={nodes.particle194.geometry} material={materials.confetti} position={[-0.52, 10, 5.79]} rotation={[-2.82, 0.4, -1.52]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle195' geometry={nodes.particle195.geometry} material={materials.confetti} position={[4.26, 10, 4.8]} rotation={[2.55, 0.36, -1.29]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle196' geometry={nodes.particle196.geometry} material={materials.confetti} position={[9.89, 10, -3.3]} rotation={[1.64, 1.48, 1.53]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle197' geometry={nodes.particle197.geometry} material={materials.confetti} position={[-1.72, 10, -7.76]} rotation={[2.89, -0.05, -2.05]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle198' geometry={nodes.particle198.geometry} material={materials.confetti} position={[-5.9, 10, -3.8]} rotation={[-1.09, -0.14, -2.57]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle199' geometry={nodes.particle199.geometry} material={materials.confetti} position={[-0.84, 10, 6.02]} rotation={[-3, 0.12, -1.47]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle200' geometry={nodes.particle200.geometry} material={materials.confetti} position={[0.48, 10, -6.3]} rotation={[-1.06, 0.65, -1.15]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle201' geometry={nodes.particle201.geometry} material={materials.confetti} position={[-0.74, 10, -0.85]} rotation={[-1.57, -1.07, -1.07]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle202' geometry={nodes.particle202.geometry} material={materials.confetti} position={[4.17, 10, 9.49]} rotation={[0.6, 0.98, 1.53]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle203' geometry={nodes.particle203.geometry} material={materials.confetti} position={[-4.1, 10, 9.28]} rotation={[0.3, 1.2, -3.12]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle204' geometry={nodes.particle204.geometry} material={materials.confetti} position={[-1.6, 10, -5.89]} rotation={[-2.1, -0.18, -2.04]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle205' geometry={nodes.particle205.geometry} material={materials.confetti} position={[-6.22, 10, -4.68]} rotation={[-2.92, 0.36, -1.04]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle206' geometry={nodes.particle206.geometry} material={materials.confetti} position={[-7.57, 10, -1.32]} rotation={[-1.4, 0.55, -1.6]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle207' geometry={nodes.particle207.geometry} material={materials.confetti} position={[0.13, 10, -4.68]} rotation={[-2.11, -0.25, -2.47]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle208' geometry={nodes.particle208.geometry} material={materials.confetti} position={[4.74, 10, 3.63]} rotation={[0.12, 1.16, 1.08]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle209' geometry={nodes.particle209.geometry} material={materials.confetti} position={[-5.09, 10, 2.37]} rotation={[-1.39, 0.25, -1.57]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle210' geometry={nodes.particle210.geometry} material={materials.confetti} position={[3.94, 10, -5.66]} rotation={[-3.03, 0.62, -1.7]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle211' geometry={nodes.particle211.geometry} material={materials.confetti} position={[1.38, 10, 4.25]} rotation={[0.44, 1.1, 1.63]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle212' geometry={nodes.particle212.geometry} material={materials.confetti} position={[9.56, 10, 7.64]} rotation={[-2.24, 1.09, -0.28]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle213' geometry={nodes.particle213.geometry} material={materials.confetti} position={[6.16, 10, 2.66]} rotation={[1.5, -0.18, -0.7]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle214' geometry={nodes.particle214.geometry} material={materials.confetti} position={[4.58, 10, -5.43]} rotation={[-3.06, 0.87, -1.44]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle215' geometry={nodes.particle215.geometry} material={materials.confetti} position={[-6.69, 10, -8.28]} rotation={[-1.02, -0.3, -2.01]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle216' geometry={nodes.particle216.geometry} material={materials.confetti} position={[0.8, 10, -1.77]} rotation={[-2.83, -0.82, -1.37]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle217' geometry={nodes.particle217.geometry} material={materials.confetti} position={[-2.99, 10, -5.03]} rotation={[-1.31, -0.95, -2.22]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle218' geometry={nodes.particle218.geometry} material={materials.confetti} position={[9.31, 10, -9.32]} rotation={[-1.83, 1.1, -2.19]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle219' geometry={nodes.particle219.geometry} material={materials.confetti} position={[-7.71, 10, 0.86]} rotation={[2.02, 1.06, -1.65]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle220' geometry={nodes.particle220.geometry} material={materials.confetti} position={[6.05, 10, 1.64]} rotation={[-1.49, 0.8, -1.91]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle221' geometry={nodes.particle221.geometry} material={materials.confetti} position={[6.95, 10, 5.31]} rotation={[-2.9, -0.15, -1.63]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle222' geometry={nodes.particle222.geometry} material={materials.confetti} position={[-5.56, 10, 3.23]} rotation={[-0.77, 0.78, -1.85]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle223' geometry={nodes.particle223.geometry} material={materials.confetti} position={[-7.36, 10, -1.17]} rotation={[-2.39, -0.76, -1.72]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle224' geometry={nodes.particle224.geometry} material={materials.confetti} position={[7.15, 10, -0.37]} rotation={[2.74, 0.55, -0.61]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle225' geometry={nodes.particle225.geometry} material={materials.confetti} position={[-0.46, 10, -2.65]} rotation={[-1.77, -0.17, 0.17]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle226' geometry={nodes.particle226.geometry} material={materials.confetti} position={[8.03, 10, -9.2]} rotation={[-1.18, 0.62, 2.97]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle227' geometry={nodes.particle227.geometry} material={materials.confetti} position={[5.45, 10, 5.86]} rotation={[1.85, -0.06, -2.02]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle228' geometry={nodes.particle228.geometry} material={materials.confetti} position={[-7.09, 10, -0.86]} rotation={[0.01, -0.72, 1.32]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle229' geometry={nodes.particle229.geometry} material={materials.confetti} position={[3.52, 10, -5.95]} rotation={[-2.57, 1.4, -0.97]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle230' geometry={nodes.particle230.geometry} material={materials.confetti} position={[-2.39, 10, 7.94]} rotation={[1.77, 0.88, 1.31]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle231' geometry={nodes.particle231.geometry} material={materials.confetti} position={[0.3, 10, 2.14]} rotation={[1.04, 0.11, -0.13]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle232' geometry={nodes.particle232.geometry} material={materials.confetti} position={[-4.78, 10, 1.9]} rotation={[2.07, 0.18, -1.02]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle233' geometry={nodes.particle233.geometry} material={materials.confetti} position={[6.21, 10, 1.41]} rotation={[-2.93, -0.46, -2.63]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle234' geometry={nodes.particle234.geometry} material={materials.confetti} position={[5.01, 10, -5.59]} rotation={[1.93, -0.46, -1.55]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle235' geometry={nodes.particle235.geometry} material={materials.confetti} position={[-2.92, 10, 8.72]} rotation={[-2.12, -0.11, -1.5]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle236' geometry={nodes.particle236.geometry} material={materials.confetti} position={[-3.61, 10, -2.36]} rotation={[2.98, -0.24, -0.71]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle237' geometry={nodes.particle237.geometry} material={materials.confetti} position={[8.86, 10, 9.59]} rotation={[-3.14, -0.42, -0.81]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle238' geometry={nodes.particle238.geometry} material={materials.confetti} position={[7.78, 10, -5.84]} rotation={[1.23, 0.41, 1.86]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle239' geometry={nodes.particle239.geometry} material={materials.confetti} position={[8.45, 10, -4.36]} rotation={[-1.46, -0.38, -2.46]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle240' geometry={nodes.particle240.geometry} material={materials.confetti} position={[-0.58, 10, -1.08]} rotation={[-2.88, 0.11, -1.39]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle241' geometry={nodes.particle241.geometry} material={materials.confetti} position={[-9.33, 10, 9.97]} rotation={[-1.13, -0.12, -1.78]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle242' geometry={nodes.particle242.geometry} material={materials.confetti} position={[9.59, 10, -4.86]} rotation={[-1.09, 0.72, -2.65]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle243' geometry={nodes.particle243.geometry} material={materials.confetti} position={[6.93, 10, -5.94]} rotation={[2.2, -0.7, -1.54]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle244' geometry={nodes.particle244.geometry} material={materials.confetti} position={[1.9, 10, -7.28]} rotation={[-0.65, -1.28, 0.43]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle245' geometry={nodes.particle245.geometry} material={materials.confetti} position={[-2, 10, -4.48]} rotation={[1.89, -0.11, 0.16]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle246' geometry={nodes.particle246.geometry} material={materials.confetti} position={[-9.49, 10, -0.97]} rotation={[2.5, -0.09, -1.06]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle247' geometry={nodes.particle247.geometry} material={materials.confetti} position={[9.21, 10, 3.81]} rotation={[-2.02, -0.72, -1.48]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle248' geometry={nodes.particle248.geometry} material={materials.confetti} position={[-2.29, 10, -1.05]} rotation={[2.37, 0, -0.54]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle249' geometry={nodes.particle249.geometry} material={materials.confetti} position={[-6.47, 10, -8.12]} rotation={[-2.42, -0.81, -1.19]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle250' geometry={nodes.particle250.geometry} material={materials.confetti} position={[7.98, 10, -7.95]} rotation={[0.12, 0.67, 2.11]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle251' geometry={nodes.particle251.geometry} material={materials.confetti} position={[-8.77, 10, -9.57]} rotation={[0.27, 1.14, 3.04]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle252' geometry={nodes.particle252.geometry} material={materials.confetti} position={[5.05, 10, -9.34]} rotation={[1.45, 0.53, 1.09]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle253' geometry={nodes.particle253.geometry} material={materials.confetti} position={[-1.19, 10, -8.19]} rotation={[1.91, -0.13, -0.6]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle254' geometry={nodes.particle254.geometry} material={materials.confetti} position={[-8.95, 10, 7.31]} rotation={[2.95, 0.04, -1.27]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle255' geometry={nodes.particle255.geometry} material={materials.confetti} position={[-4.56, 10, -8.41]} rotation={[-1.17, -0.14, -2.3]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle256' geometry={nodes.particle256.geometry} material={materials.confetti} position={[8.23, 10, -4.51]} rotation={[-2.99, 0.69, -1.5]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle257' geometry={nodes.particle257.geometry} material={materials.confetti} position={[-6.63, 10, 2.97]} rotation={[1.35, 1.21, -1.26]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle258' geometry={nodes.particle258.geometry} material={materials.confetti} position={[-7.83, 10, 6.49]} rotation={[-1.37, -0.7, -0.6]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle259' geometry={nodes.particle259.geometry} material={materials.confetti} position={[-1.74, 10, -2.76]} rotation={[-1.88, 0.81, -1.91]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle260' geometry={nodes.particle260.geometry} material={materials.confetti} position={[5.23, 10, 9.1]} rotation={[-2.49, 0.45, -3.03]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle261' geometry={nodes.particle261.geometry} material={materials.confetti} position={[-8.51, 10, 2.39]} rotation={[1.41, 0.65, -0.68]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle262' geometry={nodes.particle262.geometry} material={materials.confetti} position={[3.03, 10, 3.58]} rotation={[-1.42, -0.6, -0.2]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle263' geometry={nodes.particle263.geometry} material={materials.confetti} position={[0.22, 10, 0.26]} rotation={[-0.07, 0.05, 3.03]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle264' geometry={nodes.particle264.geometry} material={materials.confetti} position={[9.2, 10, 1.31]} rotation={[0.72, 0.24, 2.8]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle265' geometry={nodes.particle265.geometry} material={materials.confetti} position={[5.31, 10, -7.27]} rotation={[-2.38, -0.68, -2.59]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle266' geometry={nodes.particle266.geometry} material={materials.confetti} position={[2.07, 10, 3.35]} rotation={[1.47, 0.74, -0.26]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle267' geometry={nodes.particle267.geometry} material={materials.confetti} position={[1.96, 10, 3.97]} rotation={[-0.76, 0.21, 1.97]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle268' geometry={nodes.particle268.geometry} material={materials.confetti} position={[-8.58, 10, 9.57]} rotation={[1.74, -0.04, 3.06]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle269' geometry={nodes.particle269.geometry} material={materials.confetti} position={[-7.41, 10, -9.92]} rotation={[-1.2, -0.43, -2.36]} scale={0} 
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle270' geometry={nodes.particle270.geometry} material={materials.confetti} position={[2.59, 10, 1.01]} rotation={[-3.04, -0.41, -1.7]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle271' geometry={nodes.particle271.geometry} material={materials.confetti} position={[-0.4, 10, -3.9]} rotation={[2.65, 0.65, -1.21]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle272' geometry={nodes.particle272.geometry} material={materials.confetti} position={[5.63, 10, 1.8]} rotation={[-2.23, 0.6, 2.82]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle273' geometry={nodes.particle273.geometry} material={materials.confetti} position={[-5.7, 10, -9.9]} rotation={[2.76, 0.33, -2.26]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle274' geometry={nodes.particle274.geometry} material={materials.confetti} position={[9.85, 10, 3.57]} rotation={[3.07, 1.03, -2.11]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle275' geometry={nodes.particle275.geometry} material={materials.confetti} position={[-9.79, 10, -2.83]} rotation={[-1.51, 0.5, 2.29]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle276' geometry={nodes.particle276.geometry} material={materials.confetti} position={[-3.33, 10, -8.92]} rotation={[1.29, 1.03, -1.18]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle277' geometry={nodes.particle277.geometry} material={materials.confetti} position={[7.13, 10, 4.63]} rotation={[-2.47, -0.09, -0.9]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle278' geometry={nodes.particle278.geometry} material={materials.confetti} position={[6.99, 10, -2.19]} rotation={[-2.17, 0.21, -1]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle279' geometry={nodes.particle279.geometry} material={materials.confetti} position={[4.65, 10, 8.32]} rotation={[0.64, 0.86, 2.19]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle280' geometry={nodes.particle280.geometry} material={materials.confetti} position={[-1.86, 10, 8.8]} rotation={[1.49, 0.99, 1.46]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle281' geometry={nodes.particle281.geometry} material={materials.confetti} position={[-1.89, 10, 1.3]} rotation={[0.95, 0.11, 0.77]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle282' geometry={nodes.particle282.geometry} material={materials.confetti} position={[4.1, 10, 3.87]} rotation={[-2.33, 0.76, -1.22]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle283' geometry={nodes.particle283.geometry} material={materials.confetti} position={[2.54, 10, -7.74]} rotation={[2.45, 1.48, 0.77]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle284' geometry={nodes.particle284.geometry} material={materials.confetti} position={[-2.72, 10, -6.12]} rotation={[1.59, -0.28, 0.2]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle285' geometry={nodes.particle285.geometry} material={materials.confetti} position={[6.81, 10, -7.81]} rotation={[1.65, 0.79, -0.3]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle286' geometry={nodes.particle286.geometry} material={materials.confetti} position={[4.42, 10, -7.07]} rotation={[-0.56, 1.14, -2.81]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle287' geometry={nodes.particle287.geometry} material={materials.confetti} position={[-9.64, 10, 5.29]} rotation={[-2.81, 0.25, -2.19]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle288' geometry={nodes.particle288.geometry} material={materials.confetti} position={[-2.36, 10, -7.3]} rotation={[1.75, 0.78, -1.64]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle289' geometry={nodes.particle289.geometry} material={materials.confetti} position={[8.16, 10, -1.39]} rotation={[-2.58, 0.42, -1.11]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle290' geometry={nodes.particle290.geometry} material={materials.confetti} position={[1.59, 10, 4.4]} rotation={[-2.62, -0.4, 0.37]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle291' geometry={nodes.particle291.geometry} material={materials.confetti} position={[8.08, 10, 2.36]} rotation={[2.04, -1.32, -1.89]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle292' geometry={nodes.particle292.geometry} material={materials.confetti} position={[5.88, 10, 5.7]} rotation={[-1.54, 0.18, -2.12]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle293' geometry={nodes.particle293.geometry} material={materials.confetti} position={[6.1, 10, -4.61]} rotation={[2.12, 0.73, -1.09]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle294' geometry={nodes.particle294.geometry} material={materials.confetti} position={[-0.31, 10, 9.23]} rotation={[-1.73, -0.33, -1.61]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle295' geometry={nodes.particle295.geometry} material={materials.confetti} position={[3.18, 10, -7.51]} rotation={[2.09, 0.94, -0.7]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle296' geometry={nodes.particle296.geometry} material={materials.confetti} position={[-9.27, 10, 6.84]} rotation={[-0.32, 0.33, 1.68]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle297' geometry={nodes.particle297.geometry} material={materials.confetti} position={[-3.18, 10, -3.47]} rotation={[3.01, 0.78, 0.59]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle298' geometry={nodes.particle298.geometry} material={materials.confetti} position={[-6.32, 10, 0.94]} rotation={[1.63, 0.3, -0.18]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
        <mesh name='particle299' geometry={nodes.particle299.geometry} material={materials.confetti} position={[2.78, 10, -9.85]} rotation={[-2.73, 0.19, -0.87]} scale={0}  
          material-color= {confettiColor ? confettiColor : confettiColorDefault}
        />
      </group>
    </group>
  )
}