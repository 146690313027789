/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

import { standardSubmitButton } from '../../helpers/buttons'
import { securityCodeTextField } from '../../helpers/textfields'
import { standardErrorContainer } from '../../helpers/errors'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

const Security = (props) => {

  // Destructure Props
  const { securityCode, securityPassed, setSecurityPassed } = props

  // Guess and error states
  const [ securityCodeGuess, setSecurityCodeGuess ] = useState('')
  const [ securityCodeErrors, setSecurityCodeErrors ] = useState(false)

  // Execute button pressed
  const handleSubmitSecurityCode = (e) => {
    e.preventDefault()

    // If guess equals code, security is passed, if not, show an error message
    if (securityCode === securityCodeGuess) {
      setSecurityCodeErrors(false)
      setSecurityPassed(true)

      // Collect Analytics
      customAnalyticsEvent(
        'unwrap_security_passed', 
        'unwrap_security_passed', 
        'unwrap_process', 
        'unwrap_security_passed', 
        'Security'
      )
    } else {
      setSecurityCodeErrors(true)

      // Collect Analytics
      customAnalyticsEvent(
        'unwrap_security_failed', 
        'unwrap_security_failed', 
        'unwrap_process', 
        'unwrap_security_failed', 
        'Security'
      )
    }
  }

  return (
    <>

      {/* Security Code Body */}
      <Box sx={{ 
        width: '100vw', height: '100vh',
        display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'stretch',
      }}>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={!securityPassed ? true : false}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
          keepMounted
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <Fade in={!securityPassed ? true : false}>
            <Paper sx={{
              width: '85%',
              height: '85%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

              {/* Title */}
              <Typography>
                <strong>Security Code</strong>
              </Typography>
              
              {/* TextField and Button Container */}
              <Box 
                component="form" 
                onSubmit={handleSubmitSecurityCode} 
                noValidate 
                textAlign={'center'}
                sx={{ 
                  mt: 1,
                  width: '100%', maxWidth: '300px',
                }}
              >
                
                {/* Security Code Guess TextField */}
                {securityCodeTextField(
                  'securityCodeGuess',
                  'Security Code',
                  'off',
                  true,
                  setSecurityCodeGuess
                )}

                {/* Submit button */}
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {standardSubmitButton(
                    'Submit', 
                    'submit', 
                    securityCodeGuess === '',
                    'primary',
                    2,
                    2, 
                    '155px', 
                    '45px'
                  )}
                </Box>
                
                {/* Error Message, if errors */}
                {securityCodeErrors &&
                  standardErrorContainer(
                    '*Incorrect code',
                    0,
                    0
                  )
                }
                
              </Box>

              {/* Useful Info */}
              <Typography display="block" sx={{ mt: 1, width: '90%', maxWidth: '400px' }}>
                *The person who sent this gift to you received the code when creating it, and if a link was emailed to you, the code is in the email
              </Typography>
              
            </Paper>
          </Fade>
        </Modal>
      </Box>
    </>
  )
}

export default Security
