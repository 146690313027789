
// Profile
export const profPicDefaultURL = 'https://3d-wrap-bizops-images.s3.amazonaws.com/social-media-logo-600.jpg'

// Cards
export const cardWidth = 250 // The width for GiftsCard and GiftIdeasCard
export const acceptRejectDisappearWidth = 450 //The width at which buttons disappear from the righthand side of a FriendsListItem

// Navbar
export const navbarHeight = '60px' //Height for the navigation bar
export const navbarBgDefaultColor = '#e1e1e1' // lightgray

// Stepper Defaults
export const bottomSteps = ['Gift', 'Package', 'Review', 'Send'] // Bottom Steps for Wrapping Present Process
export const ministepsHeight = '25px' // Height for the ministep/arrow elements in wrap and unwrap process

// Canvas/Package Design
export const boxTypeDefault = 'cardboard' //Box type default is cardboard
export const wrapTypeDefault = 'neat' // Wrap type default is neat

export const canvasBgDefaultColorWrap = '#f5f5f5' // whitesmoke
export const canvasBgDefaultColorUnwrap = '#e1e1e1' // lightgray
export const confettiColorDefault = '#ffd700' //gold
export const ribbonColorDefault = '#ffd700' //gold
export const cardTextColorDefault = 'ff0000' //red
export const envelopeColorDefault = '#ff0000' //red
export const wrappingColorDefault = '#2BC70A' //green

// Models
export const positionChangeHeightSm = 700
export const positionChangeWidthSm = 600
export const positionChangeWidthMd = 900
export const yPositionDefault = -1
export const yPositionHome = -1
export const yPositionSmall = -1
export const yPositionSmallExample = 0
export const yPositionBoxNoPaper = yPositionDefault + 1
export const yPositionHomeBoxNoPaper = yPositionHome + 1
export const yPositionSmallBoxNoPaper = yPositionSmall + 1
export const yPositionSmallExampleBoxNoPaper = yPositionSmallExample + 1
export const yRotationDefault = 30
export const xRotationSmall = 5
export const yRotationSmall = 30
export const zRotationSmall = 0
export const yRotationDefaultBoxNoPaper = yRotationDefault - 90
export const xRotationSmallBoxNoPaper = xRotationSmall
export const yRotationSmallBoxNoPaper = yRotationSmall - 90
export const zRotationSmallBoxNoPaper = zRotationSmall
export const yRotationDefaultText = 10
export const yRotationHomeScreenText = 20

export const envelopeAndCardActionStringsArray = ['envelope-solo-movement', 'envelope-solo-opening', 'card-solo-movement']
export const messyWrappingActionStringsArray = ['wrap-left-crumpling', 'wrap-left-movement', 'wrap-right-crumpling', 'wrap-right-movement', 'wrap-front-crumpling', 'wrap-front-movement', 'wrap-back-crumpling', 'wrap-back-movement', 'wrap-top-crumpling', 'wrap-top-movement', 'wrap-bottom-crumpling', 'wrap-bottom-movement']
export const neatWrappingActionStringsArray = ['neat-wrapping-solo-opening', 'neat-wrapping-solo-moving']
export const ribbonActionStringsArray = ['bow-loop-solo-opening', 'bow-loop-solo-movement', 'bow-knot-solo', 'side-ribbon-1-solo', 'side-ribbon-2-solo']
export const swipewrapActionStringsArray = ['wrapping-top-crumpling', 'wrapping-top-movement', 'wrapping-bottom-movement']

export const boxNoPaperGLB = '/models/box-no-paper-2.glb'
export const cardboardBoxGLB = '/models/cardboard-box-solo-2.glb'
export const confetti300GLB = '/models/confetti-300.glb'
export const envelopeAndCardGLB = '/models/envelope-and-card-1.glb'
export const messyWrappingGLB = '/models/messy-wrapping.glb'
export const neatWrappingGLB = '/models/neat-wrapping-1.glb'
export const ribbonGLB = '/models/ribbon-solo-1.glb'
export const swipewrapCloneGLB = '/models/swipewrap-clone-wrapping-1.glb'

// Form Options

// Nav menu when logged in
export const navMenuOptionsLoggedIn = [
  // 'Send Gift',
  // 'Example',
  // 'Gift Ideas', 
  'Profile', 
  // 'FAQ', 
  'Logout'
]

// Nav menu when not logged in
export const navMenuOptionsNotLoggedIn = [
  // 'Send Gift',
  // 'Example',
  // 'Gift Ideas', 
  // 'FAQ', 
  'Login'
]

// Holiday List
export const holidays = [
  'Christmas',
  'Hannukah',
  'Kwanzaa',
  'Valentines Day',
  'Halloween',
  'Thanksgiving',
  'Easter',
  'Passover',
  'Ramadan',
  'Holi'
]

// Holiday-to-Emoji List
export const holidayEmojis = {
  'Christmas': '🎄',
  'Hannukah': '🕯',
  'Kwanzaa': '🙌🏿',
  'Valentines Day': '💖',
  'Halloween': '🎃',
  'Thanksgiving': '🦃',
  'Easter': '🐰',
  'Passover': '🔯',
  'Ramadan': '🌙',
  'Holi': '🎨',
}

// Background Color Wrap Options
export const bgColors = [
  '#f5f5f5', // whitesmoke
  '#e1e1e1', // light gray (same as theme default, makes nav bar look transparent)
  '#faf0e6', // linen
  '#fde2e4', // pink
  '#d0f0c0' // tea green
]

// Wrap Color Wrap Options
export const wrapColors = [
  '#800000', // maroon
  '#ff0000', // red
  '#ff00ff', // magenta
  '#ffa500', // orange
  '#ffd700', // gold
  '#2BC70A', // green
  '#008080', // teal
  '#0000ff', // blue
  '#dda0dd', // plum
  '#800080' // purple
]

// Ribbon Color Wrap Options
export const ribbonColors = [
  '#ffd700', // gold
  '#c0c0c0', // silver
  '#ff0000', // red
  '#0000ff', // blue
  '#2BC70A' // green
]

// Confetti Color Wrap Options
export const confettiColors = [
  '#ffd700', // gold
  '#c0c0c0', // silver
  '#ff0000', // red
  '#0000ff', // blue
  '#2BC70A' // green
]

// Envelope Color Wrap Options
export const envelopeColors = [
  '#c0c0c0', // silver
  '#ff0000', // red
  '#ff00ff', // magenta
  '#ffa500', // orange
  '#ffd700', // gold
  '#2BC70A', // green
  '#008080', // teal
  '#0000ff', // blue
  '#dda0dd', // plum
  '#800080' // purple
]

// Card Text Color Wrap Options
export const cardTextColors = [
  '#c0c0c0', // silver
  '#ff0000', // red
  '#ff00ff', // magenta
  '#ffa500', // orange
  '#ffd700', // gold
  '#2BC70A', // green
  '#008080', // teal
  '#0000ff', // blue
  '#dda0dd', // plum
  '#800080' // purple
]

// Salutations Options for CardText
export const salutations = [
  'To',
  'Dear',
  'Yo'
]

// Message Options for CardText
export const cardMessages = [
  'Happy Birthday!',
  'Congratulations!',
  'Happy Holidays!',
  'Thank You!',
  'Thought of You',
  'You\'re the Best!',
  'I Love You',
  'Get Well Soon',
  'My Condolences'
]

// Signoff Options for CardText
export const signOffs = [
  'From',
  'Love'
]

// Wrap Type Options
export const wrapTypes = [
  'neat',
  'messy',
  'swipe'
]

// Box Type Wrap Options
export const boxTypes = [
  'cardboard',
  'white'
]

// Will or Will Not Use Feedback Options
export const useOrNot = [
  'Will use',
  'Prefer something else'
]

// Gift Types Wrap Options
export const giftTypes = [
  'oneItem',
  'chooseOne'
]

// Default oneItem Questions
export const questionsOneItemDefaultArray = [
  {
    q: 'No questions',
    checked: true,
  },
  {
    q: 'Will you actually use it, or would you prefer a similarly priced item?',
    checked: false,
  },
  {
    q: 'Which color do you like most?',
    checked: false,
  },
  {
    q: 'Which size will fit you best?',
    checked: false,
  },
  {
    q: 'What address should it be sent to?',
    checked: false,
  },
  {
    q: '',
    checked: false,
  }
]

// Default chooseOne questions
export const questionsChooseOneDefaultArray = [
  {
    q: 'Which gift do you choose?',
    checked: true,
  },
  {
    q: 'Will you actually use it, or would you prefer a similarly priced item?',
    checked: false,
  },
  {
    q: 'Which color do you like most?',
    checked: false,
  },
  {
    q: 'Which size will fit you best?',
    checked: false,
  },
  {
    q: 'What address should it be sent to?',
    checked: false,
  },
  {
    q: '',
    checked: false,
  }
]

// Default oneItem giftOptions
export const giftOptionsOneItemDefault = [
  [
    {
      link: '',
      image: '',
      brand: '',
      product: '',
    }
  ]
]

// Default chooseOne giftOptions
export const giftOptionsChooseOneDefault = [
  [
    {
      link: '',
      image: '',
      brand: '',
      product: '',
    }
  ],
  [
    {
      link: '',
      image: '',
      brand: '',
      product: '',
    }
  ]
]


// Permitted Country Codes — https://www.iban.com/country-codes
export const permittedCountryCodes = [
  'US', // United States
  'FR', // France
  'AU', // Australia
  'AT', // Austria
  'BE', // Belgium
  'CA', // Canada
  'DK', // Denmark
  'FI', // Finland
  'DE', // Germany
  'GR', // Greece
  'HK', // Hong Kong
  'IE', // Ireland
  'IL', // Israel
  'IT', // Italy
  'JP', // Japan
  'LI', // Liechtenstein
  'LU', // Luxembourg
  'MX', // Mexico
  'NL', // The Netherlands
  'NZ', // New Zealand
  'NO', // Norwary
  'PL', // Poland
  'PT', // Portugal
  'PR', // Puerto Rico
  'SG', // Singapore
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // Great Britain

  'AF', // Afghanistan
  'AL', // Albania
  'DZ', // Algeria
  'AM', // American Samoa
  'AD', // Andorra
  'AO', // Angola
  'AI', // Anguilla
  'AQ', // Antarctica
  'AG', // Antigua and Barbuda
  'AR', // Argentina
  'AM', // Armenia
  'AW', // Aruba
  'AZ', // Azerbaijan
  'BS', // The Bahamas
  'BH', // Bahrain
  'BD', // Bangladesh
  'BB', // Barbados
  'BY', // Belarus
  'BZ', // Belize
  'BJ', // Benin
  'BM', // Bermuda
  'BT', // Bhutan
  'BO', // Bolivia
  'BQ', // Bonaire
  'BA', // Bosnia and Herzegovina
  'BW', // Botswana
  'BV', // Bouvet Island
  'IO', // British Indian Ocean Territory
  'BN', // Brunei
  'BG', // Bulgaria
  'BF', // Burkina Faso
  'BI', // Burundi
  'CV', // Cabo Verde
  'KH', // Cambodia
  'CM', // Camaroon
  'KY', // the Cayman Islands
  'CF', // Central African Republic
  'TD', // Chad
  'CL', // Chile
  'CN', // China
  'CX', // Christmas Island
  'CC', // The Cocos Islands
  'CO', // Colombia
  'KM', // The Comoros
  'CD', // Democratic Republic of Congo
  'CG', // Congo
  'CK', // The Cook Islands
  'CR', // Costa Rica
  'HR', // Croatia
  'CU', // Cuba
  'CW', // Curacao
  'CY', // Cyprus
  'CZ', // Czech Republic
  'CI', // Ivory Coast
  'DJ', // Djibouti
  'DM', // Dominica
  'DO', // The Dominican Republic
  'EC', // Ecuador
  'EG', // Egypt
  'SV', // El Salvador
  'GQ', // Equatorial Guinea
  'ER', // Eritrea
  'EE', // Estonia
  'SZ', // Eswatini
  'ET', // Ethiopia
  'FK', // Falkland Islands
  'FO', // Faroe Islands
  'FJ', // Fiji
  'GF', // French Guiana
  'PF', // French Polynesia
  'TF', // French Southern Territories
  'GA', // Gabon
  'GM', // Gambia
  'GE', // Georgia
  'GH', // Ghana
  'GI', // Gibraltar
  'GL', // Greenland
  'GD', // Grenada
  'GP', // Guadeloupe
  'GU', // Guam
  'GT', // Guatemala
  'GG', // Guernsey
  'GN', // Guinea
  'GW', // Guinea-Bissau
  'GY', // Guyana
  'HT', // Haiti
  'HM', // Heard Island and McDonald Islands
  'VA', // The Holy See
  'HN', // Honduras
  'HU', // Hungary
  'IS', // Iceland
  'IN', // India
  'ID', // Indonesia
  'IR', // Iran
  'IQ', // Iraq
  'IM', // Isle of Man
  'JM', // Jamaica
  'JE', // Jersey
  'JO', // Jordan
  'KZ', // Kazakhstan
  'KE', // Kenya
  'KI', // Kiribati
  // 'KP', // North Korea
  'KR', // South Korea
  'KW', // Kuwait
  'KG', // Kyrgyzstan
  'LA', // Laos
  'LV', // Latvia
  'LB', // Lebanon
  'LS', // Lesotho
  'LR', // Liberia
  'LY', // Libya
  'LT', // Lithuania
  'MO', // Macao
  'MG', // Madagascar
  'MW', // Malawi
  'MY', // Malaysia
  'MV', // The Maldives
  'ML', // Mali
  'MT', // Malta
  'MH', // The Marshall Islands
  'MQ', // Martinique
  'MR', // Mauritania
  'MU', // Mauritius
  'YT', // Mayotte
  'FM', // Micronesia
  'MD', // Maldova
  'MC', // Monaco
  'MN', // Mongolia
  'ME', // Montenegro
  'MS', // Montserrat
  'MA', // Morocco
  'MZ', // Mozambique
  'MM', // Myanmar
  'NA', // Namibia
  'NR', // Nauru
  'NP', // Nepal
  'NC', // New Caledonia
  'NI', // Nicaragua
  'NE', // Niger
  'NG', // Nigeria
  'NU', // Niue
  'NF', // Norfolk Island
  'MP', // Northern Mariana Islands
  'OM', // Oman
  'PK', // Pakistan
  'PW', // Palau
  'PS', // Palestine
  'PA', // Panama
  'PG', // Papua New Guinea
  'PY', // Paraguay
  'PE', // Peru
  'PH', // The Philippines
  'PN', // Pitcairn
  'QA', // Qatar
  'MK', // Macedonia
  'RO', // Romania
  // 'RU', // Russia
  'RW', // Rwanda
  'RE', // Reunion
  'BL', // Saint Barthelemy
  'SH', // Saint Helena
  'KN', // Saint Kitts and Nevis
  'LC', // Saint Lucia
  'MF', // Saint Martin
  'PM', // Saint Pierre and Miquelon
  'VC', // Saint Vincent and the Grenadines
  'WS', // Samoa
  'SM', // San Marino
  'ST', // Sao Tome and Principe
  'SA', // Saudi Arabia
  'SN', // Senegal
  'RS', // Serbia
  'SC', // Seychelles
  'SL', // Sierra Leone
  'SX', // Sint Maarten
  'SK', // Slovakia
  'SI', // Slovenia
  'SB', // Solomon Islands
  'SO', // Somalia
  'ZA', // South Africa
  'GS', // South Georgia and the Sandwich Islands
  'SS', // South Sudan
  'LK', // Sri Lanka
  'SD', // Sudan
  'SR', // Suriname
  'SJ', // Svalbard
  'SY', // Syria
  'TW', // Taiwan
  'TJ', // Tajikistan
  'TZ', // Tanzania
  'TH', // Thailand
  'TL', // Timor-Leste
  'TG', // Togo
  'TK', // Tokelau
  'TO', // Tonga
  'TT', // Trinidad and Tobago
  'TN', // Tunisia
  'TR', // Turkey
  'TM', // Turkmenistan
  'TC', // Turks and Caicos
  'TV', // Tuvalu
  'UG', // Uganda
  'UA', // Ukraine
  'AE', // United Arab Emirates
  'UM', // United States Minor Outlying Islands
  'UY', // Uruguay
  'UZ', // Uzbekistan
  'VU', // Vanuatu
  'VE', // Venezuela
  'VN', // Vietnam
  'VG', // British Virgin Islands
  'VI', // US Virgin Islands
  'WF', // Wallis and Futuna
  'EH', // Western Sahara
  'YE', // Yemen
  'ZM', // Zambia
  'ZW', // Zimbabwe
  'AX', // Aland Islands
  ''
]