/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

// mui
import TextField from '@mui/material/TextField'

// Recipient Name and Sender Name in CardText.js in wrap process
export const cardTextTextField = (element, elementName, placeholder, value, changeFunction) => {
  return (
    <TextField 
      id={`${element}-outlined`}
      variant='outlined'
      name={`${elementName}`}
      maxLength={30}
      aria-label={`${elementName}`}
      placeholder={`${placeholder}`}
      autoComplete='off'
      value={value}
      onChange={(e) => changeFunction(e)}
      color="primary"
      size="small"
      className={value ? `${elementName} text-${value}` : `${elementName} text-` }
      sx={{ width: '100%' }}
    />
  )
}

// Question Feedback TextFields in questionFeedback.js during unwrap process
export const feedbackTextField = (name, questionIndex, minRows, maxRows, placeholder, feedback, handleSomethingElse, handleSetAnswer) => {
  return (
    <TextField
      placeholder={placeholder}
      autoComplete='off'
      variant='outlined'
      name={name}
      value={name === 'somethingElseLink' ? feedback.hasOwnProperty('somethingElseLink') ? feedback.somethingElseLink : '' : feedback.qAndA[questionIndex].hasOwnProperty('a') ? feedback.qAndA[questionIndex].a : ''}
      minRows={minRows}
      maxRows={maxRows}
      multiline={maxRows > 1}
      size='small'
      onChange={name === 'somethingElseLink' ? (e) => handleSomethingElse(e, questionIndex) : (e) => handleSetAnswer(e, questionIndex)}
      sx={{ width: '100%' }}
    />
  )
}

// Login/Register process TextFields (username, email, password, passwordConfirmation) in inProcessLoginRegister.js
export const loginTextField = (name, label, value, variant, required, fullWidth, autoFocus, autoComplete, form, setForm, setErrors, changeHandler ) => {
  return (
    <TextField
      required={required}
      fullWidth={fullWidth}
      label={label}
      variant={variant}
      name={name}
      type={name.includes('password') || name.includes('Password') ? 'password' : ''}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={value} 
      onChange={(e) => changeHandler(e, setErrors, setForm, form)}
      inputProps={{ style: { textTransform: name.includes('password') ? 'none' : 'lowerCase' } }}
    />
  )
}

// Searchbar TextField in FindFriends.js and GiftIdeas.js
export const searchTextField = (name, placeholder, changeHandler, value ) => {
  return (
    <TextField 
      fullWidth 
      name={name} 
      autoComplete='off'
      placeholder={placeholder}
      onChange={changeHandler} 
      value={value} 
      sx={{ 
        pt: 3, 
      }} 
    />
  )
}

// TextField for custom question in Questions.js during wrap process
export const customQuestionTextField = (name, placeholder, value, changeHandler, question, questionIndex) => {
  return (
    <TextField  
      variant="standard" 
      autoComplete='off'
      name={name}
      maxLength={150}
      placeholder={placeholder}
      value={value}
      onChange={(e) => changeHandler(e, question, questionIndex)}
      color="primary"
      sx={{ 
        width: 'calc(100% - 60px)',
        maxWidth: '500px',
      }}
    />
  )
}

// GiftItem TextFields (link, brand, product); appears during the creating gift options part of the wrap process
export const giftItemTextField = (name, label, value, placeholder, focused, changeHandler, setPutErrors, setGiftOptions, giftOptions, optionIndex, itemIndex, setLoadingImage ) => {
  return (
    <TextField
      focused={focused}
      label={label}
      autoComplete='off'
      variant='outlined'
      placeholder={placeholder}
      name={name}
      value={value}
      minRows={1}
      maxRows={1}
      inputProps={{ maxLength: name === 'link' ? 500 : 50 }}
      size='small'
      onChange={(e) => changeHandler(e, setPutErrors, setGiftOptions, giftOptions, optionIndex, itemIndex, setLoadingImage)}
      sx={{ width: '90%' }}
    />
  )
}

// Note Textfield, appears in the Note.js file, for writing a longer note during the wrap process
export const noteTextField = (name, value, rows, placeholder, changeHandler) => {
  
  return (
    <TextField
      autoFocus={true}
      name={name}
      multiline
      autoComplete='off'
      rows={rows}
      maxLength={1000}
      aria-label={name}
      value= {value ? value : ''}
      placeholder={placeholder}
      onChange={changeHandler}
      color="primary"
      style={{ 
        width: '90%', 
        fontFamily: 'Lato, Arial, sans-serif', 
        fontSize: '16px', 
        padding: '10px', 
      }}
    />
  )
}

// Textfield for inputting the recipient email address in emailTo.js during the wrap process
export const emailTextField = (name, value, placeholder, variant, clickHandler, state, setState, width) => {
  return (
    <TextField 
      variant={variant}
      name={name}
      maxLength={150}
      aria-label={name}
      placeholder={placeholder}
      autoComplete='off'
      value={value}
      onChange={(e) => clickHandler(e, state, setState)}
      color="primary"
      sx={{ 
        // backgroundColor: 'yellow',
        // width: width >= 440 ? 'calc(100% - 120px)' : '95%',
        width: '100%',
        // maxWidth: '325px', 
        // ml: '45px', 
        mt: 1,
      }}
    />
  )
}

// Textfield for inputting the security code in Security.js during the unwrap process
export const securityCodeTextField = (name, label, autoComplete, autoFocus, changeHandler) => {
  return (
    <TextField
      margin="normal"
      required
      fullWidth
      id={name}
      label={label}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      onChange={(e) => changeHandler(e.target.value)}
    />
  )
}

// TextField for writing the thank you note in reviewResponses.js during the unwrap process
export const thankYouNoteTextField = (name, value, placeholder, changeHandler) => {
  return (
    <TextField
      placeholder={placeholder}
      variant='outlined'
      autoComplete='off'
      name={name}
      value={value}
      multiline
      minRows={3}
      maxRows={5}
      size='small'
      onChange={(e) => changeHandler(e)}
      sx={{
        width: '100%',
        maxWidth: '600px',
      }} 
    />
  )
}

// TextField for editing bio in EditProfile.js
export const editBioTextField = (name, label, placeholder, value, changeHandler, setErrors, setFormData, formData) => {
  return (
    <TextField
      id={name}
      label={label}
      placeholder={placeholder}
      variant='outlined'
      autoComplete='off'
      name={name}
      inputProps={{ maxLength: 150 }}
      value={value}
      required
      multiline
      minRows={1}
      maxRows={3}
      onChange={(e) => changeHandler(e, setErrors, setFormData, formData)}
      sx={{ width: '90%' }}
    />
  )
}