/* eslint-disable react/no-unescaped-entities */

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { socialMediaButtons } from '../../../helpers/buttons'
import { standardButton } from '../../../helpers/buttons'
import { userIsAuthenticated } from '../../../helpers/storage'

import { getUsernameFromLocalStorage } from '../../../helpers/storage'

export const responsesSent = (navigate, width, continueAsGuest) => {

  // Navigate to /sendgift when 'Send Gift' button pressed
  const handleSendGift = () => {
    console.log('handleSendGift runs')
    navigate(`/sendgift`)
  }

  // Navigate to home when 'Visit 3D Wrap' button pressed
  const handleVisit3dwrap = () => {
    console.log('handleVisit3dWrap runs')
    navigate(`/`)
  }

  // Navigate to /profile/username when 'Visit Profile' button pressed
  const handleVisitProfile = () => {
    console.log('handleVisitProfile runs')
    const username = getUsernameFromLocalStorage()
    navigate(`/profile/${username}`)
  }

  const responsesSentBoxAndButton = (buttonText, color, clickResponse, topPadding) => {
    return (
      <Box sx={{ pb: 1, pt: topPadding }}>
        {standardButton(
          buttonText,
          'button',
          'contained',
          false,
          color,
          0,
          0,
          0,
          width >= 350 ? '250px' : '175px',
          '55px',
          clickResponse
        )}
      </Box>
    )
  }

  return (        
    <Box 
      sx={{ 
        width: '100%', height: '100%',
        pt: 4,
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', 
      }}
    >

      {/* Visit 3D Wrap Button */}
      {responsesSentBoxAndButton('3dwrap.com', 'primary', handleVisit3dwrap, 0)}

      {/* Send Button */}
      {responsesSentBoxAndButton('Send Gift', 'secondary', handleSendGift, 1)}

      {/* Visit Profile */}
      {userIsAuthenticated() && 
        responsesSentBoxAndButton('View Profile', 'primary', handleVisitProfile, 1)
      }

      {/* Useful Text */}
      {userIsAuthenticated() &&
        <Typography alignContent={'center'} variant='p' sx={{
          width: width >= 350 ? '250px' : '175px',
        }}>
          *View your gift in the "Received Gifts" tab on your profile page
        </Typography>
      }

      {/* Social Media Buttons */}
      <Box 
        sx={{ 
          pt: 4,
          width: '100%', 
          display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', 
        }}
      >
        {socialMediaButtons('row', 'center', 'center', 0, false)}
      </Box>
      
    </Box>
  )
}