/* eslint-disable no-prototype-builtins */

// Convert angles to radians
export const angleToRadians = (angleInDeg) => (Math.PI / 180) * angleInDeg

// Make sure the animation only plays once and does not reset to the beginning when finished
export const editAnimation = (actions) => {
  Object.keys(actions).forEach(function(key, index) {
    if (actions[key].hasOwnProperty('repetitions')) {
      actions[key].repetitions = 1 //Only play animation once
      actions[key].clampWhenFinished = true //Do NOT reset animation to beginning when finished
    }
  })
}

// If paused then unpause, else play
export const unpauseOrPlay = (action) => {
  if (action.paused) {
    action.paused = false
  } else {
    action.play()
  }
}

// Stop, then play and immediately pause to reset the animation to its first frame
export const stopPlayPause = (action) => {
  action.stop().play() // Stop, then play
  action.paused = true // Immediately pause animation on first frame
}
