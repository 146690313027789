/* eslint-disable no-prototype-builtins */
import React from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import FacebookLogin from 'react-facebook-login'

import { handleChange } from './globalHelpers'
import { setTokenToLocalStorage, setProfPicToLocalStorage, setUsernameToLocalStorage, setEmailToLocalStorage } from './storage'
import { loginTextField } from './textfields'
import { standardSubmitButton, handleSwitchLoginRegisterButton, standardButton } from './buttons'
import { loginRegisterErrors, standardErrorContainer } from './errors'
import { profPicDefaultURL } from './variableDefaults'
import { standardSpinner } from './spinners'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'


// Proceed to next view, depending on the fromComponent
export const proceedToNextView = (fromComponent, setSendViewIndex, setAuthenticated, navigate) => {

  if (fromComponent === 'allAuth') {
    navigate('/')
  } else if (fromComponent === 'wrap') {
    setSendViewIndex(0)
  } else if (fromComponent === 'unwrap') {
    setAuthenticated(true)
  }
}

// Handle Continue as Guest
export const handleContinueAsGuest = (e, fromComponent, setSendViewIndex, setAuthenticated, setContinueAsGuest, navigate) => {
  e.preventDefault()

  // Set Continue as Guest to true
  setContinueAsGuest(true)

  // Depending on the component the login occurs in, go to the appropriate next view
  proceedToNextView(fromComponent, setSendViewIndex, setAuthenticated, navigate)
}

// Reset all auth errors and Loadings
export const resetAllAuthErrors = (setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister) => {
  // Reset error states
  setGoogleLoginErrors(false)
  setFacebookLoginErrors(false)
  setLoginErrors(false)
  setRegisterErrors({})

  // Reset Loading states
  setLoadingGoogle(false)
  setLoadingFacebook(false)
  setLoadingLogin(false)
  setLoadingRegister(false)
}

// Switches between login and register forms
const handleSwitchLoginRegister = async (e, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister) => {
  e.preventDefault()

  // Toggle between login and register view indexes
  if (fromComponent === 'allAuth' || fromComponent === 'wrap' || fromComponent === 'unwrap') {
    setLoginViewIndex(loginViewIndex === 1 ? 2 : 1)
  }

  // reset all error states
  resetAllAuthErrors(setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)
}

// Code inside 'try' part of try/catch when attempting a login
const loginTry = async (fromComponent, setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated) => {
  
  // Set Loading
  setLoading(true)

  // Upload Login/Register Form to server
  // const { data } = await axios.post('/api/auth/', formToUpload)
  const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/`, formToUpload)


  // Set the token, username, email, and profile picture to local storage
  setTokenToLocalStorage(data.token)
  setUsernameToLocalStorage(data.username)
  setEmailToLocalStorage(data.email)
  setProfPicToLocalStorage(data.profilePicture)

  // Depending on the component the login occurs in, go to the appropriate next view
  proceedToNextView(fromComponent, setSendViewIndex, setAuthenticated, navigate)

  // Reset view
  setLoginViewIndex(0)

  // Set loading and login errors to false
  setLoading(false)
  setErrors(false)
}

// Code inside 'catch' part of try/catch when attempting to login
const loginCatch = async (err, setLoading, setErrors) => {
  console.log('err: ', err)
  setLoading(false)
  setErrors(true)
}

// Code inside 'catch' part of try/catch when attempting to register
// Necessary because we want Register error messages to be more specific than login error messages
const registerCatch = async (err, setLoading, errors, setErrors) => {
  // console.log('err ->', err.response)
  const errorObj = err.response.data.errors
  const messageToReturn = errorObj[Object.keys(errorObj)[0]].message.includes('Value:') ? errorObj[Object.keys(errorObj)[0]].message.split(' Value:')[0] : errorObj[Object.keys(errorObj)[0]].message

  // console.log('error message ->', errorObj[Object.keys(errorObj)[0]].message)
  // console.log('message to return ->', messageToReturn)

  setLoading(false)
  setErrors({ ...errors, [Object.keys(errorObj)[0]]: messageToReturn })
}

// The try/catch when attempting to log in
const loginTryCatch = async (fromComponent, setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated) => {
  try {
    await loginTry(fromComponent, setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)

  } catch (err) {
    await loginCatch(err, setLoading, setErrors)
  }
}

// Google Sign In Button and Callback
export const createGoogleSignInButton = (fromComponent, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated) => {
  
  // the 'global google' below is necessary, don't change or delete it 
  /* global google */
  // If the global google variable exists, create the google login button
  if (typeof google !== 'undefined' && google) {
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: async function (response) {

        // reset all error states
        resetAllAuthErrors(setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)
        
        // Decode the JSON credential into an object
        const userObject = jwtDecode(response.credential)

        
        // Increasing size of profile picture
        let profilePicture
        if (userObject.hasOwnProperty('picture') && userObject.picture.length > 0) {
          if (userObject.picture.includes('s96-c')) {
            profilePicture = userObject.picture.replace('s96-c', 's300-c')
          } else if (userObject.picture.includes('?sz=')) {
            profilePicture = userObject.picture.substring(0, userObject.picture.indexOf('?')) + '?sz=300'
          }
        } else {
          // If there is no google profile pic, set it to the 3D Wrap default
          profilePicture = profPicDefaultURL
        }

        // Create the object to upload
        const googleFormToUpload = {
          email: userObject.email,
          password: userObject.sub ? `3dwrap${userObject.sub}` : '3dwrap4$$w0rd!', // .sub is the user's Google user id
          passwordConfirmation: userObject.sub ? `3dwrap${userObject.sub}` : '3dwrap4$$w0rd!',
          profilePicture: profilePicture,
          name: userObject.name ? userObject.name : '',
          givenName: userObject.given_name ? userObject.given_name.toLowerCase() : '',
          familyName: userObject.family_name ? userObject.family_name.toLowerCase() : '',
          loginMethod: 'google',
          acceptedTOS: true,
        }

        // If none of the required form fields are empty, POST the form data to the register endpoint and then navigate to /login
        if (
          googleFormToUpload.email.length > 0 
          && googleFormToUpload.password.length > 0  
          && googleFormToUpload.passwordConfirmation.length > 0  
          && googleFormToUpload.profilePicture.length > 0  
        ) {
          // Upload form to server to execute Login/Register
          await loginTryCatch(fromComponent, setLoadingGoogle, setGoogleLoginErrors, googleFormToUpload, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)

        } else {
          console.log('register form not complete')

          // If there's an error, pass this message
          setLoadingGoogle(false)
          setGoogleLoginErrors(true)
        }
      },
    })

    // Display the Google Sign-In Button
    google.accounts.id.renderButton(
      document.getElementById('signInDiv'),
      { theme: 'outlined', size: 'large', width: 225 }
    )
  }
}

// Facebook Callback Response
const handleFacebookCallbackResponse = async (response, fromComponent, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated) => {
  
  // reset all error states
  resetAllAuthErrors(setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)
  
  if (response.accessToken) {

    // Get first and last name from full name
    let givenName = ''
    let familyName = ''
    if (response.name.includes(' ')) {
      givenName = response.name.substring(0, response.name.indexOf(' '))
      familyName = response.name.split(' ').pop()
    } else {
      givenName = response.name
    }

    // Create the object to upload
    const facebookFormToUpload = {
      email: response.email,
      password: `3dwrap${response.id}`,
      passwordConfirmation: `3dwrap${response.id}`,
      profilePicture: (response.hasOwnProperty('picture') && response.picture.hasOwnProperty('data') && response.picture.data.hasOwnProperty('url') && response.picture.data.url.length > 0) ? response.picture.data.url : profPicDefaultURL,
      name: response.name,
      givenName: givenName.toLowerCase(),
      familyName: familyName.toLowerCase(),
      loginMethod: 'facebook',
      acceptedTOS: true,
    }

    // If none of the form fields are empty, POST the form data to the register endpoint and then navigate to /login
    if (facebookFormToUpload.email 
      && facebookFormToUpload.password 
      && facebookFormToUpload.passwordConfirmation 
      && facebookFormToUpload.profilePicture
    ) {
      // Upload form to server to execute login/register
      await loginTryCatch(fromComponent, setLoadingFacebook, setFacebookLoginErrors, facebookFormToUpload, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)
    } else {
      console.log('register form not complete')

      // If there's an error, pass this message
      setLoadingFacebook(false)
      setFacebookLoginErrors(true)
    }

  } else {
    console.log('facebook login failed')
    setLoadingFacebook(false)
    setFacebookLoginErrors(true)
  }
}

// Email/Password login attempt submitted
const handleSubmitLogin = async (e, fromComponent, loginForm, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated) => {
  e.preventDefault()

  // reset all error states
  resetAllAuthErrors(setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)

  if (loginForm.email && loginForm.password) {

    // Upload login form to server to execute login
    await loginTryCatch(fromComponent, setLoadingLogin, setLoginErrors, loginForm, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)

  } else {
    console.log('login form not complete')

    // Set errors to true if the email or password is missing
    setLoadingLogin(false)
    setLoginErrors(true)
  }
}

// Email/Password register attempt submitted
const handleSubmitRegister = async (e, fromComponent, registerForm, registerErrors, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated) => {
  e.preventDefault()

  // reset all error states
  resetAllAuthErrors(setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)

  // If none of the form fields are empty, POST the form data to the register endpoint and then navigate to /login
  if (registerForm.username && registerForm.email && registerForm.password && registerForm.passwordConfirmation && (registerForm.password === registerForm.passwordConfirmation)) {
    try {

      // upload register form to server to execute login/register
      await loginTry(fromComponent, setLoadingRegister, setRegisterErrors, registerForm, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)

    } catch (err) {
      console.log('register error ->', err)

      // Catch Register errors if there are any
      await registerCatch(err, setLoadingRegister, registerErrors, setRegisterErrors)

    }
  } else if (registerForm.password !== registerForm.passwordConfirmation) {
    console.log('password does not match password confirmation')

    // If there's an error, pass this message
    setLoadingRegister(false)
    setRegisterErrors({ ...registerErrors, passwordConfirmation: 'password and password confirmation do not match' })
  } else {
    console.log('register form not complete')

    // If there's an error, pass this message
    setLoadingRegister(false)
    setRegisterErrors({ ...registerErrors, passwordConfirmation: 'All fields are required' })
  }
}

// Email/Password Login Elements
export const inProcessLogin = (fromComponent, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingRegister, navigate, setSendViewIndex, setAuthenticated) => {

  return (
    <Box sx={{ 
      width: '85%',
      maxWidth: '400px',
      mt: 2, mb: 2,
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    }}>
      {
        loadingLogin ?
          standardSpinner()
          :
          <>
            {/* Login Form */}
            <Box component="form" 
              onSubmit={(e) => handleSubmitLogin(e, fromComponent, loginForm, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)} 
              noValidate 
              sx={{ 
                mt: 1, mb: 1,
              }}
            >
              {/* Grid Necessary for standardized spacing */}
              <Grid container spacing={2}>
              
                {/* Email */}
                <Grid item xs={12}>
                  {loginTextField('email', 'Email Address', loginForm.email, 'outlined', true, true, true, 'email', loginForm, setLoginForm, setLoginErrors, handleChange )}
                </Grid>
                
                {/* Password */}
                <Grid item xs={12}>
                  {loginTextField('password', 'Password', loginForm.password, 'outlined', true, true, false, 'current-password', loginForm, setLoginForm, setLoginErrors, handleChange )}
                </Grid>

              </Grid>
              
              {/* Errors if there are errors */}
              {loginRegisterErrors(loginErrors, 'Unauthorised.', 1, 0)}

              {/* Submit button */}
              <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12}>
                  {standardSubmitButton('Sign In', 'submit', false, 'primary', 2, 2, '150px', '50px')}
                </Grid>
              </Grid>

              {/* Link to Register page for people without accounts */}
              <Grid container>
                <Grid item xs={12} >
                  {handleSwitchLoginRegisterButton('No account yet? Sign Up', handleSwitchLoginRegister, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)}
                </Grid>

                {/* Link to Reset Password */}
                {fromComponent === 'allAuth' && loginViewIndex === 1 &&
                  <Grid item xs={12} >
                    <Button 
                      onClick={(e) => navigate('/reset-password')} 
                      variant="text" 
                      color='primary'
                      sx={{ 
                        mt: 0,
                        width: '225px', 
                        height: '40px', 
                        display: 'flex', flexDirection: 'row', justifyContent: 'left',
                      }}
                    >
                      Reset Password
                    </Button>
                  </Grid>
                }
                
                
              </Grid>
            </Box>
          </>
      }
    </Box>
  )
}

// Email/Password Register Elements
export const inProcessRegister = (fromComponent, registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, navigate, setSendViewIndex, setAuthenticated) => {
  return (
    <Box sx={{ 
      width: '85%',
      maxWidth: '400px',
      mt: 2, mb: 2,
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    }}>
      {
        loadingRegister ?
          standardSpinner()
          :
          <>
            {/* Register Form */}
            <Box component="form" noValidate 
              onSubmit={(e) => handleSubmitRegister(e, fromComponent, registerForm, registerErrors, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)} 
              sx={{ 
                mt: 3, 
              }}
            >
              {/* Grid necessary for standardized spacing */}
              <Grid container spacing={2}>
                
                {/* Username */}
                <Grid item xs={12}>
                  {loginTextField('username', 'Username', registerForm.username, 'outlined', true, true, true, 'username', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under username if it's a username error */}
                {loginRegisterErrors(registerErrors.username, registerErrors.username, 0, 1)}

                {/* Email */}
                <Grid item xs={12}>
                  {loginTextField('email', 'Email Address', registerForm.email, 'outlined', true, true, false, 'email', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under email if it's an email error */}
                {loginRegisterErrors(registerErrors.email, registerErrors.email, 0, 1)}

                {/* Password */}
                <Grid item xs={12}>
                  {loginTextField('password', 'Password', registerForm.password, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under password if it's a password error */}
                {loginRegisterErrors(registerErrors.password, registerErrors.password, 0, 1)}

                {/* Password Confirmation */}
                <Grid item xs={12}>
                  {loginTextField('passwordConfirmation', 'Password Confirmation', registerForm.passwordConfirmation, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under password confirmation if it's a password confirmation error */}
                {loginRegisterErrors(registerErrors.passwordConfirmation, registerErrors.passwordConfirmation, 0, 1)}

              </Grid>
              
              {/* Terms of Service and Privacy Policy Checkbox */}
              <Grid item xs={12} 
                sx={{ 
                  fontSize: '13px',
                  pl: 1, pr: 1, pt: 1,
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap',
                  overflow: 'visible',
                }}
              >
                <FormControlLabel 
                  control={
                    <Checkbox 
                      disabled={false}
                      value={acceptedTOS} 
                      name={'acceptedTOS'}
                      checked={acceptedTOS}
                      onClick={(e) => setAcceptedTOS(!acceptedTOS)}
                      sx={{ pl: 2, width: '40px' }} />
                  } 
                />
                <Typography variant='p' sx={{ ml: 0, mr: 0, width: 'calc(100% - 60px)' }}>
                  Accept <a href='/tos' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: 'mediumorchid' }} >Terms of Service</a> and <a href='/privacy' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: 'mediumorchid' }} >Privacy Policy</a>.
                </Typography>
              </Grid>

              {/* Register Button */}
              <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12}>
                  {standardSubmitButton('Register', 'submit', !acceptedTOS, 'primary', 2, 2, '150px', '50px')}
                </Grid>
              </Grid>

              {/* Prompt */}
              <Grid container>
                <Grid item xs={12}>
                  {handleSwitchLoginRegisterButton('Already have an account? Log in', handleSwitchLoginRegister, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)}
                </Grid>
              </Grid>
            </Box>
          </>
      }
    </Box>
  )
}

// All Auth Elements: Google Sign In Button, Facebook Sign In Button, Email/Password Login and Register Options
export const inProcessAllAuth = (fromComponent, loginViewIndex, googleLoginErrors, facebookLoginErrors, setGoogleLoginErrors, setFacebookLoginErrors, setLoadingGoogle, setLoadingFacebook, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated, setContinueAsGuest ) => {

  return (
    <>
      {/* Advisory */}
      {fromComponent === 'unwrap' &&
        <Typography 
          sx={{
            color: 'purple',
          }}
        >
          <em>Log in to view gift</em>
        </Typography>
      }
      {fromComponent === 'wrap' &&
        <Typography 
          sx={{
            color: 'purple',
          }}
        >
          <em>Log in to send gift</em>
        </Typography>
      }

      {/* Google */}

      {/* Google Login Button — if the global google variable exists */}
      {typeof google !== 'undefined' && google &&
        <Box
          sx={{
            backgroundColor: 'white',
            mt: 2, mb: 2,
            borderRadius: '3px',
            width: '225px',
            minWidth: '225px',
            maxWidth: '225px',
            height: '40px',
            minHeight: '40px',
            maxHeight: '40px',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Box id="signInDiv"
            className="g-signin2" data-width="300" data-height="200"
            sx={{ 
              border: 0,
              boxShadow: 2,
            }}
          >
          </Box>

        </Box>
      }

      {/* Google Login Errors */}
      {googleLoginErrors && 
        standardErrorContainer(
          'Error logging in with Google.', 
          2,
          0
        )
      }
      


      {/* Facebook */}

      {/* Facebook Login Button */}
      <Box
        sx={{
          mt: 1,
          boxShadow: 2,
          width: '225px', 
          // maxHeight: '40px',
          display: 'flex', flexDirection: 'row', justifyContent: 'center',
        }}
      >
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          disableMobileRedirect={true}
          fields="name,email,picture.type(large)"
          size='small'
          cssClass="my-facebook-button-class"
          callback={(response) => handleFacebookCallbackResponse(response, fromComponent, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)} 
        />
      </Box>

      {/* Facebook Login Errors */}
      {facebookLoginErrors &&
        standardErrorContainer(
          'Error logging in with Facebook.', 
          0,
          1
        )
      }

      {/* Email + Password */}
      {loginViewIndex === 0 &&
        <Button 
          onClick={(e) => handleSwitchLoginRegister(e, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)} 
          variant="contained" 
          sx={{ 
            mt: 3,
            boxShadow: 2,
            color: 'white',
            backgroundColor: 'plum',
            width: '225px', 
            height: '40px', 
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
            ':hover': {
              backgroundColor: 'white',
              color: 'plum',
            },
          }}
        >
          Email + Password
        </Button>
      }

      {/* Login Form */}
      {loginViewIndex === 1 && 
        inProcessLogin(fromComponent, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingRegister, navigate, setSendViewIndex, setAuthenticated)
      }

      {/* Register Form */}
      {loginViewIndex === 2 &&
        inProcessRegister(fromComponent, registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, navigate, setSendViewIndex, setAuthenticated)
      }

      {/* Continue as Guest */}
      {fromComponent !== 'allAuth' && 
        <>
          {/* Divider */}
          <Divider 
            sx={{
              mt: 3,
              width: '275px',
              color: 'purple',
              '&::before, &::after': {
                borderColor: 'mediumorchid',
              },
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
            }}
          >
            Or
          </Divider>

          {/* Continue as Guest Button */}
          <Button 
            type={'button'}
            fullWidth
            variant={'contained'}
            color={'tertiary'}
            onClick={(e) => handleContinueAsGuest(e, fromComponent, setSendViewIndex, setAuthenticated, setContinueAsGuest, navigate)} 
            sx={{ 
              mt: 3, 
              width: '225px',
              height: '40px',
            }}
          >
            Continue as Guest
          </Button>
        </>
      
      }
    </>
  )
}