import { Html } from '@react-three/drei'

// Indicates when 3d elements are loading — only works when model-element is 'lazy'-loaded
const Loader = () => {
  return (
    <Html center>
      <p style={{ color: 'purple' }}>
        Loading...
      </p>
    </Html>
  )
}

export default Loader