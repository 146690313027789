// React
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

// MUI
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import SendIcon from '@mui/icons-material/Send'
import Person2Icon from '@mui/icons-material/Person2'


// Import logo image — purple, transparent background
import logo3dWrapP from '../../assets/logo-image-trans-p-correct.png'

// Default profile pic — purple, white background
import profPicDefault from '../../assets/social-media-logo.png'

// Helper methods
import { userIsAuthenticated, getProfPicFromLocalStorage, getUsernameFromLocalStorage } from '../../helpers/storage'
import { navbarHeight, navbarBgDefaultColor, navMenuOptionsLoggedIn, navMenuOptionsNotLoggedIn, profPicDefaultURL } from '../../helpers/variableDefaults'


// The navbar appears at the top of the website on all pages except for unwrap views
const PageNavbar  = () => {

  // Navigate
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Keeps track of whether the menu should be open or closed
  const [anchorElUser, setAnchorElUser] = useState(null)

  //Navigate to different pages depending on which menu item is clicked
  const handleNavClick = (event) => {

    // converting page name to lower case and removing spaces
    const pageName = event.currentTarget.innerText.toLowerCase().replace(/ /g,'')

    // It's important to use .includes instead of '===' because '===' doesn't work on mobile for some reason
    if (
      pageName.includes('login') 
      || pageName.includes('faq') 
      || pageName.includes('giftideas') 
      || pageName.includes('sendgift') 
      || pageName.includes('example') 
      // || pageName.includes('findfriends')
    ) {
      handleCloseUserMenu()

      //If login, faq, giftideas, or findfriends, navigate to those pages
      navigate(`/${pageName}`)

    } else if (pageName.includes('profile')) {
      handleCloseUserMenu()

      //If profile, navigate to the user's profile
      navigate(`/profile/${getUsernameFromLocalStorage()}`, { replace: true })
      
    } else if (pageName.includes('logout')) {
      handleCloseUserMenu()

      //Remove token, profpic, username, email, and currentProfile from local storage upon logout
      window.localStorage.removeItem('3dwrap')
      window.localStorage.removeItem('profPic')
      window.localStorage.removeItem('username')
      window.localStorage.removeItem('email')
      window.localStorage.removeItem('currentProfile')

      //Navigate to the login screen upon logout
      navigate('/login')

    } else {
      console.log('pagename not found')
    }
  }

  // Opens the user menu on the right hand side
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  // Closes the user menu on the right hand side
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  // Send Gift IconButton Clicked — navigate to /sendgift
  const handleSend = async (e) => {
    e.preventDefault()
    
    navigate('/sendgift')
  }

  // Click Profile IconButton Clicked — navigate to /login or profile
  const handleClickProfile = async (e) => {
    e.preventDefault()
    
    if (userIsAuthenticated()) {
      navigate(`/profile/${getUsernameFromLocalStorage()}`, { replace: true })
    } else {
      navigate('/login')
    }
  }

  return (
    <>
      {!urlPath.includes('unwrap') && // Appbar appears on all views except when a gift is being unwrapped
        <AppBar position="static" component="nav"
          style={{ 
            background: navbarBgDefaultColor,
            boxShadow: 'none', 
          }}
        >
          <Box>
            <Toolbar 
              sx={{ 
                width: '100%',
                height: navbarHeight,
                // backgroundColor: 'orange',
                fontFamily: 'Lato',
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', 
              }}
            >
              
              {/* Logo */}
              <Box sx={{ ml: 1, display: 'flex', alignItems: 'flex-end' }}>

                {/* Logo image */}
                <Box as={Link} to="/" sx={{ width: 45 }}>
                  <Box component='img' src={logo3dWrapP} alt="Logo" />
                </Box>

              </Box>

              {/* Send Gift Icon Button */}
              <Box sx={{ justifyContent: 'end', alignItems: 'center', display: 'flex', mr: 0 }}>
                
                {/* Send Button */}
                <Box>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    onClick={handleSend}
                    color="inherit"
                  >
                    <SendIcon color='primary' />
                  </IconButton>
                </Box>

                {/* Profile Button */}
                {!userIsAuthenticated() &&
                  <Box>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-haspopup="true"
                      onClick={handleClickProfile}
                      color="inherit"
                    >
                      <Person2Icon color='primary' />
                    </IconButton>
                  </Box>
                }
                {/* Menu Options */}
                {userIsAuthenticated() &&
                  <Box sx={{ flexGrow: 0 }}>

                    {/* Profile image */}
                    <Tooltip title="Open menu">
                      <IconButton 
                        onClick={handleOpenUserMenu} // Menu opens on click
                        onMouseOver={handleOpenUserMenu} //Menu opens on hover
                        aria-controls="menu-appbar"
                        aria-owns="menu-appbar"
                        sx={{ 
                          p: 0, ml: 2, mr: 3, 
                          boxShadow: (userIsAuthenticated() && getProfPicFromLocalStorage() && getProfPicFromLocalStorage() !== profPicDefaultURL ) ? 2 : 0, 
                        }}
                      >
                        {(userIsAuthenticated() && getProfPicFromLocalStorage() && getProfPicFromLocalStorage() !== profPicDefaultURL ) ?
                          <Avatar alt="3dwrap profile pic" src={getProfPicFromLocalStorage() ? getProfPicFromLocalStorage() : profPicDefault} />
                          :
                          <MenuIcon sx={{ color: 'purple' }}/>
                        }
                        
                      </IconButton>
                    </Tooltip>

                    {/* The Menu */}
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      MenuListProps={{ onMouseLeave: handleCloseUserMenu }} //Menu closes when mouse no longer hover over it
                      onClose={handleCloseUserMenu}
                    >
                      {/* If user is logged in, shows logged in menu, otherwise it shows logged out menu on right side of navbar */}
                      {(userIsAuthenticated() ? navMenuOptionsLoggedIn : navMenuOptionsNotLoggedIn).map((page, i) => (
                        <MenuItem key={i} onClick={handleNavClick}>
                          <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                }
              </Box>
              
              
            </Toolbar>
          </Box>
        </AppBar>
      }
    </>
  )
}

export default PageNavbar