import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { colorOptionsElement, wrapCanvasElement } from '../../../helpers/wrapHelpers'
import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { dummyBox, removeColorStyling } from '../../../helpers/globalHelpers'
import { cardTextTextField } from '../../../helpers/textfields'
import { selectElement } from '../../../helpers/selects'
import { positionChangeWidthMd, ministepsHeight, salutations, cardMessages, signOffs, cardTextColors } from '../../../helpers/variableDefaults'

import useWindowDimensions from '../../../helpers/windowDimensions'

const CardText = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    cardText, setCardText, backArrow, forwardArrow,
    wrapping, ribbon, envelope, boxType, environment,
    miniStepsPackage, activeBottomStep,
  } = props
  
  // Update view when cardText changes
  useEffect(() => {

  }, [cardText])

  // Execute cardText selections
  const handleSetCardText = async (e) => {
    e.preventDefault()

    // Create name and value variables
    let name
    let value

    // Assign values to name and value depending on the type of event target
    if (!e.target.name) {
      name = e.target.classList[1]
      value = e.target.classList[0].slice(5)
    } else {
      name = e.target.name
      value = e.target.value
    }

    // Set card text, and, if name is 'color', remove color styling
    if (name === 'color') {

      // Remove styling from non-selected colors, and add styling to newly selected color if different from previous selection
      removeColorStyling(cardTextColors, value, 'text', cardText.color, e)
  
      // Set cardText
      setCardText({ ...cardText, [name]: `#${value}` })

    } else {
      setCardText({ ...cardText, [name]: value })
    }

  }

  // TextFields surrounded by a Grid that's specific to this view
  const textfieldElement = (element, elementName, placeholder, value, bottomMargin = 0) => {
    return (
      <Grid item xs={8} md={10} sx={{ mt: 0, mb: bottomMargin }}>
        {cardTextTextField(element, elementName, placeholder, value, handleSetCardText)}
      </Grid>
    )
  }

  return (
    <>
      <Grid container spacing={0} 
        sx={{ 
          height: '100%',
        }}
      >
        {/* Wrap Present Canvas */}
        {wrapCanvasElement(width, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep)}

        {/* Options */}
        <Grid item xs={12} md={6} 
          sx={{ 
            boxShadow: 0, 
            height: width < positionChangeWidthMd ? '60%' : '100%', maxHeight: width < positionChangeWidthMd ? '60%' : '100%',
            width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
          }}
        >
          {/* Title and Options Container */}
          <Box 
            sx={{ 
              height: `calc(100% - ${ministepsHeight})`,
              maxHeight: `calc(100% - ${ministepsHeight})`,
              // overflow: 'auto',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                // -webkit-appearance: none,
                WebkitAppearance: 'none',
                width: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, .35)',
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
              },
              width: '100%', 
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', 
            }}
          >

            {/* Dummy Box to get spacing right */}
            {dummyBox('0px')}

            {/* Title and Options */}
            <Box 
              sx={{
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {/* Title */}
              <Typography sx={{ mb: 2, mt: 2 }}>
                <strong>Card</strong>
              </Typography>

              {/* Card Options  */}
              <Grid container spacing={1} sx={{ 
                // pb: 2,
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap',
              }}>
                {/* Salutation */}
                {selectElement('salutation', cardText.salutation, salutations, handleSetCardText)}
                
                {/* Recipient Name */}
                {textfieldElement('recipient', 'recipientName', 'Recipient Name*', cardText.recipientName, 0)}
                
                {/* Message */}
                {selectElement('message', cardText.message, cardMessages, handleSetCardText)}
                
                {/* Sign Off */}
                {selectElement('signOff', cardText.signOff, signOffs, handleSetCardText)}

                {/* Sender Name */}
                {textfieldElement('sender', 'senderName', 'Sender Name*', cardText.senderName, 2)}
                
              </Grid>

              {/* Color Options */}
              {colorOptionsElement('wrap', cardTextColors, cardText.color, handleSetCardText)}
              
            </Box>

            {/* Dummy Box to get spacing right */}
            {dummyBox('0px')}

          </Box>
          
          {/* Mobile stepper and, if the screen is small, arrows */}
          {packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, true)}
          
        </Grid>
      </Grid>  
    </>
  )
}

export default CardText