import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import neatUnwrapLarge320 from '../../../assets/neatUnwrapLarge320.gif'
import messyUnwrapLarge320 from '../../../assets/messyUnwrapLarge320.gif'
import swipewrapCloneLarge320 from '../../../assets/swipewrapCloneLarge320.gif'

import { colorOptionsElement, wrapCanvasElement, wrappingOptionElement } from '../../../helpers/wrapHelpers'
import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { dummyBox, removeStyling, removeColorStyling } from '../../../helpers/globalHelpers'
import { positionChangeWidthMd, ministepsHeight, wrapColors, ribbonColors, envelopeColors } from '../../../helpers/variableDefaults'

import useWindowDimensions from '../../../helpers/windowDimensions'

const Wrapping = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    wrapping, setWrapping, backArrow, forwardArrow,
    boxType, ribbon, envelope, cardText, environment,
    setRibbon, setEnvelope, setEnvironment, setCardText,
    miniStepsPackage, activeBottomStep,
  } = props
  
  // Update view when wrapping selection changes
  useEffect(() => {

  }, [wrapping])

  // Handle Wrapping Selection
  const handleSetWrapping = async (e) => {
    e.preventDefault()
    
    // Get name from event target classlist
    const name = e.target.classList[1]

    // Update the wrapping object depending on which input was selected
    if (name === 'type') {

      // Get the value from the event target classList
      const value = e.target.classList[0]

      // Remove the styling from the options that were not selected
      if (value === 'neat') {
        removeStyling('.messy')
        removeStyling('.swipe')
      } else if (value === 'messy') {
        removeStyling('.neat')
        removeStyling('.swipe')
      } else if (value === 'swipe') {
        removeStyling('.neat')
        removeStyling('.messy')
      }

      // toggle the 'styled' class for the event target if the selected wrapping type is different
      if (value !== wrapping.type) {
        e.target.classList.toggle('styled')
      }

      // Set Wrapping
      setWrapping({ ...wrapping, [name]: value })

    } else if (name === 'color') {

      // Get the value from the event target classList
      const value = e.target.classList[0].slice(5)
      
      // Remove styling from non-selected wrapping colors, and add styling to newly selected color if different from previous selection
      removeColorStyling(wrapColors, value, 'wrap', wrapping.color, e)

      // Set Wrapping
      setWrapping({ ...wrapping, [name]: `#${value}` })

      // Reset Ribbon and Envelope defaults if wrapping color is same
      let ribbonColor
      let envelopeColor
      if (ribbon === `#${value}`) {
        const editedRibbonColors = ribbonColors.filter(color => (color !== `#${value}` && color !== envelope))
        ribbonColor = editedRibbonColors[Math.floor(Math.random() * editedRibbonColors.length)]
        setRibbon(ribbonColor)

        // Set Confetti Color default to same as ribbon color
        setEnvironment({ ...environment, confettiColor: ribbonColor })
      }
      if (envelope === `#${value}`) {
        const editedEnvelopeColors = envelopeColors.filter(color => (color !== `#${value}` && color !== ribbonColor))
        envelopeColor = editedEnvelopeColors[Math.floor(Math.random() * editedEnvelopeColors.length)]
        setEnvelope(envelopeColor)
        
        // Set the card text color default to the same as the envelope color
        setCardText({ ...cardText, color: envelopeColor })
      }
    }
  }

  return (
    <>
      <Grid container spacing={0} 
        sx={{ 
          height: '100%', 
        }}
      >

        {/* Wrap Present Canvas */}
        {wrapCanvasElement(width, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep)}

        {/* Wrapping Choices */}
        <Grid item xs={12} md={6}  
          sx={{ 
            boxShadow: 0, 
            height: width < positionChangeWidthMd ? '60%' : '100%', maxHeight: width < positionChangeWidthMd ? '60%' : '100%',
            width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', alignItems: 'space-between', justifyContent: 'space-between', 
          }}
        >

          <Box 
            sx={{ 
              height: `calc(100% - ${ministepsHeight})`,
              maxHeight: `calc(100% - ${ministepsHeight})`,
              // overflow: 'auto',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                // -webkit-appearance: none,
                WebkitAppearance: 'none',
                width: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, .35)',
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
              },
              width: '100%', 
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', 
            }}
          >
            {/* Dummy Box to get spacing right */}
            {dummyBox('0px')}

            {/* Title and Options Container */}
            <Box 
              sx={{
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {/* Title */}
              <Typography sx={{ mb: 2, mt: 2 }}>
                <strong>Wrapping</strong>
              </Typography>

              {/* Wrap Types */}
              <Box 
                sx={{ 
                  width: '100%', 
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', 
                }}
              >
                {/* Neat */}
                {wrappingOptionElement(
                  'neat', 
                  (wrapping && wrapping.type === 'neat' ? 'neat type styled' : 'neat type'), 
                  handleSetWrapping, 
                  neatUnwrapLarge320
                )}

                {/* Messy */}
                {wrappingOptionElement(
                  'messy', 
                  (wrapping && wrapping.type === 'messy' ? 'messy type styled' : 'messy type'), 
                  handleSetWrapping, 
                  messyUnwrapLarge320
                )}

                {/* Swipe */}
                {wrappingOptionElement(
                  'swipe', 
                  (wrapping && wrapping.type === 'swipe' ? 'swipe type styled' : 'swipe type'), 
                  handleSetWrapping, 
                  swipewrapCloneLarge320
                )}
              </Box>

              {/* Colors */}
              {colorOptionsElement('wrap', wrapColors, wrapping.color, handleSetWrapping)}
              
            </Box>

            {/* Dummy Box to get spacing right */}
            {dummyBox('0px')}

          </Box>
          
          {/* Mobile Stepper and arrows */}
          {packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, true)}
          
        </Grid>
      </Grid>  
    </>
  )
}

export default Wrapping