import { profPicDefaultURL, navbarHeight, confettiColorDefault, canvasBgDefaultColorUnwrap, cardTextColorDefault, ribbonColorDefault, envelopeColorDefault, wrappingColorDefault, boxTypeDefault, wrapTypeDefault, positionChangeWidthSm, positionChangeHeightSm, positionChangeWidthMd, bgColors, wrapColors, ribbonColors, confettiColors, envelopeColors, cardTextColors, wrapTypes, boxTypes, giftTypes } from './variableDefaults.js'

// Design a random package
export const randomPackage = (gift, cardText, setWrappingColor, setRibbon, setEnvelope, setCardText, setConfettiColor, setWrappingType, setBoxType, setGift) => {
  // Random wrap color
  const wrapColor = wrapColors[Math.floor(Math.random() * wrapColors.length)]
  setWrappingColor(wrapColor)

  // Ribbon Color can be any color except the wrapping color
  const editedRibbonColors = ribbonColors.filter(color => color !== wrapColor)
  const ribbonColor = editedRibbonColors[Math.floor(Math.random() * editedRibbonColors.length)]
  setRibbon(ribbonColor)

  // Envelope color can be any color except the wrapping color or the ribbon color
  const editedEnvelopeColors = envelopeColors.filter(color => (color !== wrapColor && color !== ribbonColor))
  const envelopeColor = editedEnvelopeColors[Math.floor(Math.random() * editedEnvelopeColors.length)]
  setEnvelope(envelopeColor)

  // Card text color is the same as envelope color (because it looks good)
  setCardText({ ...cardText, color: envelopeColor })

  // Confetti color is the same as ribbon color (because it looks good)
  setConfettiColor(ribbonColor)

  // Random wrap type (neat, messy, swipe)
  const wrapType = wrapTypes[Math.floor(Math.random() * wrapTypes.length)]
  setWrappingType(wrapType)

  // Random box type (cardboard, white)
  const boxType = boxTypes[Math.floor(Math.random() * boxTypes.length)]
  setBoxType(boxType)

  // Update the gift state with the random package
  setGift({ 
    ...gift, 
    package: {
      confettiColor: ribbonColor,
      boxType: boxType,
      wrappingType: wrapType,
      wrappingColor: wrapColor,
      ribbonColor: ribbonColor,
      envelopeColor: envelopeColor,
      cardText: { ...cardText, color: envelopeColor },
    },
  })
}

// Random gift options from the retrieved gift ideas; called in useEffect
export const randomGiftOptions = (giftIdeas = [], gift, setGift) => {

  // random gift type (oneItem or chooseOne)
  const giftType = giftTypes[Math.floor(Math.random() * giftTypes.length)]

  // Note depends on type of gift
  const noteText = giftType === 'oneItem' ? 
    'Dear User, \n \nThis is an example of a how a gift is revealed. Below is a series of questions that a sender might ask before purchasing the gift. \n \nWith Care, \n3D Wrap'
    :
    'Dear User, \n \nThis is an example of a how a gift is revealed. In this instance, the sender has several ideas and wants you to choose your favorite. Make your choice, then answer a series of questions that a sender might ask before purchasing the gift. \n \nWith Care, \n3D Wrap'
  
  // Choose a random gift idea
  const randomGiftItem = giftIdeas[Math.floor(Math.random() * giftIdeas.length)]
  let newOptions
  if (giftType === 'oneItem') {
    // If gift type is oneItem, make newOptions with the random gift idea
    newOptions = [
      [
        {
          link: randomGiftItem.link,
          image: randomGiftItem.image,
          brand: randomGiftItem.brand.charAt(0).toUpperCase() + randomGiftItem.brand.slice(1),
          product: randomGiftItem.subtitle,
        }
      ]
    ]

  } else {
    // If gift type is chooseOne, create two more random gift ideas, and make newOptions with the 3 random gift ideas

    const option2Choices = giftIdeas.filter(idea => idea !== randomGiftItem)
    const option2 = option2Choices[Math.floor(Math.random() * option2Choices.length)]
    
    const option3Choices = option2Choices.filter(idea => idea !== option2)
    const option3 = option3Choices[Math.floor(Math.random() * option3Choices.length)]

    newOptions = [
      [
        {
          link: randomGiftItem.link,
          image: randomGiftItem.image,
          brand: randomGiftItem.brand.charAt(0).toUpperCase() + randomGiftItem.brand.slice(1),
          product: randomGiftItem.subtitle,
        }
      ],
      [
        {
          link: option2.link,
          image: option2.image,
          brand: option2.brand.charAt(0).toUpperCase() + option2.brand.slice(1),
          product: option2.subtitle,
        }
      ],
      [
        {
          link: option3.link,
          image: option3.image,
          brand: option3.brand.charAt(0).toUpperCase() + option3.brand.slice(1),
          product: option3.subtitle,
        }
      ]
    ]
  }

  // Set the gift with the new options
  setGift(
    { 
      ...gift, 
      options: newOptions,
      note: noteText,
      type: giftType,
    }
  )
}


export const giftObjectDefault = () => {
  return {
    type: 'oneItem',
    note: 'Dear User, \n \nThis is an example of a how a gift is revealed. Below is a series of questions that a sender might ask before purchasing the gift. \n \nWith Care, \n3D Wrap',
    wasOpened: false,
    senderUsername: '3D Wrap',
    senderProfilePicture: profPicDefaultURL,
    package: {
      confettiColor: confettiColorDefault,
      boxType: boxTypeDefault,
      wrappingType: wrapTypeDefault,
      wrappingColor: wrappingColorDefault,
      ribbonColor: ribbonColorDefault,
      envelopeColor: envelopeColorDefault,
      cardText: {
        salutation: '',
        recipientName: '',
        message: '',
        signOff: '',
        senderName: '',
        color: cardTextColorDefault,
      },
    },
    feedback: {
      qAndA: [
        {
          q: 'Will you actually use it, or would you prefer a similarly priced item?',
        },
        {
          q: 'Which color do you like most?',
        },
        {
          q: 'Which size will fit you best?',
        },
        {
          q: 'What address should it be sent to?',
        }
      ],
    },
    options: [
      [
        {
          link: 'https://www.pozo.store/',
          image: 'https://3d-wrap-giftidea-images.s3.amazonaws.com/seed-pozo-2.jpg',
          brand: 'PoZo',
          product: 'Position-Specific Pillows',
        }
      ]
    ],
  }
}