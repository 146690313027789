import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { wrapCanvasElement } from '../../../helpers/wrapHelpers'
import { mapItemsElement } from '../../../helpers/mapItemsWithinOptions'
import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { noteTextTypography } from '../../../helpers/typographies'
import { positionChangeWidthSm, positionChangeWidthMd, ministepsHeight } from '../../../helpers/variableDefaults'

import useWindowDimensions from '../../../helpers/windowDimensions'


const Review = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    backArrow, forwardArrow,
    typeChosen, giftOptions, questions, note,
    boxType, wrapping, ribbon, envelope, cardText, environment, 
    miniStepsPackage, activeBottomStep,
  } = props

  console.log('gift options ->', giftOptions)

  // resets view when forward or back arrow is pressed
  useEffect(() => {

  }, [activeBottomStep])


  return (
    <>
      <Grid container spacing={0} 
        sx={{ 
          height: '100%',
        }}
      >
        {/* Wrap Present Canvas */}
        {wrapCanvasElement(width, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep)}

        {/* Review of Gift Options/Items, questions, and note */}
        <Grid item xs={12} md={6} 
          sx={{ 
            boxShadow: 0, 
            height: width < positionChangeWidthMd ? '60%' : '100%', maxHeight: width < positionChangeWidthMd ? '60%' : '100%',
            width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', 
          }}
        >
          
          {/* Gift */}
          <Box sx={{ 
            height: width < positionChangeWidthSm ? `calc(100% - ${ministepsHeight})` : '100%',
            maxHeight: width < positionChangeWidthSm ? `calc(100% - ${ministepsHeight})` : '100%',
            borderBottom: width >= positionChangeWidthMd ? '1px solid #e1e1e1' : '',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              // -webkit-appearance: none,
              WebkitAppearance: 'none',
              width: '7px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            pl: 2, pt: 2,
            width: '100%', 
          }}>

            {/* Type Chosen */}
            <Box sx={{ ml: 0, mt: 1, mb: 1 }}>
              <Typography>
                <strong>Type:</strong> {typeChosen === 'oneItem' ? 'Single gift' : 'Multiple ideas, recipient chooses one'}
              </Typography>
            </Box>
            
            {/* Gifts */}
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              {typeChosen === 'oneItem' ?
                <>

                  {mapItemsElement(0, 'Gift:', giftOptions)}
                
                </>
                :
                <>
                  {giftOptions.map((option, optionIndex) => (
                    <Box key={optionIndex} sx={{ 
                      mb: 0, 
                      width: '100%',
                      display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center',
                    }}>

                      {mapItemsElement(optionIndex, `Option ${optionIndex + 1}`, giftOptions)}

                    </Box>
                  ))}
                </>
              }
            </Box>
            
            {/* Questions */}
            <Box sx={{ mt: 4, mb: 0, width: '95%' }}>
              <Typography sx={{ mt: 1 }}>
                <strong>Questions:</strong>
              </Typography>
              <Box 
                sx={{
                  backgroundColor: 'lightyellow',
                  mt: 1,
                  pl: 3, pr: 3, pt: 1, pb: 1,
                  borderRadius: '5px',
                  border: 2,
                  borderColor: 'plum',
                }}
              >
                {questions.map((question, questionIndex) => (
                  <Box key={questionIndex} sx={{
                    display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                  }}>
                    {question.checked && 
                      <Typography variant='p' sx={{ 
                        mb: 1, mt: 1, 
                        width: '95%', 
                      }}>
                        — {question.q}
                      </Typography>
                    }
                  </Box>
                ))}
              </Box>
            </Box>

            {/* Note */}
            <Box sx={{ mt: 4, mb: 3, width: '95%' }}>
              <Box>
                <Typography>
                  <strong>Note:</strong>
                </Typography>
              </Box>
              <Box 
                sx={{
                  backgroundColor: 'lavender',
                  mt: 1,
                  pl: 3, pr: 3, pt: 2, pb: 2,
                  borderRadius: '5px',
                  border: 2,
                  borderColor: 'plum',
                }}
              >
                {noteTextTypography(note, '20px', 'Caveat')}
              </Box>
            </Box>
          </Box>
          
          {/* Arrows in view if width under 600px */}
          {
            // width < positionChangeWidthSm && 
            packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, false)
          }
          
        </Grid>
      </Grid>  
    </>
  )
}

export default Review