/* eslint-disable react/no-unescaped-entities */

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import { noteTextTypography } from '../../../helpers/typographies'
import { chooseGiftCheckbox } from '../../../helpers/checkboxes'
import { mapItemsWithinOptionUnwrap } from '../../../helpers/mapItemsWithinOptions'

export const revealGift = (options, note, optionChosen, setOptionChosen, feedback, gift, setGift) => {

  // Execute selecting an option for chooseOne gifts
  const handleChooseOption = (e, optionIndex) => {
    e.preventDefault()
    
    // set optionChosen
    setOptionChosen(optionIndex === optionChosen ? -1 : optionIndex)
    
    // Update the feedback object
    const feedbackToEdit = { ...feedback }
    feedbackToEdit.optionChosen = optionIndex === optionChosen ? -1 : optionIndex

    // Update the gift with the new feedback object
    setGift({ ...gift, feedback: feedbackToEdit })
  }

  return (
    <Grid container spacing={0} sx={{
      maxWidth: '850px',
      display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'flex-start',
    }}>

      {/* Useful text, if there are multiple gift options */}
      <Grid item xs={12} sx={{
        mt: 1, mb: 0,
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
      }}>
        {options.length > 1 &&
          <>
            <Typography textAlign={'center'} sx={{ width: '75%', maxWidth: '400px', pt: 2 }}>
              <strong>{options.length} ideas, check the box underneath your favorite</strong>
            </Typography>
            <Typography textAlign={'center'} component="pre"
              sx={{ 
                width: '100%', mt: 0, mb: 0,
                fontStyle: 'italic',
                color: 'purple',
              }}
            >
              *Click images to view gift links
            </Typography>
          </>
        }
        
      </Grid>

      {/* Options */}
      {options.map((option, optionIndex) => ( 
        <Grid item key={optionIndex} xs={10} sm={options.length === 2 ? 5.5 : 8 } md={ options.length === 3 ? 3.5 : options.length === 2 ? 5.8 : 6.1 } 
          sx={{
            backgroundColor: 'whitesmoke',
            borderRadius: '5px',
            mb: 1, mt: 3,
            pt: 1, pb: 1,
            boxShadow: 3,
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
          }}
        >
          {/* Title */}
          <Typography sx={{ mb: options.length > 1 ? 1 : 0 }}>
            <strong>{options.length === 1 ? 'Your Gift:' : `Option ${optionIndex + 1}`}</strong>
          </Typography>
          {options.length === 1 &&
            <Typography textAlign={'center'} component="pre"
              sx={{ 
                width: '100%', mt: 0, mb: 1,
                fontStyle: 'italic',
                color: 'purple',
              }}
            >
              Click image to view gift link
            </Typography>
          }

          {/* Items Within Option */}
          {mapItemsWithinOptionUnwrap(options, optionIndex)}
          
          {/* Checkbox under option, if multiple options */}
          {options.length > 1 &&
            <Box sx={{
              width: '100%',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
            }}>
              {chooseGiftCheckbox(
                gift.wasOpened, 
                optionIndex === optionChosen, 
                handleChooseOption, 
                optionIndex
              )}
            </Box>
          }
        </Grid>
      ))}

      {/* Note from Sender */}
      <Grid item xs={10} sm={8} md={6.1} sx={{
        mt: 2, mb: 2, 
        width: '100%',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}>
        <Box sx={{
          backgroundColor: 'lavender',
          mt: 1,
          pl: 3, pr: 3, pt: 2, pb: 2,
          borderRadius: '5px',
          border: 2,
          borderColor: 'plum',
          width: '100%', maxWidth: '600px',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
        }}>
          {noteTextTypography(note, '20px', 'Caveat')}
        </Box>
      </Grid>

    </Grid>
  )
}