
import MobileStepper from '@mui/material/MobileStepper'

import Box from '@mui/material/Box'

import { positionChangeWidthSm, ministepsHeight } from './variableDefaults'


// Standardized mobile stepper used in wrap and unwrap views
export const mobileStepperElement = (activeStep, steps) => {
  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, mb: 2, mt: 2, 
        bgcolor: 'transparent', 
        color: 'mediumorchid', 
      }}
    />
  )
}

// Mobile stepper and arrows for some of the wrap views at some widths
export const packageViewMobileStepperAndArrows = (width, miniStep, backArrow, forwardArrow, fromPackage = true, fromGift = false) => {
  return (
    <Box
      sx={{
        height: ministepsHeight,
        width: '100%',
        mb: 2, mt: 2,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {
        // width < positionChangeWidthSm && 
        backArrow
      }
      {fromPackage && mobileStepperElement(miniStep, 7)}
      {fromGift && mobileStepperElement(miniStep, 3)}
      {
        // width < positionChangeWidthSm && 
        forwardArrow
      }

    </Box>
  )
}

// Mobile stepper and arrows used in some unwrap views at narrow widths
// Not currently being used
export const unwrapViewMobileStepperAndArrows = (activeStep, skipFeedback, backArrow, forwardArrow, doneButton) => {
  return (
    <Box 
      sx={{
        backgroundColor: '#e1e1e1',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {/* {((activeStep === 1 && skipFeedback) || (activeStep === 2 && skipFeedback === false))
        && doneButton()
      } */}
      <Box
        sx={{
          height: ministepsHeight,
          mb: 2, mt: 2,
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {backArrow}
        {mobileStepperElement(activeStep, skipFeedback ? 2 : 3)}
        {forwardArrow}
        {/* {((activeStep === 1 && skipFeedback) || (activeStep === 2 && skipFeedback === false)) ? 
          doneButton()
          :
          <>
            {mobileStepperElement(activeStep, skipFeedback ? 2 : 3)}
            {forwardArrow}
          </>
        } */}
      </Box>
    </Box>
  )
}