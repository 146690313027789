/* eslint-disable react/no-unknown-property */
import React, { useState, Suspense, lazy } from 'react'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { pointLights, cameraPositionUnwrap, textBeneathGiftUnwrap } from '../../helpers/canvasElements'
import { canvasBgDefaultColorUnwrap, canvasBgDefaultColorWrap, navbarBgDefaultColor, positionChangeWidthMd, positionChangeWidthSm } from '../../helpers/variableDefaults'
import useWindowDimensions from '../../helpers/windowDimensions'

// Models — Lazy Loading important so that it shows a 'loading...' indicator
const BoxNoPaper = lazy(() => import('../models/unwrap/BoxNoPaper'))
const CardboardBox = lazy(() => import('../models/unwrap/CardboardBox'))
const EnvelopeAndCard = lazy(() => import('../models/unwrap/EnvelopeAndCard'))
const MessyWrapping = lazy(() => import('../models/unwrap/MessyWrapping'))
const NeatWrapping = lazy(() => import('../models/unwrap/NeatWrapping'))
const Ribbon = lazy(() => import('../models/unwrap/Ribbon'))
const SwipewrapCloneWrapping = lazy(() => import('../models/unwrap/SwipewrapCloneWrapping'))

// Models — Not Lazy Loading — we don't want the 'loading...' indicator to show mid-unwrap
import MessageOnCard from '../models/unwrap/MessageOnCard'
import Confetti300 from '../models/unwrap/Confetti300'

// Loading indicator
import Loader from '../common/Loader'

// Show Present — called in Home.js and UnwrapGift.js
function ShowPresent(props) {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    confettiColor, backgroundColor, cardText, envelope, ribbon, wrappingType, wrappingColor, boxType,
    finishedUnwrap, setFinishedUnwrap, setRevealGift, 
  } = props
  
  // Animation Index
  const [animationIndex, setAnimationIndex] = useState(0)

  // URL Path
  const urlPath = window.location.pathname


  return (
    <div style = {{ 
      height: '100%', 
      backgroundColor: urlPath === '/' ? canvasBgDefaultColorWrap : urlPath === '/example' ? navbarBgDefaultColor : backgroundColor ? backgroundColor : canvasBgDefaultColorUnwrap, 
    }}>
      {/* 3D Canvas */}
      <Canvas>
        {/* Scene Lighting */}
        {pointLights()}

        {/* 3D Models Container */}
        <Suspense 
          fallback={<Loader />}
        >
          
          {/* Envelope and Card */}
          <EnvelopeAndCard animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} envelope={envelope} />

          {/* Message on Card */}
          {animationIndex > 0 && 
            <MessageOnCard cardText={cardText} />
          }

          {/* Ribbon */}
          <Ribbon animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} ribbon={ribbon} />

          {/* Wrapping */}
          {wrappingType === 'neat' ?
            <NeatWrapping animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} wrappingColor={wrappingColor} />
            :
            wrappingType === 'messy' ?
              <MessyWrapping animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} wrappingColor={wrappingColor} />
              :
              <SwipewrapCloneWrapping animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} wrappingColor={wrappingColor} />
          }
          
          {/* Box */}
          {boxType === 'cardboard' ? 
            <CardboardBox animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} setFinishedUnwrap={setFinishedUnwrap} setRevealGift={setRevealGift}/>
            :
            <BoxNoPaper animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} setFinishedUnwrap={setFinishedUnwrap} setRevealGift={setRevealGift}/>
          }

          {/* Confetti */}
          {animationIndex > 3 &&
            <Confetti300 animationIndex={animationIndex} confettiColor={confettiColor} />
          }
        
          {/* Text beneath gift */}
          {textBeneathGiftUnwrap(width, height, animationIndex, urlPath)}

        </Suspense>

        
        {/* Camera Positioning */}
        {cameraPositionUnwrap(width, height, urlPath)}

        {/* Orbit Controls */}
        <OrbitControls 
          enableRotate={
            (width < positionChangeWidthMd && urlPath === '/') 
            // || (width < positionChangeWidthSm && urlPath !== '/') //Comment in if you want rotation disabled for unwrapping on mobile
              ? 
              false : true
          } 
          enableZoom={false} 
          enablePan={false} 
        />

      </Canvas>
    </div>
  )
}

export default ShowPresent