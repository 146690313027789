/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import useWindowDimensions from '../../helpers/windowDimensions'
import { seoPageTags } from '../../helpers/analytics'
import { emailTextField, loginTextField } from '../../helpers/textfields'
import { standardButton } from '../../helpers/buttons'
import { validEmail, handleChange } from '../../helpers/globalHelpers'
import { standardErrorContainer } from '../../helpers/errors'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'


// Reset Password — route: /reset-password/
const ResetPassword = () => {

  // Params
  const { userId, token } = useParams()
  // console.log('userId ->', userId)
  // console.log('token ->', token)

  // use Naviage
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Forgot Password State
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')

  // Link Sent
  const [linkSent, setLinkSent] = useState(false)
  const [sentLinkEmail, setSentLinkEmail] = useState('')

  // New Password
  const [newPasswordForm, setNewPasswordForm] = useState({
    newPassword: '',
    newPasswordConfirmation: '',
    userId: userId ? userId : '',
    token: token ? token : '',
  })

  // Errors
  const [emailErrors, setEmailErrors] = useState(false)
  const [newPasswordErrors, setNewPasswordErrors] = useState(false)

  // Handle Set Email
  const handleSetEmail = async (e) => {
    e.preventDefault()

    // get name and value from the event target
    const { name, value } = e.target

    setForgotPasswordEmail(value)

    setEmailErrors(false)

  }

  const handleSendResetLink = async (e) => {
    e.preventDefault()
    console.log('handle send reset link runs')

    const formToUpload = { email: forgotPasswordEmail }
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reset-password/link-request/`, formToUpload)
      
      console.log('data ->', data)

      // Update States
      setSentLinkEmail(forgotPasswordEmail)
      setForgotPasswordEmail('')
      setLinkSent(true)
      setEmailErrors(false)


    } catch (err) {
      // console.log('err: ', err)
      setEmailErrors(true)
    }
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault()
    console.log('handle password reset runs')

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reset-password/execute/`, newPasswordForm)
      
      console.log('data ->', data)

      // Reset the form
      setNewPasswordForm({
        newPassword: '',
        newPasswordConfirmation: '',
        userId: userId ? userId : '',
        token: token ? token : '',
      })

      // Navigate to login screen
      navigate('/login')
      
    } catch (err) {
      console.log('err: ', err)
    }

  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Reset Password'
      )}

      {/* Body — Reset Password Form */}
      <Box 
        sx={{ 
          // backgroundColor: 'blue',
          pl: 1, pr: 0, pb: 1, width: '100%', height: '100vh',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch', 
        }}
      >
        <Box 
          sx={{
            // backgroundColor: 'yellow',
            width: '85%',
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography sx={{ mb: 0 }}>
            <strong>Reset Password</strong>
          </Typography>


          {/* Useful Info */}
          <Typography 
            textAlign={'left'}
            component="pre"
            sx={{
              mt: 0, mb: 1,
              fontSize: '14px',
              fontStyle: 'italic',
              color: linkSent ? 'blue' : 'purple',
            }}
          >
            {!linkSent && !userId &&
              `*A password reset link will be sent to the submitted email address.`
            }
            {linkSent && !userId &&
              `*A password reset link was sent to ${sentLinkEmail}.
              **Link is valid for 60 minutes`.replace(/ {5} +/g, '') //the regex is for deleting the indentation spaces in the string literal`
            }
            {userId &&
              `Input a new password and then press 'Reset Password'`
            }
          </Typography>

          
          {/* TextField and Button Container */}
          <Box 
            component="form" 
            onSubmit={handleSetEmail} 
            noValidate 
            textAlign={'center'}
            sx={{ 
              mt: 1,
              width: '85%', maxWidth: '350px',
            }}
          >
            {!userId ?
              <>
                {/* Email TextField */}
                {emailTextField(
                  'forgot-password-email', 
                  forgotPasswordEmail, 
                  'email address', 
                  'outlined',
                  handleSetEmail, 
                  forgotPasswordEmail, 
                  setForgotPasswordEmail,
                  width
                )}
              </>
              :
              <>
                {/* Password TextField */}
                <Box sx={{ mt: 1 }}>
                  {loginTextField('newPassword', 'Password', newPasswordForm.newPassword, 'outlined', true, true, false, 'new-password', newPasswordForm, setNewPasswordForm, setNewPasswordErrors, handleChange )}
                </Box>
                
                {/* Password Confirmation */}
                <Box sx={{ mt: 2 }}>
                  {loginTextField('newPasswordConfirmation', 'Password Confirmation', newPasswordForm.newPasswordConfirmation, 'outlined', true, true, false, 'new-password', newPasswordForm, setNewPasswordForm, setNewPasswordErrors, handleChange )}
                </Box>

                {/* Error message — Password must be same as password confirmation */}
                {newPasswordForm.newPasswordConfirmation.length > 0 
                  && newPasswordForm.newPasswordConfirmation !== newPasswordForm.newPassword
                  &&
                    <Typography 
                      sx={{
                        fontSize: '14px',
                        fontStyle: 'italic',
                        color: 'red',
                      }}
                    >
                      *password confirmation must match password
                    </Typography>
                }
              </>
            }
            

            {/* Button */}
            <Box 
              sx={{
                mt: 2,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'center',
              }}
            >
              {standardButton(
                !userId ? linkSent ? 'New Reset Link' : 'Send Reset Link' : 'Reset Password', 
                'button',
                'contained',
                !userId ? !validEmail(forgotPasswordEmail) : !(newPasswordForm.newPassword.length > 0 && newPasswordForm.newPasswordConfirmation.length > 0 && (newPasswordForm.newPassword === newPasswordForm.newPasswordConfirmation)), 
                'secondary',
                2,
                2,
                0,
                '160px',
                '45px',
                !userId ? handleSendResetLink : handlePasswordReset
              )}
            </Box>
            
            {/* Error Message, if errors */}
            {((!userId && emailErrors) || (userId && newPasswordErrors)) &&
              standardErrorContainer(
                emailErrors ? '*No account associated with this email address' : '*Could not reset password. Keep in mind that the password reset link expires after 60 minutes.',
                0,
                0
              )
            }
            
          </Box>
          
        </Box>
      </Box>

    </>
  )
}

export default ResetPassword