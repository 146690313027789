import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { packageViewMobileStepperAndArrows } from '../../../../helpers/mobileSteppers'
import { dummyBox, validEmail } from '../../../../helpers/globalHelpers'
import { emailToCheckboxElement } from '../../../../helpers/checkboxes'
import { emailTextField } from '../../../../helpers/textfields'
import { standardButton } from '../../../../helpers/buttons'
import { standardErrorContainer } from '../../../../helpers/errors'
import { positionChangeWidthSm, ministepsHeight } from '../../../../helpers/variableDefaults'
import { standardSpinner } from '../../../../helpers/spinners'
import { userIsAuthenticated, getEmailFromLocalStorage } from '../../../../helpers/storage'

// Email To
export const emailTo = (send, setSend, handleSetSend, handleSendPresent, sendErrors, backArrow, forwardArrow, width, loading, setLoading, loginOptionChosen, setLoginOptionChosen) => {

  if (userIsAuthenticated() && loginOptionChosen === false) {
    send.senderEmail = getEmailFromLocalStorage()
    setLoginOptionChosen(true)
  }

  return (
    <>
      {
        loading ?
          standardSpinner()
          :
          <>
            {/* Dummy Box to get spacing right */}
            {dummyBox('0px')}
            
            {/* Send Options */}
            <Box sx={{ 
              // backgroundColor: 'yellow',
              height: `calc(100% - ${ministepsHeight})`,
              maxHeight: `calc(100% - ${ministepsHeight})`,
              overflow: 'auto',
              width: '100%', 
              maxWidth: '300px',
              pt: 2, pb: 2,
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch', 
            }}>

              {/* Guiding text */}
              <Typography sx={{ 
                pl: 1, pr: 0,
                width: '100%', 
              }}>
                Email link to:
              </Typography>
              
              {/* Sender Email */}
              <Box sx={{ 
                pl: 1, 
                pr: 0, pb: 1, width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'flex-start', 
              }}>
                {/* Sender Email Textfield */}
                {emailTextField(
                  'senderEmail', 
                  send.senderEmail, 
                  'My email address (required)',
                  'standard', 
                  handleSetSend, 
                  send, 
                  setSend,
                  width
                )}
              </Box>
              
              {/* Recipient Email */}
              <Box sx={{ 
                pl: 1, 
                pr: 0, pb: 1, width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'flex-start', 
              }}>
                {/* Recipient Email Textfield */}
                {emailTextField(
                  'recipientEmail', 
                  send.recipientEmail, 
                  'Recipient email address (optional)', 
                  'standard',
                  handleSetSend, 
                  send, 
                  setSend,
                  width
                )}
              </Box>
              
              {/* Button */}
              <Box 
                sx={{
                  width: '100%',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                }}
              >
                {standardButton(
                  'Send', 
                  'button',
                  'contained',
                  (
                    (
                      !validEmail(send.senderEmail) 
                      // && !validEmail(send.recipientEmail) 
                      // && send.copyLinkOnly === false
                    ) 
                  ), 
                  'secondary',
                  2,
                  2,
                  0,
                  '150px',
                  '45px',
                  handleSendPresent
                )}
              </Box>


              {/* Errors if there are errors */}
              {sendErrors && 
                standardErrorContainer(
                  'Could not send gift. Check your Internet connection.',
                  0,
                  0
                )
              }

            </Box>

            {/* Arrows in view if width under 600px */}
            {width < positionChangeWidthSm && 
              packageViewMobileStepperAndArrows(width, 7, backArrow, forwardArrow, false)
            }
          </>
      }
    </>
  )
}