/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import axios from 'axios'

import { getPayload, getTokenFromLocalStorage, getEmailFromLocalStorage, getUsernameFromLocalStorage, getProfPicFromLocalStorage, userIsAuthenticated } from '../../../helpers/storage'
import { noteTextTypography } from '../../../helpers/typographies'
import { thankYouNoteTextField, emailTextField } from '../../../helpers/textfields'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

export const emailResponses = (feedback, gift, setGift, width, originalSenderEmail) => {

  // URL Path
  const urlPath = window.location.pathname

  // Payload
  const payload = getPayload()

  // Execute Thank You Note Inputs
  const handleSetThankYouNote = (e) => {

    // Destructure the feedback object
    const feedbackToEdit = { ...feedback }

    // set the thankYouNote equal to the event target value
    feedbackToEdit.thankYouNote = e.target.value

    // set the gift state with the updated feedback object
    setGift({ ...gift, feedback: feedbackToEdit })

  }

  const handleSetEmail = async (e) => {
    e.preventDefault()

    // get name and value from the event target
    const { name, value } = e.target

    // destructure the gift object
    const editedGift = { ...gift }

    // Edit the gift object appropriately
    if (name === 'recipientEmail') {
      editedGift.recipientEmail = value
    } else if (name === 'senderEmail') {
      editedGift.senderEmail = value
    } 

    // set the gift object with editedGift
    setGift(editedGift)

  }
  
  return (
    <Grid container sx={{
      width: '100%',
      mt: 2,
      p: 1,
      borderRadius: '5px',
      display: 'flex', flexDirection: 'row', justifyContent: 'space-around',
    }}>

      {/* Send Responses to email addresses */}
      {urlPath !== '/' && urlPath !== '/example'
      && !(userIsAuthenticated() && payload.sub && gift.sender === payload.sub) 
      &&
        <Box 
          sx={{
            // backgroundColor: 'yellow',
            p: 2,
            mt: 0, mb: 4,
            boxShadow: 3, borderRadius: '5px',
            width: '100%',
            maxWidth: '600px',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
          }}
        >
          {gift.wasOpened ? 
            <Typography sx={{ 
              pl: 1, 
              // backgroundColor: 'red', 
              width: '100%',
            }}>
              Responses sent to:<br/> <strong>{gift.senderEmail && gift.recipientEmail ? `${gift.senderEmail}, ${gift.recipientEmail}` : gift.senderEmail ? `${gift.senderEmail}` : gift.recipientEmail ? `${gift.recipientEmail}` : 'No email addresses given'}</strong>
            </Typography>
            :
            <>
              {/* Guiding Text */}
              <Typography textAlign={'center'} sx={{ 
                pl: 1,
                width: '100%', 
              }}>
                <strong>Send responses to:</strong>
              </Typography>
              
              {/* Sender Email  */}
              <Box sx={{ 
                pl: 0, pr: 0, pb: 1, width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'flex-start', 
              }}>
                {originalSenderEmail !== 'No Email' ?
                  <Typography sx={{ 
                    pl: 1, 
                    // backgroundColor: 'red', 
                    width: '100%',
                  }}>
                    <strong>{gift.senderEmail}</strong>
                  </Typography>
                  :
                  <>
                    {/* Sender Email Textfield */}
                    {emailTextField(
                      'senderEmail', 
                      gift.senderEmail !== 'No Email' ? gift.senderEmail : '', 
                      'Giver email address (required)', 
                      'standard',
                      handleSetEmail, 
                      gift, 
                      setGift,
                      width
                    )}
                  </>
                }
                
              </Box>
              
              {/* Recipient Email */}
              <Box sx={{ 
                pl: 1, pr: 0, pb: 1, width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'flex-start', 
              }}>
                {/* Recipient Email Textfield */}
                {emailTextField(
                  'recipientEmail', 
                  gift.recipientEmail !== 'No Email' ? gift.recipientEmail : getEmailFromLocalStorage() ? getEmailFromLocalStorage() : '', 
                  'My email address (optional)', 
                  'standard',
                  handleSetEmail, 
                  gift, 
                  setGift,
                  width
                )}
              </Box>
            </>
          }
        </Box>
      }
        
      {/* Thank You Note */}
      <Grid item key={3} xs={12} sx={{
        mt: 0, mb: 1.5,
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}>
        {/* Title */}
        <Typography
          sx={{
            mb: 1,
          }}
        >
          <strong>Thank You Note</strong>
        </Typography>

        {/* Thank You Note Text */}
        {gift.wasOpened ?
          // Note Typography if gift was opened already
          <Box sx={{
            backgroundColor: 'lavender',
            mt: 1,
            pl: 3, pr: 3, pt: 2, pb: 2,
            borderRadius: '5px',
            border: 2,
            borderColor: 'plum',
            width: '100%', maxWidth: '600px',
            display: 'flex', flexDirection: 'row', justifyContent: ((gift.feedback.hasOwnProperty('thankYouNote') && (gift.feedback.thankYouNote === 'No thank you note sent'))) ? 'center' : 'flex-start', alignItems: 'center',
          }}>
            <strong>{noteTextTypography(gift.feedback.hasOwnProperty('thankYouNote') ? gift.feedback.thankYouNote.length > 0 ? gift.feedback.thankYouNote : 'No thank you note sent.' : 'No thank you note sent.', '20px', 'Caveat')}</strong>
          </Box>
          :
          // Note TextField if gift was not yet opened
          thankYouNoteTextField(
            'thankYouNote', 
            gift.feedback.hasOwnProperty('thankYouNote') ? gift.feedback.thankYouNote : '', 
            'It\'s polite to say thank you...', 
            handleSetThankYouNote
          )
        }
      </Grid>
    </Grid>
  )
}