import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { colorOptionsElement, wrapCanvasElement } from '../../../helpers/wrapHelpers'
import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { dummyBox, removeColorStyling } from '../../../helpers/globalHelpers'

import useWindowDimensions from '../../../helpers/windowDimensions'
import { positionChangeWidthMd, ministepsHeight, ribbonColors, envelopeColors } from '../../../helpers/variableDefaults'

const Ribbon = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    ribbon, setRibbon, setEnvironment, backArrow, forwardArrow,
    wrapping, boxType, envelope, cardText, environment,
    setEnvelope, setCardText,
    miniStepsPackage, activeBottomStep, 
  } = props
  
  // View updates when ribbon value changes
  useEffect(() => {

  }, [ribbon])

  // Handle ribbon selection
  const handleSetRibbon = async (e) => {
    e.preventDefault()

    // Value from event target classList
    const value = e.target.classList[0].slice(7)

    // Remove styling from non-selected ribbon colors, and add styling to newly selected color if different from previous selection
    removeColorStyling(ribbonColors, value, 'ribbon', ribbon, e)

    // Set Ribbon Color
    setRibbon(`#${value}`)

    // Set Confetti Color default to same as ribbon color
    setEnvironment({ ...environment, confettiColor: `#${value}` })

    // Reset envelope color default if ribbon color is the same
    if (envelope === `#${value}`) {
      const editedEnvelopeColors = envelopeColors.filter(color => (color !== `#${value}` && color !== wrapping.color))
      const envelopeColor = editedEnvelopeColors[Math.floor(Math.random() * editedEnvelopeColors.length)]
      setEnvelope(envelopeColor)

      // Set the card text color default to the same as the envelope color
      setCardText({ ...cardText, color: envelopeColor })
    }
  }

  return (
    <>
      <Grid container spacing={0} 
        sx={{ 
          height: '100%',
        }}
      >
        {/* Wrap Present Canvas */}
        {wrapCanvasElement(width, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep)}

        {/* Ribbon Options */}
        <Grid item xs={12} md={6} 
          sx={{ 
            boxShadow: 0, 
            height: width < positionChangeWidthMd ? '60%' : '100%', maxHeight: width < positionChangeWidthMd ? '60%' : '100%',
            width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
          }}
        >
          {/* Dummy Box to get spacing right */}
          {dummyBox('0px')}

          {/* Title and Options */}
          <Box 
            sx={{ 
              height: `calc(100% - ${ministepsHeight})`,
              maxHeight: `calc(100% - ${ministepsHeight})`,
              // overflow: 'auto',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                // -webkit-appearance: none,
                WebkitAppearance: 'none',
                width: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, .35)',
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
              },
              width: '100%', 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            {/* Title */}
            <Typography sx={{ mb: 2, mt: 2 }}>
              <strong>Ribbon</strong>
            </Typography>

            {/* Colors */}
            {colorOptionsElement('ribbon', ribbonColors, ribbon, handleSetRibbon)}
            
          </Box>
          
          {/* Mobile stepper and, if the screen is small, arrows */}
          {packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, true)}
          
        </Grid>
      </Grid>  
    </>
  )
}

export default Ribbon