/* eslint-disable react/no-unknown-property */
/* eslint-disable camelcase */

import React, { useRef } from 'react'
import { extend } from '@react-three/fiber'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import Lato_Regular from '../../../assets/fonts/Lato_Regular.json'

import useWindowDimensions from '../../../helpers/windowDimensions'

import { messageOnCardElements } from '../elements/messageOnCardElements'
import { yRotationDefaultText, yRotationHomeScreenText, positionChangeWidthSm, positionChangeHeightSm } from '../../../helpers/variableDefaults'


const MessageOnCard = (props) => {
  extend({ TextGeometry })

  // Dynamic height and width
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { cardText } = props

  // Destructure cardText
  const { salutation, recipientName, message, signOff, senderName, color } = cardText

  // URL Path
  const urlPath = window.location.pathname

  // Create the full card message
  const fullMessage = 
    (urlPath === '/' || urlPath === '/example') ? 
      'Wrap and open presents \nonline in 3D' 
      : 
      `${salutation} ${recipientName}\n${message}\n${signOff} ${senderName}` 
    
  // Import fonts
  const latoRegular = new FontLoader().parse(Lato_Regular)

  // Customizing Text Appearance
  const textOptions = {
    font: latoRegular, 
    size: (urlPath === '/' || urlPath === '/example') ? .4 : .3,
    height: .2,
  }

  // Instantiate the textMesh
  const textMesh = useRef()
  
  return (
    <>
      {messageOnCardElements(
        height < positionChangeHeightSm ? (urlPath === '/' || urlPath === '/example') ? [(urlPath === '/' || urlPath === '/example') ? -3 : -1.2, urlPath === '/' ? 2.8 : urlPath === '/example' ? 5 : 5.2, (urlPath === '/' || urlPath === '/example') ? 0 : 0.2] : [(urlPath === '/' || urlPath === '/example') ? -3 : -1.2, (urlPath === '/' || urlPath === '/example') ? 3 : 3.2, (urlPath === '/' || urlPath === '/example') ? 0 : 0.2] :  [(urlPath === '/' || urlPath === '/example') ? -2.8 : -1.2, (urlPath === '/' || urlPath === '/example') ? 3 : 3.2, (urlPath === '/' || urlPath === '/example') ? 0 : 0.2], 
        (urlPath === '/' || urlPath === '/example') ? yRotationHomeScreenText : yRotationDefaultText, 
        textMesh, 
        fullMessage, 
        textOptions, 
        color
      )}
    </>
  )
}

export default MessageOnCard