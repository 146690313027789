
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

// Add http:// to any link that doesn't have it so that the link will go to an outside URL
export const makeOutsideGiftLink = (urlString = '') => {
  if (urlString.toLowerCase().startsWith('http://') || urlString.toLowerCase().startsWith('https://')) {
    return urlString
  } else {
    return 'http://' + urlString
  }
}

// Styling the expand more IconButton for the GiftsCard and GiftIdeasCard
export const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))