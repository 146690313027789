import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { handleGiftItemChange } from '../../../helpers/wrapHelpers'
import { makeSquareOptionImage } from '../../../helpers/imageHandling'
import { giftItemTextField } from '../../../helpers/textfields'
import { replaceImageWithIconButton, addImageWithTextButton, deleteItemIconButton } from '../../../helpers/buttons'
import { standardSpinner } from '../../../helpers/spinners'
import { emailToCheckboxElement } from '../../../helpers/checkboxes'

import { Input } from '../../../helpers/globalHelpers'


// Gift Item
const GiftItem = (props) => {
  
  // Destructure Props
  const { setPutErrors, setGiftOptions, giftOptions, optionIndex, itemIndex, allGiftOptionElementsAdded, allSingleOptionElementsAdded } = props

  // Loading
  const [loadingImage, setLoadingImage] = useState(false)

  // Adding a new image when the user selects the Upload Image or PhotoCamera button
  const handleImageSelect = async (e) => {

    // Get optionIndex and itemIndex from the event target classList
    const imageOptionIndex = parseInt(e.target.classList[0].slice(-1))
    const imageItemIndex = parseInt(e.target.classList[1].slice(-1))

    // Set errors to false and loading to true
    setPutErrors(false)
    setLoadingImage(true)

    // Center and crop the image into a 600x600 jpg; dataURL for image sets at bottom of this function
    makeSquareOptionImage(e.target.files[0], imageOptionIndex, imageItemIndex, setGiftOptions, giftOptions)
    
    // Set Loading to false
    setLoadingImage(false)
  }

  // Update view every time giftOptions changes
  useEffect(() => {

  }, [giftOptions])

  // Handle deleting an item within an option
  const handleDeleteItem = async (e) => {
    e.preventDefault()
    
    // Get item and option indexes from the event currentTarget classList
    const selectedItemIndexString = e.currentTarget.classList[0].charAt(0)
    const selectedItemIndex = parseInt(selectedItemIndexString)
    const selectedOptionIndexString = e.currentTarget.classList[1].charAt(0)
    const selectedOptionIndex = parseInt(selectedOptionIndexString)

    // Destructure the giftOptions array
    const newArray = [ ...giftOptions ]
    
    // Splice the specified option from giftOptions
    if (selectedItemIndex > -1 && selectedOptionIndex > -1 ) { 
      newArray[selectedOptionIndex].splice(selectedItemIndex, 1)
    }

    // setGiftOptions with the newArray
    setGiftOptions(newArray)
  }

  // Executes checkbox selection
  const handleSetNoGiftLink = async (e) => {
    e.preventDefault()

    // destructure the giftOptions object
    const editedGiftOptions = [ ...giftOptions ]

    // Link equals checkbox value
    editedGiftOptions[optionIndex][itemIndex].link = editedGiftOptions[optionIndex][itemIndex].link === 'No gift link' ? '' : 'No gift link'

    // set the giftOptions object with editedGiftOptions
    setGiftOptions(editedGiftOptions)
  }
  
  return (
    <>
      {/* This must be first input So that the file upload only fires when you press the button */}
      <Box sx={{ width: '100%' }}>
        <Input type="text" autoFocus="autoFocus" />
      </Box>
      
      {/* Gift Item */}
      <Box 
        sx={{ 
          backgroundColor: 'whitesmoke', 
          mt: 1, mb: 2, boxShadow: 3, borderRadius: '5px', width: '90%', 
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', 
        }}
      >

        {/* Delete item button if more than one item */}
        {itemIndex > 0 &&
          deleteItemIconButton(itemIndex, optionIndex, handleDeleteItem)
        }

        {/* Link */}
        {giftOptions[optionIndex][itemIndex].link !== 'No gift link' &&
          <Box 
            sx={{ 
              mt: 2, mb: 1, width: '100%', 
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            {giftItemTextField('link', 'Link', giftOptions[optionIndex][itemIndex].link, 'Paste Link to Gift', true, handleGiftItemChange, setPutErrors, setGiftOptions, giftOptions, optionIndex, itemIndex, setLoadingImage)}
          </Box>
        }

        {/* Checkbox — No Gift Link */}
        {(giftOptions[optionIndex][itemIndex].link.length < 1 || giftOptions[optionIndex][itemIndex].link === 'No gift link') &&
          <Box
            sx={{
              width: '100%',
              mb: giftOptions[optionIndex][itemIndex].link === 'No gift link' ? 1.48 : 0,
            }}
          >
            {emailToCheckboxElement(
              'link', 
              giftOptions[optionIndex][itemIndex].link === 'No gift link', 
              `<p>No gift link</p>`,
              handleSetNoGiftLink, 
              giftOptions, 
              setGiftOptions,
              'left',
              -2
            )}
          </Box>
        }

        {/* Link Instructions */}
        {giftOptions[optionIndex].length - 1 === itemIndex 
          && giftOptions[optionIndex][itemIndex].link.length < 1 
          &&
          <Typography
            textAlign={'left'}
            component="pre"
            sx={{
              mb: 1,
              width: '95%',
              pl: '5px', pr: '5px',
              fontSize: '14px',
              fontStyle: 'italic',
              color: 'purple',
            }}
          >
            {
              `*Paste in link to gift (must start with http or https)
              **OR select 'No gift link'`.replace(/ {5} +/g, '') //the regex is for deleting the indentation spaces in the string literal
            }
          </Typography>
        }

        {/* Image */}
        {/* If there is an image, make an icon button, if not, make an 'upload image' button */}
        {giftOptions[optionIndex][itemIndex].link.length > 0 &&
          <>
            {giftOptions[optionIndex][itemIndex].image ? 
              <>
                {
                  loadingImage ?
                    standardSpinner()
                    :
                    // Photocamera Icon over current image
                    replaceImageWithIconButton(
                      giftOptions[optionIndex][itemIndex].image, 
                      125, 
                      `${optionIndex}${itemIndex}`, 
                      `option-${optionIndex} item-${itemIndex}`, 
                      handleImageSelect,
                      -4
                    )
                }
              </>
              :
              <Box sx={{ height: 133, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {
                  loadingImage ?
                    standardSpinner()
                    :
                    // Purple button with 'Image' in white text
                    addImageWithTextButton(
                      'Image', 
                      `${optionIndex}${itemIndex}`, 
                      `option-${optionIndex} item-${itemIndex}`, 
                      handleImageSelect
                    )
                }
              </Box>
            }
          </>
        }  

        {/* Image Instructions */}
        {giftOptions[optionIndex].length - 1 === itemIndex 
          && giftOptions[optionIndex][itemIndex].link.length > 1 
          && giftOptions[optionIndex][itemIndex].image.length < 1 
          && 
            <Typography
              textAlign={'left'}
              component="pre"
              sx={{
                mb: 1,
                width: '95%',
                pl: '5px', pr: '5px',
                fontSize: '14px',
                fontStyle: 'italic',
                color: 'purple',
              }}
            >
              {(giftOptions[optionIndex][itemIndex].link.includes('http://') || giftOptions[optionIndex][itemIndex].link.includes('https://')) ?
                `*Upload image manually if no metadata image is found
                *Use 1:1 image for best results`.replace(/ {8} +/g, '') //the regex is for deleting the indentation spaces in the string literal
                :
                `*Upload image manually
                *Use 1:1 image for best results`.replace(/ {8} +/g, '') //the regex is for deleting the indentation spaces in the string literal
              }
            </Typography>
        }

        {giftOptions[optionIndex].length - 1 === itemIndex 
          && giftOptions[optionIndex][itemIndex].link.length > 1 
          && giftOptions[optionIndex][itemIndex].image.length > 0
          && giftOptions[optionIndex][itemIndex].brand.length < 1 
          && giftOptions[optionIndex][itemIndex].product.length < 1  
          && 
            <Typography
              textAlign={'left'}
              component="pre"
              sx={{
                mb: 1.5,
                width: '95%',
                pl: '5px', pr: '5px',
                fontSize: '14px',
                fontStyle: 'italic',
                color: 'purple',
              }}
            >
              {
                `*Click camera to change image
                *Use 1:1 image for best results`.replace(/ {8} +/g, '') //the regex is for deleting the indentation spaces in the string literal
              }
            </Typography>
        }

        {/* Brand */}
        {giftOptions[optionIndex][itemIndex].link.length > 0 && giftOptions[optionIndex][itemIndex].image.length > 0 &&
          <>
            <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {giftItemTextField('brand', 'Brand*', giftOptions[optionIndex][itemIndex].brand, '', true, handleGiftItemChange, setPutErrors, setGiftOptions, giftOptions, optionIndex, itemIndex, setLoadingImage)}
            </Box>
          </>
        }

        {/* Product */}
        {giftOptions[optionIndex][itemIndex].link.length > 0 && giftOptions[optionIndex][itemIndex].image.length > 0 &&
          <>
            <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {giftItemTextField('product', 'Product*', giftOptions[optionIndex][itemIndex].product, '', true, handleGiftItemChange, setPutErrors, setGiftOptions, giftOptions, optionIndex, itemIndex, setLoadingImage)}
            </Box>
          </>
        }

        {/* Instructions after all fields filled in for item */}
        {giftOptions[optionIndex].length - 1 === itemIndex 
        && giftOptions[optionIndex][itemIndex].link.length > 1 
        && giftOptions[optionIndex][itemIndex].image.length > 1 
        && giftOptions[optionIndex][itemIndex].brand.length > 0 
        && giftOptions[optionIndex][itemIndex].product.length > 0
        &&
          <Box
            sx={{
              pt: 1, pb: 1,
              backgroundColor: (allGiftOptionElementsAdded() ? 'plum' : 'lightyellow'),
              borderTop: 1, borderBottom: 0,
              borderColor: 'lightgray',
              width: '100%',
              // height: '60px',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch',
            }}
          >
            <Typography
              textAlign={'left'}
              component="pre"
              sx={{
                width: '95%',
                pl: '5px', pr: '5px',
                fontSize: '14px',
              }}
            >
              {(allGiftOptionElementsAdded())
              &&
                `*Click '>' to proceed`.replace(/ {6} +/g, '') //the regex is for deleting the indentation spaces in the string literal
              }

              {giftOptions.length > 1
              &&
                <>
                  {allGiftOptionElementsAdded() ?
                    `
                    **OR click '+' on the righthand side to add a new gift Option`.replace(/ {6} +/g, '') //the regex is for deleting the indentation spaces in the string literal
                    :
                    `*Fill in remaining gift Options to proceed`.replace(/ {6} +/g, '') //the regex is for deleting the indentation spaces in the string literal
                  }
                </>
              }
              
              {
                `
                **OR click 'Add Another Item' below to include another item ${giftOptions.length > 1 ? 'in this Option' : 'in this gift'}`.replace(/ {6} +/g, '') //the regex is for deleting the indentation spaces in the string literal
              }

            </Typography>
          </Box>
        } 
      </Box>
    </>
  )
}

export default GiftItem