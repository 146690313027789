import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { noteTextField } from '../../../helpers/textfields'

import { dummyBox } from '../../../helpers/globalHelpers'

import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { ministepsHeight } from '../../../helpers/variableDefaults'

const GiftNote = (props) => {

  // Destructure Props
  const { 
    note, setNote, 
    width, miniStepsPackage, backArrow, forwardArrow, 
  } = props

  // Updates view whenever note updates
  useEffect(() => {

  }, [note])

  const handleSetNote = async (e) => {
    e.preventDefault()

    setNote(e.target.value)
  }

  return (
    <Box
      sx={{ 
        width: '100%',
        height: '100%',
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
      }}
    >
      {/* Dummy Box to get spacing right */}
      {dummyBox('0px')}

      {/* Title and Note TextField */}
      <Box 
        sx={{ 
          width: '100%', 
          maxWidth: '600px',
          height: `calc(100% - ${ministepsHeight})`,
          maxHeight: `calc(100% - ${ministepsHeight})`,
          overflow: 'auto',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >
        
        {/* Title */}
        <Typography sx={{ mb: 1, mt: 0 }}>
          <strong>Personalized Note</strong>
        </Typography>

        {/* Note TextField */} 
        {noteTextField(
          'note', 
          note, 
          10, 
          '(This will be read after the gift is unwrapped, max 1000 characters)', 
          handleSetNote
        )}

      </Box>
          
      {/* Mobile stepper and, if the screen is small, arrows */}
      {packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, true)}

    </Box>
  )
}

export default GiftNote