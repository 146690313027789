import Typography from '@mui/material/Typography'

// Converting numbers to corresponding months for formatting dates
const numberToWordMonth = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December', 
}

// User Profile Important Dates — Elements on Profile
export const userProfileImportantDates = (importantDates = {}) => {

  // Birthday components
  let birthYear
  let birthMonth
  let birthDay
  if (importantDates.birthday.visible) {
    birthYear = importantDates.birthday.date.substring(0, 4)
    birthMonth = numberToWordMonth[importantDates.birthday.date.substring(5, 7)]
    birthDay = importantDates.birthday.date.substring(8, 10)
  }

  // Anniversary components
  let anniversaryYear
  let anniversaryMonth
  let anniversaryDay
  if (importantDates.anniversary.visible) {
    anniversaryYear = importantDates.anniversary.date.substring(0, 4)
    anniversaryMonth = numberToWordMonth[importantDates.anniversary.date.substring(5, 7)]
    anniversaryDay = importantDates.anniversary.date.substring(8, 10)
  }

  return (
    <>
      {importantDates.birthday.visible &&
        <Typography key={'birthday'}>
          🎂 {birthMonth} {birthDay}, {birthYear}
        </Typography>
      }
      {importantDates.anniversary.visible &&
        <Typography key={'anniversary'}>
          💍 {anniversaryMonth} {anniversaryDay}, {anniversaryYear}
        </Typography>
      }
    </>
  )
}