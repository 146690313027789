// React
import React, { useState, useEffect } from 'react'

// mui
import Box from '@mui/material/Box'

import { securityCodeTextField } from '../../helpers/textfields'
import { seoPageTags } from '../../helpers/analytics'

// Password-Protecting the website
const SitePassword = (props) => {

  // Destructure Props
  const { setSitePasswordGuess } = props

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Password'
      )}
      
      {/* TextField Container */}
      <Box
        sx={{
          width: '100vw', height: '100vh',
          display: 'flex', flexDirection: 'row', justifyContent: 'center',
        }}
      >
        <Box  
          sx={{ 
            mt: 1,
            width: '100%', maxWidth: '300px',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          
          {/* Security Code Guess TextField */}
          {securityCodeTextField(
            'sitePasswordGuess',
            'Password',
            'off',
            true,
            setSitePasswordGuess
          )}
          
        </Box>
      </Box>
    </>
  )
}

export default SitePassword