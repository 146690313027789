// React
import React from 'react'

// Import spinner image
import spinner320 from '../assets/neatWrapSpinner320.gif'

// mui
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'


export const standardSpinner = () => {
  return (
    <Box
      sx={{ 
        width: '100%', height: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
      }}
    >

      {/* GIF of present wrapping */}
      <Box component='img' src={spinner320} alt="Spinner" className="spinner" sx={{ maxWidth: 250 }} />

    </Box>  
  )
}

// Circular Spinner
export const circularSpinner = () => {
  return (
    <Box
      sx={{ 
        width: '100%', height: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
      }}
    >

      {/* circular spinner animation */}
      <CircularProgress color="primary" />

    </Box>  
  )
}