
import axios from 'axios'
import { getTokenFromLocalStorage } from './storage.js'
import { urlToFile, makeSquareOptionImage } from './imageHandling.js'

import WrapPresent from '../components/canvas/WrapPresent'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { positionChangeWidthMd } from './variableDefaults'

// The WrapPresent element in the Package and Review parts of the wrap process
export const wrapCanvasElement = (screenWidth, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep) => {
  return (
    <Grid item xs={12} md={6}
      sx={{ 
        height: screenWidth < positionChangeWidthMd ? (miniStepsPackage === 1 || miniStepsPackage === 4 || activeBottomStep === 2) ? '28vh' : '40%' : '100%', width: screenWidth < positionChangeWidthMd ? '100%' : '40%', 
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
      }}
    >
      <WrapPresent boxType={boxType} wrapping={wrapping} ribbon={ribbon} envelope={envelope} cardText={cardText} environment={environment} miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}/>
    </Grid>
  )
}

// Color option elements in many of the package design views during the wrap process
export const colorOptionsElement = (elementString, colorArray, stateColor, handleColorSelect) => {
  return (
    <Box 
      sx={{ 
        pb: 1,
        width: '100%', maxWidth: '269px',
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'space-around', 
      }}
    >
      {colorArray.map((color, colorIndex) => (
        <Box key={colorIndex}
          component='div'
          className={stateColor === color ? `${elementString}-${color.slice(1)} color color-styled` : `${elementString}-${color.slice(1)} color` }
          name='color'
          onClick={(e) => handleColorSelect(e)}
          alt={color}
          sx={{ 
            backgroundColor: color, 
            cursor: 'pointer', 
            width: '40px', height: '40px', 
            borderRadius: '50%',
            mt: 1, mb: 0, mr: '2.5px', ml: '2.5px', 
          }}
        />
      ))}
    </Box>
  )
}

// Box type options during the wrap process
export const boxTypeOption = (name, className, screenWidth, clickHandler, srcGIF) => {
  return (
    <Box 
      sx={{ 
        width: screenWidth < positionChangeWidthMd ? 150 : 200, 
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
      }}
    >
      {/*  GIF */}
      <Box 
        component='img'
        className={className}
        onClick={(e) => clickHandler(e)}
        src={srcGIF} 
        alt={`${name}-box-gif`}
        sx={{ cursor: 'pointer', width: '90%', maxWidth: screenWidth < positionChangeWidthMd ? 130 : 200, boxShadow: 3, mt: 1, mb: 2 }} 
      />
    </Box>
  )
}

// Wrapping Option Elements during the wrap process
export const wrappingOptionElement = (name, className, clickHandler, srcGIF) => {
  return (
    <Box 
      sx={{ 
        width: '30%', maxWidth: '135px',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
      }}
    >
      {/* Gif */}
      <Box 
        component='img'
        name='type'
        className={className}
        onClick={(e) => clickHandler(e)}
        src={srcGIF} 
        alt={`${name}-unwrap`}
        sx={{ cursor: 'pointer', width: '90%', maxWidth: 125, boxShadow: 3, mt: 1, mb: 2 }} 
      />
    </Box>
  )
}

// Handles gift item input changes
export const handleGiftItemChange = async (e, setErrors, setGiftOptions, giftOptions, optionIndex, itemIndex, setLoadingImage) => {

  const { name, value } = e.target
  const originalLinkValue = giftOptions[optionIndex][itemIndex]['link']
  setErrors(false)

  const newArray = [ ...giftOptions ]
  newArray[optionIndex][itemIndex][name] = value

  if (name !== 'link') {
    setGiftOptions(newArray)
  }

  // Get metadata from url
  if (name === 'link' && (value.includes('http://') || value.includes('https://') || value === '' || value.length < originalLinkValue.length)) {
    
    if (value === '' || value.length < originalLinkValue.length) {
      newArray[optionIndex][itemIndex]['link'] = ''
      newArray[optionIndex][itemIndex]['image'] = ''
      newArray[optionIndex][itemIndex]['brand'] = ''
      newArray[optionIndex][itemIndex]['product'] = ''
    }
    setGiftOptions(newArray)

    if (value !== '' && newArray[optionIndex][itemIndex]['image'] === '') {
      setLoadingImage(true)
    }

    try {
      // const response = await axios.put(`/api/metadata/`, { urlString: value }, {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/metadata/`, { urlString: value }, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      const { title, image } = response.data

      if (image !== 'no image') {
        const metaDataArray = [ ...newArray ]
  
        // Image to blob then to square
        const fileFromImage = await urlToFile(image)

        // Image is cropped into a square and then set in giftOptions
        makeSquareOptionImage(fileFromImage, optionIndex, itemIndex, setGiftOptions, metaDataArray)
      }

      setLoadingImage(false)

    } catch (error) {
      console.log('error message ->', error)
      // console.log('no metadata available') 
      setLoadingImage(false)
    }
  } 
}

// Reset state to default questions for oneItem gift types
export const resetQuestionsOneItem = (setQuestions) => {
  setQuestions(
    [
      {
        q: 'No questions',
        checked: true,
      },
      {
        q: 'Will you actually use it, or would you prefer a similarly priced item?',
        checked: false,
      },
      {
        q: 'Which color do you like most?',
        checked: false,
      },
      {
        q: 'Which size will fit you best?',
        checked: false,
      },
      {
        q: 'What address should it be sent to?',
        checked: false,
      },
      {
        q: '',
        checked: false,
      }
    ]
  )
}

// Reset state to default questions for chooseOne gift types
export const resetQuestionsChooseOne = (setQuestions) => {
  setQuestions(
    [
      {
        q: 'Which gift do you choose?',
        checked: true,
      },
      {
        q: 'Will you actually use it, or would you prefer a similarly priced item?',
        checked: false,
      },
      {
        q: 'Which color do you like most?',
        checked: false,
      },
      {
        q: 'Which size will fit you best?',
        checked: false,
      },
      {
        q: 'What address should it be sent to?',
        checked: false,
      },
      {
        q: '',
        checked: false,
      }
    ]
  )
}

// Reset state to default gift options for oneItem gift types
export const resetGiftOptionsOneItem = (setGiftOptions) => {
  setGiftOptions(
    [
      [
        {
          link: '',
          image: '',
          brand: '',
          product: '',
        }
      ]
    ]
  )
}

// Reset state to default gift options for chooseOne gift types
export const resetGiftOptionsChooseOne = (setGiftOptions) => {
  setGiftOptions(
    [
      [
        {
          link: '',
          image: '',
          brand: '',
          product: '',
        }
      ],
      [
        {
          link: '',
          image: '',
          brand: '',
          product: '',
        }
      ]
    ]
  )
}