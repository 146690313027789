/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import profPicDefault from '../../assets/social-media-logo.png'

import { makeSpecificGift } from '../../helpers/sendSpecificGift'
import { makeOutsideGiftLink, ExpandMore } from '../../helpers/cardHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { cardWidth } from '../../helpers/variableDefaults'
import { viewImageInNewTabOverlay } from '../../helpers/typographies'
import { viewImageInNewTabIcon } from '../../helpers/buttons'

const GiftIdeasCard = (props) => {

  // Payload
  const payload = getPayload()

  // Navigate
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // Destructure Props
  const { giftIdea, setSendSpecificGift, wishlist, setWishlist } = props

  // Tracks if the description overlay above image should show
  // const [show, setShow] = useState(false)

  // Tracks whether the giftIdea is wishlisted
  const [isWishlisted, setIsWishlisted] = useState(userIsAuthenticated && payload && payload.hasOwnProperty('sub') ? giftIdea.wishlistedUsers.includes(payload.sub) : false)
  
  // Tracks the wishlist count for each gift idea
  const [wishlistCount, setWishlistCount] = useState(giftIdea.wishlistedUsers.length)

  // Updates view for items that were wishlisted or not wishlisted — removes correct card from UserProfile.js if star is pressed on the wishlist tab there
  useEffect(() => {

    // Set is wishlisted to whether or not the new giftIdea wishlist array includes the User's Id
    setIsWishlisted(userIsAuthenticated && payload && payload.hasOwnProperty('sub') ? giftIdea.wishlistedUsers.includes(payload.sub) : false)
  
  }, [giftIdea])

  // Add or Remove Item from Wishlist when star is pressed
  const handleToggleWishlist = async (e) => {
    e.preventDefault()

    if (userIsAuthenticated()) {
      // Run try/catch if user is authenticated
      
      try {
        // All wishlist database toggling happens on the backend, uploading the giftIdea as the form element is just filler here
        // await axios.put(`/api/giftIdeas/${giftIdea._id}/wishlist/`, giftIdea, {
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/giftIdeas/${giftIdea._id}/wishlist/`, giftIdea, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        // toggle wishlisted
        setIsWishlisted(!isWishlisted)

        // Increment/decrement the wishlist count
        if (!isWishlisted === false) {
          setWishlistCount(wishlistCount - 1)
        } else {
          setWishlistCount(wishlistCount + 1)
        }

        // Removes the item from the wishlist tab when the star is pressed in UserProfile.js
        // Works in tandem with the [giftIdea] useEffect above to update the view correctly
        if (urlPath.includes('/profile')) {
          const editedWishlist = [ ...wishlist ]
          if (editedWishlist.includes(giftIdea)) {
            editedWishlist.splice(editedWishlist.indexOf(giftIdea), 1)
          } else {
            editedWishlist.push(giftIdea)
          }
          setWishlist(editedWishlist)
        } 

      } catch (error) {
        console.log(error)
      }

    } else {
      // If not authenticated, navigate to login
      navigate('/login')
    }
    

  }

  // Creates a specificGift when send icon is pressed
  const handleSendGiftIdea = () => {

    // Create a Specific Gift
    makeSpecificGift(setSendSpecificGift, giftIdea.link, giftIdea.image, giftIdea.brand, giftIdea.subtitle)

    // Navigate to /sendgift
    navigate('/sendgift')
  }

  return (
    <Card align="left" 
      sx={{ 
        // backgroundColor: 'yellow',
        width: '100%', maxWidth: cardWidth, boxShadow: 3, 
      }}
    >
      
      {/* Part of card above image */}
      <CardHeader
        avatar={
          // Avatar image for brand
          <Avatar sx={{ boxShadow: 3, border: 0, borderColor: 'black' }} alt="gift-idea-prof-pic" src={giftIdea.profilePicture ? giftIdea.profilePicture : profPicDefault} />
        }
        title={
          // Brand name
          <Box sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <strong>{giftIdea.brand}</strong>
          </Box>
          
        }
        subheader= {
          // Promo code if promotion, subtitle if not promotion
          <Box sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <Typography 
              sx={{ 
                fontSize: '14px', 
              }}
            >
              {giftIdea.isPromotion ? <em>Promo Code: {giftIdea.promoCode}</em> : <em>{giftIdea.subtitle}</em> }
            </Typography>
          </Box>
        }
      />

      {/* Image and link */}
      <Box 
        // onMouseOver={() => setShow(true)}
        // onMouseOut={() => setShow(false)}
        sx={{ 
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >
        <CardMedia
          component="img"
          height={cardWidth}
          image={giftIdea.image}
          alt={giftIdea.subtitle}
          sx={{ 
            borderRadius: 0,
            display: 'inline-block',
          }}
        />
        {/* {show && 
          viewImageInNewTabOverlay(cardWidth, cardWidth)
        } */}

        {viewImageInNewTabIcon(giftIdea.link, '85%', '25px')}
        
      </Box>

      {/* Gift Idea Caption */}
      <CardContent>
        <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}>
          {giftIdea.caption}
        </Typography>
      </CardContent>

      {/* Card Buttons */}
      <CardActions disableSpacing>

        {/* Send IconButton */}
        <IconButton onClick={handleSendGiftIdea} aria-label="share">
          <SendIcon />
        </IconButton>
        
        {/* Star IconButton */}
        {userIsAuthenticated() && 
          <IconButton onClick={handleToggleWishlist} aria-label="add to favorites">
            <StarIcon color={ isWishlisted ? 'primary' : '' }/>
          </IconButton>
        }

        {/* Wishlist count on giftIdeas page but not on UserProfile */}
        {/* {(urlPath.includes('/giftideas') || urlPath === '/' ) && wishlistCount > 0 &&
          <Typography>
            {wishlistCount}
          </Typography>
        } */}

        {/* Expand More */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>

      </CardActions>

      {/* Longer Description — Part that expands when Expand More is Pressed */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Lato',
              fontSize: '16px',
            }}
          >
            {giftIdea.longDescription}
          </Typography>
        </CardContent>
      </Collapse>

    </Card>
  )
}

export default GiftIdeasCard