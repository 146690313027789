import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { colorOptionsElement, wrapCanvasElement } from '../../../helpers/wrapHelpers'
import { packageViewMobileStepperAndArrows } from '../../../helpers/mobileSteppers'
import { dummyBox, removeColorStyling } from '../../../helpers/globalHelpers'
import { positionChangeWidthMd, ministepsHeight, envelopeColors } from '../../../helpers/variableDefaults'

import useWindowDimensions from '../../../helpers/windowDimensions'

const Envelope = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    envelope, setEnvelope, setCardText, backArrow, forwardArrow,
    wrapping, ribbon, boxType, cardText, environment,
    miniStepsPackage, activeBottomStep,
  } = props
  
  // View updates when envelope changes
  useEffect(() => {

  }, [envelope])

  // Handle envelope option selection
  const handleSetEnvelope = async (e) => {
    e.preventDefault()

    // set the value with the event target classList
    const value = e.target.classList[0].slice(9)

    // Remove styling from non-selected colors, and add styling to newly selected color if different from previous selection
    removeColorStyling(envelopeColors, value, 'envelope', envelope, e)

    // Set Envelope Color
    setEnvelope(`#${value}`)

    // Set the card text color default to the same as the envelope color
    setCardText({ ...cardText, color: `#${value}` })
  }

  return (
    <>
      <Grid container spacing={0} 
        sx={{ 
          height: '100%',
        }}
      >
        {/* Wrap Present Canvas */}
        {wrapCanvasElement(width, boxType, wrapping, ribbon, envelope, cardText, environment, miniStepsPackage, activeBottomStep)}

        {/* Options */}
        <Grid item xs={12} md={6} 
          sx={{ 
            boxShadow: 0, 
            height: width < positionChangeWidthMd ? '60%' : '100%', maxHeight: width < positionChangeWidthMd ? '60%' : '100%',
            width: width < positionChangeWidthMd ? '100%' : '50%', maxWidth: width < positionChangeWidthMd ? '100%' : '50%', 
            // overflow: 'auto',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              // -webkit-appearance: none,
              WebkitAppearance: 'none',
              width: '7px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
          }}
        >
          {/* Dummy Box to get spacing right */}
          {dummyBox('0px')}

          {/* Title and Options */}
          <Box 
            sx={{ 
              height: `calc(100% - ${ministepsHeight})`,
              maxHeight: `calc(100% - ${ministepsHeight})`,
              overflow: 'auto',
              width: '100%', 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            {/* Title */}
            <Typography sx={{ mb: 2, mt: 2 }}>
              <strong>Envelope</strong>
            </Typography>

            {/* Colors */}
            {colorOptionsElement('envelope', envelopeColors, envelope, handleSetEnvelope)}
            
          </Box>
          
          {/* Mobile stepper and, if the screen is small, arrows */}
          {packageViewMobileStepperAndArrows(width, miniStepsPackage, backArrow, forwardArrow, true)}
          
        </Grid>
      </Grid>  
    </>
  )
}

export default Envelope