/* eslint-disable react/no-unknown-property */
import React, { useState, Suspense, lazy } from 'react'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { pointLights, cameraPositionWrap, textBeneathGiftWrap } from '../../helpers/canvasElements'
import { canvasBgDefaultColorWrap, positionChangeWidthMd, positionChangeWidthSm } from '../../helpers/variableDefaults'
import useWindowDimensions from '../../helpers/windowDimensions'

// Models — Lazy Loading important so that it shows a 'loading...' indicator
const BoxNoPaper = lazy(() => import('../models/wrap/BoxNoPaper'))
const CardboardBox = lazy(() => import('../models/wrap/CardboardBox'))
const Confetti300 = lazy(() => import('../models/wrap/Confetti300'))
const EnvelopeAndCard = lazy(() => import('../models/wrap/EnvelopeAndCard'))
const MessageOnCard = lazy(() => import('../models/wrap/MessageOnCard'))
const MessyWrapping = lazy(() => import('../models/wrap/MessyWrapping'))
const NeatWrapping = lazy(() => import('../models/wrap/NeatWrapping'))
const Ribbon = lazy(() => import('../models/wrap/Ribbon'))
const SwipewrapCloneWrapping = lazy(() => import('../models/wrap/SwipewrapCloneWrapping'))

// Loading indicator
import Loader from '../common/Loader'

// Wrap Present — Used in the SendGift Process
function WrapPresent(props) {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { 
    boxType, wrapping, ribbon, envelope, cardText, environment, 
    miniStepsPackage, activeBottomStep, 
  } = props
  
  // Animation Index
  const [animationIndex, setAnimationIndex] = useState(0)

  // URL Path
  const urlPath = window.location.pathname


  return (
    <div style = {{ 
      height: '100%', width: '100%', 
      backgroundColor: environment && environment.backgroundColor ? environment.backgroundColor : canvasBgDefaultColorWrap, 
    }}>
      {/* 3D Canvas */}
      <Canvas>
        {/* Scene Lighting */}
        {pointLights()}

        {/* 3D Models Container */}
        <Suspense 
          fallback={<Loader />}
        >

          {/* Box */}
          {miniStepsPackage > -1 && boxType 
            && boxType === 'cardboard' &&
            <CardboardBox boxType={boxType} miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep} animationIndex={animationIndex} setAnimationIndex={setAnimationIndex} />
          }
          {miniStepsPackage > -1 && boxType 
            && boxType === 'white' &&
            <BoxNoPaper boxType={boxType} miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep} animationIndex={animationIndex} setAnimationIndex={setAnimationIndex}/>
          }

          {/* Wrapping */}
          {miniStepsPackage > 0 && wrapping && wrapping.type === 'neat' &&
            <NeatWrapping wrappingColor={wrapping.color} miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep} animationIndex={animationIndex} setAnimationIndex={setAnimationIndex}/>
          }
          {miniStepsPackage > 0 && wrapping && wrapping.type === 'messy' &&
            <MessyWrapping wrappingColor={wrapping.color} miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep} animationIndex={animationIndex} setAnimationIndex={setAnimationIndex}/>
          }
          {miniStepsPackage > 0 && wrapping && wrapping.type === 'swipe' &&
            <SwipewrapCloneWrapping wrappingColor={wrapping.color} miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep} animationIndex={animationIndex} setAnimationIndex={setAnimationIndex}/>
          }

          {/* Ribbon */}
          {miniStepsPackage > 1 && ribbon &&
            <Ribbon ribbonColor={ribbon} activeBottomStep={activeBottomStep} animationIndex={animationIndex} setAnimationIndex={setAnimationIndex}/>
          }
          
          {/* Envelope */}
          {miniStepsPackage > 2 && envelope &&
            <EnvelopeAndCard envelopeColor={envelope} activeBottomStep={activeBottomStep} animationIndex={animationIndex} setAnimationIndex={setAnimationIndex}/>
          }

          {/* Card */}
          {(
            (miniStepsPackage > 3 && cardText && activeBottomStep === 1)
            || (animationIndex > 0 && activeBottomStep === 2)
          ) &&
            <MessageOnCard cardText={cardText} />
          }
          
          {/* Environment */}
          {/* Confetti */}
          {(
            (activeBottomStep === 1 && miniStepsPackage > 4 && environment && environment.confettiColor)
            || (activeBottomStep === 2 && animationIndex > 3)
          )
          &&
            <Confetti300 confettiColor={environment.confettiColor} miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep} animationIndex={animationIndex}/>
          }
          
          {/* Text beneath gift */}
          {(
            (activeBottomStep === 2)
            || (boxType && miniStepsPackage === 0 && activeBottomStep === 1) 
            || (wrapping.type && miniStepsPackage === 1 && activeBottomStep === 1)
          ) && 
            textBeneathGiftWrap(activeBottomStep, animationIndex, width)
          }
          
        </Suspense>
          
        {/* Camera Positioning */}
        {cameraPositionWrap(width, miniStepsPackage)}
        
        {/* Orbit Controls */}
        <OrbitControls 
          enableRotate={
            width < positionChangeWidthMd ? false : true
          } 
          enableZoom={false} 
          enablePan={false} 
        />
            
      </Canvas>
      
    </div>
  )
}

export default WrapPresent