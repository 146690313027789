/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'

import { useOrNot } from '../../../helpers/variableDefaults'
import { feedbackTextField } from '../../../helpers/textfields'
import { selectAnswer } from '../../../helpers/selects'

export const questionFeedback = (feedback, gift, setGift, width ) => {
  // console.log('width ->', width)

  // Execute User responses to questions
  const handleSetAnswer = (e, questionIndex) => {

    // Destructure feeback object
    const feedbackToEdit = { ...feedback }

    // Set the answer to the specified question equal to the event target value
    feedbackToEdit.qAndA[questionIndex].a = e.target.value

    // Reset somethingElseLink if User flips back to 'Will use'
    if (e.target.value === 'Will use' && feedbackToEdit.hasOwnProperty('somethingElseLink')) {
      delete feedbackToEdit.somethingElseLink
    }

    // set the Gift object with the updated feedback
    setGift({ ...gift, feedback: feedbackToEdit })
  }

  // Execute appending somethingElseLink onto the feedback object
  const handleSomethingElse = (e) => {

    // Destructure feeback object
    const feedbackToEdit = { ...feedback }

    // create a somethingElseLink key on the feedback object and set its value to the event target value
    feedbackToEdit.somethingElseLink = e.target.value

    // set the Gift object with the updated feedback
    setGift({ ...gift, feedback: feedbackToEdit })

  }

  // Bolded answer Typography if the gift was already opened and the responses were already sent
  const wasOpenedElement = (questionIndex, property) => {
    return (
      <Typography>
        {property === 'somethingElseLink' ?
          <strong>{feedback.hasOwnProperty(property) ? feedback[property] : 'No Response Given.'}</strong>
          :
          <strong>{feedback.qAndA[questionIndex].hasOwnProperty(property) ? feedback.qAndA[questionIndex][property] : 'No Response Given.'}</strong>
        }
      </Typography>
    )
  }
  
  return (
    <Box 
      sx={{
        // backgroundColor: 'yellow',
        width: '100%',
        display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
    >
      {/* Title */}
      <Box 
        sx={{
          // backgroundColor: 'green',
          width: '100%',
          display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
      >
        <Typography id='transition-modal-title' variant='h6' component='h2' textAlign={'center'}
          sx={{ width: '100%', mt: 3, mb: 0, lineHeight: '25px' }}
        >
          <strong>Questions from Sender</strong>
        </Typography>
        <Typography textAlign={width < 400 ? 'left' : 'center'} component="pre"
          sx={{ width: '75%', maxWidth: '450px', mt: 0, mb: 0, fontStyle: 'italic', color: 'purple' }}
        >
          *To minimize likelihood of returns (responding optional)
          {/* <br/>**Responding is optional (but recommended) */}
        </Typography>

      </Box>
      
      {/* Questions */}
      {/* {questionFeedback(feedback, gift, setGift)} */}

      <Box sx={{
        // backgroundColor: 'blue',
        width: '95%', maxWidth: '600px', px: 1, pb: 4,
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'flex-start',
      }}>  

        {/* Map of questions and answer elements */}
        {feedback.qAndA.map((question, questionIndex) => (
          question.q !== 'No questions' && question.q !== 'Which gift do you choose?' &&
            <Grid container key={questionIndex} sx={{
              mt: 2,
              p: 1,
              width: '100%', maxWidth: '600px',
              borderRadius: '5px',
              boxShadow: 3,
              backgroundColor: 'whitesmoke',
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            }}>

              {/* Question */}
              <Grid item xs={12} md={6} sx={{
                mt: 1, mb: 1.5,
              }}>
                {question.q}
              </Grid>


              {/* Answer Elements */}

              {/* Will you actually use it */}
              {question.q === 'Will you actually use it, or would you prefer a similarly priced item?' &&
                <Grid item key={questionIndex + 1} xs={12} md={5.8} sx={{
                  mt: 0, mb: 0.5,
                }}>
                  {selectAnswer(
                    'useOrNot', 
                    gift.wasOpened, 
                    feedback.qAndA[questionIndex].hasOwnProperty('a') ? feedback.qAndA[questionIndex].a : '', 
                    useOrNot.map(answer => <MenuItem value={answer} key={answer}>{answer}</MenuItem>), 
                    handleSetAnswer, 
                    questionIndex
                  )}
                </Grid>
              }
              
              {/* Prefer Something Else Link */}
              {question.q === 'Will you actually use it, or would you prefer a similarly priced item?' 
                && question.hasOwnProperty('a')
                && question.a === 'Prefer something else'
                &&
                <Grid item key={questionIndex + 2} xs={12} sx={{
                  mt: 0, mb: 1.5,
                }}>
                  {gift.wasOpened ?
                    wasOpenedElement(questionIndex, 'somethingElseLink')
                    : 
                    feedbackTextField('somethingElseLink', questionIndex, 1, 1, 'www.yourgiftlink.com', feedback, handleSomethingElse, handleSetAnswer)
                    
                  }
                </Grid>
              }

              {/* Which Color */}
              {question.q === 'Which color do you like most?' &&
                <Grid item key={questionIndex + 1} xs={12} md={5.8} sx={{
                }}>
                  {gift.wasOpened ?
                    wasOpenedElement(questionIndex, 'a')
                    :
                    feedbackTextField('colorChoice', questionIndex, 1, 1, 'blue, red, purple, etc.', feedback, handleSomethingElse, handleSetAnswer)
                  }
                </Grid>
              }

              {/* Which Size */}
              {question.q === 'Which size will fit you best?' &&
                <Grid item key={questionIndex + 1} xs={12} md={5.8}>
                  {gift.wasOpened ?
                    wasOpenedElement(questionIndex, 'a')
                    :
                    feedbackTextField('sizeChoice', questionIndex, 1, 1, 'XS, S, M, L, XL...', feedback, handleSomethingElse, handleSetAnswer)
                  }
                </Grid>
              }

              {/* What Address */}
              {question.q === 'What address should it be sent to?' &&
                <Grid item key={questionIndex + 1} xs={12} md={5.8}>
                  {gift.wasOpened ?
                    wasOpenedElement(questionIndex, 'a')
                    :
                    feedbackTextField('address', questionIndex, 3, 5, '123 My Address Lane...', feedback, handleSomethingElse, handleSetAnswer)
                  }
                </Grid>
              }

              {/* Custom Question Answers */}
              {question.q !== 'What address should it be sent to?' 
                && question.q !== 'Which size will fit you best?'
                && question.q !== 'Which color do you like most?'
                && question.q !== 'Will you actually use it, or would you prefer a similarly priced item?'
                && question.q !== 'No questions'
                && question.q !== 'Which gift do you choose?'
                &&
                <Grid item key={questionIndex + 1} xs={12} md={5.8}>
                  {gift.wasOpened ?
                    wasOpenedElement(questionIndex, 'a')
                    :
                    feedbackTextField('custom', questionIndex, 3, 3, '...', feedback, handleSomethingElse, handleSetAnswer)
                  }
                </Grid>
              }
            </Grid>
        ))}
      </Box>

    </Box>
  )
}