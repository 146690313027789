/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { revealGift } from './responding/revealGift'
import { questionFeedback } from './responding/questionFeedback'
import { emailResponses } from './responding/emailResponses'
import { responsesSent } from './responding/responsesSent'

import { userIsAuthenticated, getPayload, getTokenFromLocalStorage, getEmailFromLocalStorage, getUsernameFromLocalStorage, getProfPicFromLocalStorage } from '../../helpers/storage.js'
import { inProcessAllAuth, createGoogleSignInButton } from '../../helpers/inProcessLoginRegister.js'
import { standardErrorContainer } from '../../helpers/errors'

import { dummyBox, validEmail } from '../../helpers/globalHelpers'
import { standardButton } from '../../helpers/buttons'
import { reloadView } from '../../helpers/globalHelpers'
import { standardSpinner } from '../../helpers/spinners'
import { customAnalyticsEvent } from '../../helpers/analytics'

import useWindowDimensions from '../../helpers/windowDimensions'

const ShowGift = (props) => {

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Navigate
  const navigate = useNavigate()
  const urlPath = window.location.pathname

  // Payload
  const payload = getPayload()

  // Destructure Props
  const { finishedUnwrap, gift, setGift } = props
  const { feedback, note, options, type } = gift

  // In process Auth States
  const [authenticated, setAuthenticated] = useState(userIsAuthenticated())

  // Email/Password Login
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
    loginMethod: 'emailLogin',
    acceptedTOS: true,
  })
  const [ loginErrors, setLoginErrors ] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)

  // Email/Password Register
  const [registerForm, setRegisterForm] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    loginMethod: 'emailRegister',
    acceptedTOS: true,
  })
  const [ registerErrors, setRegisterErrors ] = useState(false)
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [acceptedTOS, setAcceptedTOS] = useState(false)

  // Switching between Login and Register
  const [loginViewIndex, setLoginViewIndex] = useState(0)

  // Google Login
  const [loadGoogleButton, setLoadGoogleButton] = useState(0) // Dummy state for the Google Sign-In Button to load properly in UseEffect
  const [ loadingGoogle, setLoadingGoogle ] = useState(false)
  const [ googleLoginErrors, setGoogleLoginErrors ] = useState(false)

  // Facebook Login
  const [ loadingFacebook, setLoadingFacebook ] = useState(false)
  const [ facebookLoginErrors, setFacebookLoginErrors ] = useState(false)

  // Continue as Guest Indicator
  const [ continueAsGuest, setContinueAsGuest ] = useState(false)

  // Only to make the inProcessAuth consistent for use across multiple views, not used in this view
  const [ sendViewIndex, setSendViewIndex ] = useState(0)

  // Mobile Stepper
  const skipFeedback = (feedback.qAndA.length === 1 && (feedback.qAndA[0].q === 'No questions' || feedback.qAndA[0].q ===  'Which gift do you choose?')) ? true : false
  const [sentResponses, setSentResponses] = useState(0)

  // Gift Option chosen, important for chooseOne gifts
  const [optionChosen, setOptionChosen] = useState(gift.wasOpened && gift.type === 'chooseOne' ? gift.feedback.optionChosen : gift.type === 'oneItem' ? 0 : -1)
  
  // non-login-related Loading and Errors
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const originalGift = { ...gift }
  const [originalSenderEmail, setOriginalSenderEmail] = useState(originalGift.senderEmail)
  const [originalRecipientEmail, setOriginalRecipientEmail] = useState(originalGift.recipientEmail)

  // For Analytics
  useEffect(() => {

    // Custom Analytics
    customAnalyticsEvent(
      urlPath === '/' ? 'home_reveal_gift' : urlPath === '/example' ? 'example_reveal_gift' : authenticated ? 'unwrap_reveal_gift' : 'unwrap_login', 
      urlPath === '/' ? 'home_reveal_gift' : urlPath === '/example' ? 'example_reveal_gift' : authenticated ? 'unwrap_reveal_gift' : 'unwrap_login',
      urlPath === '/' ? 'homepage_unwrap' : urlPath === '/example' ? 'example_unwrap' : 'unwrap_process', 
      urlPath === '/' ? 'home_reveal_gift' : urlPath === '/example' ? 'example_reveal_gift' : authenticated ? 'unwrap_reveal_gift' : 'unwrap_login',
      urlPath === '/' ? 'Home' : urlPath === '/example' ? 'Example' : 'Show Gift'
    )

  }, [authenticated])

  // Load Google Sign-In Button
  useEffect(() => {

    // Necessary to change dummy state so that the gsi button will load
    if (loadGoogleButton < 1 && urlPath.includes('unwrap')) {
      setLoadGoogleButton(1)
    }

    // Create the GSI Button
    if (urlPath.includes('unwrap')) {
      createGoogleSignInButton('unwrap', setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)
    }

  }, [loadGoogleButton])

  // Automatically send gift unwrapped emails to sender and recipient
  const sendUnwrappedEmails = (giftToUpload, updatedQAndA) => {
    
    // Email addresses for sender and recipient
    const senderEmailAddress = giftToUpload.senderEmail
    const recipientEmailAddress = giftToUpload.recipientEmail

    // Send auto emails if the email addresses exist
    if (senderEmailAddress || recipientEmailAddress) {

      // Getting question feedback into html format for the auto email
      let feedbackHTML = ''
      if (updatedQAndA[0].q === 'No questions') {
        feedbackHTML = `<table><em>No questions</em></table>`
      } else {
        for (let i = 0; i < updatedQAndA.length; i++) {
          if (updatedQAndA[i].q === 'Which gift do you choose?') {
            let giftChosen = ''
            for (let x = 0; x < giftToUpload.options[giftToUpload.feedback.optionChosen].length; x++) {
              if (x === 0) {
                // console.log('inside x = 0')
                giftChosen = `<a target='#' href=${giftToUpload.options[giftToUpload.feedback.optionChosen][x].link}>${giftToUpload.options[giftToUpload.feedback.optionChosen][x].brand} —  ${giftToUpload.options[giftToUpload.feedback.optionChosen][x].product}</a>`
              } else {
                // console.log('inside x is not 0')
                giftChosen = giftChosen + ` + <a target='#' href=${giftToUpload.options[giftToUpload.feedback.optionChosen][x].link}>${giftToUpload.options[giftToUpload.feedback.optionChosen][x].brand} —  ${giftToUpload.options[giftToUpload.feedback.optionChosen][x].product}</a>`
              }
            }
            feedbackHTML = feedbackHTML + `<table><em>${updatedQAndA[i].q}</em> ${giftChosen}</table>`
          } else if (updatedQAndA[i].q === 'Will you actually use it, or would you prefer a similarly priced item?' && giftToUpload.feedback.hasOwnProperty('somethingElseLink')) {
            feedbackHTML = feedbackHTML + `<table><em>${updatedQAndA[i].q}</em> <span style="color:purple"><strong>${updatedQAndA[i].a} — ${giftToUpload.feedback.somethingElseLink}</strong></span></table>`
          } else {
            feedbackHTML = feedbackHTML + `<table><em>${updatedQAndA[i].q}</em> <span style="color:purple"><strong>${updatedQAndA[i].a}</strong></span></table>`
          }
        }
      }
      
      // Creating the autoEmailObject to upload to Mailgun
      const autoEmailObject = {
        senderName: giftToUpload.package.cardText.senderName,
        recipientName: giftToUpload.package.cardText.recipientName,
        senderEmailAddress: senderEmailAddress ? senderEmailAddress : 'No Email',
        recipientEmailAddress: recipientEmailAddress ? recipientEmailAddress : 'No Email',
        feedbackHTML: feedbackHTML,
        thankYouNote: giftToUpload.feedback.hasOwnProperty('thankYouNote') ? giftToUpload.feedback.thankYouNote.length > 0 ? giftToUpload.feedback.thankYouNote : 'Thank you for the gift.' : 'Thank you for the gift.',
      }

      // Upload auto email object try/catch
      try {
        // PUT the autoEmailObject to Mailgun
        // axios.put(`/api/autoEmail/`, autoEmailObject, {
        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/autoEmail/`, autoEmailObject, {})
      } catch (err) {
        console.log('err ->', err)
        console.log('auto email failed')
      }
    }
  }

  // Execute Send Responses Button
  const handleSendResponses = async () => {

    if (urlPath === '/' || urlPath === '/example') {

      // If on homescreen, reload view
      reloadView()

    } else if (gift.wasOpened || (userIsAuthenticated() && payload.sub && gift.sender === payload.sub)) {
      
      // If gift was opened or opener is the sender, go to the responses sent page without sending anything to the server
      setSentResponses(1)

    } else {
      // Send responses to server

      // setLoading to true
      setLoading(true)

      // Creating and modifying the upload object
      const giftToUpload = { ...gift }
      giftToUpload.feedback.thankYouNote = giftToUpload.feedback.hasOwnProperty('thankYouNote') ? giftToUpload.feedback.thankYouNote.length > 0 ? giftToUpload.feedback.thankYouNote : 'No thank you note sent' : 'No thank you note sent'
      giftToUpload.wasOpened = true
      if (giftToUpload.type === 'oneItem') {
        setOptionChosen(0)
        giftToUpload.feedback.optionChosen = 0
      }
      const updatedQAndA = giftToUpload.feedback.qAndA.map((question, questionIndex) => {
        return question.hasOwnProperty('a') ? question : (question.q === 'No questions' || question.q === 'Which gift do you choose?') ? question : { ...question, a: 'No Response Given.' }
      })
      giftToUpload.feedback.qAndA = updatedQAndA

      // Add recipient info to here instead of on the backend, so that users can open presents as "guest" and not have to go through secure route
      if (userIsAuthenticated()) {
        giftToUpload.recipient = payload.sub
        giftToUpload.recipientUsername = getUsernameFromLocalStorage()
        giftToUpload.recipientEmail = getEmailFromLocalStorage()
        giftToUpload.recipientProfilePicture = getProfPicFromLocalStorage()
      } else if (
        (giftToUpload.recipientEmail === 'No Email' || giftToUpload.recipientEmail === '' || !(validEmail(giftToUpload.recipientEmail))) 
        && (validEmail(originalRecipientEmail))
      ) {
        giftToUpload.recipientEmail = originalRecipientEmail
      }


      // Send Responses
      try {
        // await axios.put(`/api/gifts/${giftToUpload._id}`, giftToUpload, {
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/gifts/${giftToUpload._id}`, giftToUpload, {})

        // Send Unwrapped email
        sendUnwrappedEmails(giftToUpload, updatedQAndA)

        // Set loading to false
        setLoading(false)

        // Go to responsesSent view
        setSentResponses(1)

        // Custom Analytics — Only for unwrapping
        if (urlPath.includes('unwrap')) {
          customAnalyticsEvent(
            'unwrap_responses_sent', 
            'unwrap_responses_sent',  
            'unwrap_process', 
            'unwrap_responses_sent',  
            'Show Gift'
          )
        }

      } catch (error) {
        console.log(error)

        // error message sending responses
        setErrors(true)
        setLoading(false)
      }
    }
  } 

  return (
    <>

      {/* Show Gift Body */}
      <Box sx={{ 
        width: '100vw', height: '100vh',
        display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'stretch',
      }}>
        <Modal
          disableAutoFocus={true}
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={finishedUnwrap ? true : false}
          closeAfterTransition
          BackdropProps={{
            timeout: 3500,
          }}
          keepMounted
          sx={{ 
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
          }}
        >
          <Fade in={finishedUnwrap ? true : false}>
            <Paper
              sx={{
                width: '85vw',
                height: '85vh',
                maxHeight: '85vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
              }}
            >
              {
                loading ?
                  standardSpinner()
                  :
                  // {/* If user is authenticated or it is the homepage, reveal the gift */}
                  (authenticated || urlPath === '/' || urlPath === '/example' ) ?
                    <>
                      {/* Main Elements */}
                      <Box 
                        id='scroller' 
                        sx={{
                          pb: 2,
                          height: '100%',
                          maxHeight: '100%',
                          // overflow: 'auto',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            WebkitAppearance: 'none',
                            width: '8px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: '4px',
                            backgroundColor: 'rgba(0, 0, 0, .35)',
                            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
                          },
                          width: '100%',
                          mb: 0, mt: 0, 
                          display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
                        }}
                      >
                        
                        {/*'X' button in example.js */}
                        {urlPath === '/example' && 
                          <Box 
                            onClick={reloadView} 
                            sx={{
                              width: '100%',
                              display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
                            }}
                          >
                            <IconButton 
                              sx={{ 
                                width: '30px', height: '30px', 
                                mt: 2, mr: 2, 
                                border: 2, borderColor: 'white', boxShadow: 3, 
                              }} 
                            >
                              <CloseOutlinedIcon 
                                fontSize="small" 
                                color='primary' 
                                sx={{  }} 
                              />
                            </IconButton>
                          </Box>
                        }

                        {/* Response Elements */}
                        {sentResponses === 0 &&
                          <>
                            {/* Gift(s) and note from sender */}
                            {revealGift(options, note, optionChosen, setOptionChosen, feedback, gift, setGift)}

                            
                            {/* Feedback Questions, if there are questions */}
                            {!skipFeedback && 
                              questionFeedback(feedback, gift, setGift, width)
                            }

                            {/* Email Addresses for Responses, thank you note, and done/send button */}
                            <Box 
                              sx={{
                                width: '100%',
                                display: 'flex', flexDirection: 'column', alignItems: 'center',
                              }}
                            >

                              <Box sx={{
                                // backgroundColor: 'yellow',
                                // maxHeight: '70%',
                                overflow: 'auto',
                                width: '95%',
                                display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'flex-start',
                              }}>    
                                
                                {/* Email addresses to send responses, and thank you note */}
                                {emailResponses(feedback, gift, setGift, width, originalSenderEmail)}

                                {/* Done/Send Button */}
                                <Box>
                                  {standardButton(
                                    urlPath === '/' || urlPath === '/example' || gift.wasOpened ? 'Done' : 'Send',
                                    'button',
                                    'contained',
                                    (
                                      (urlPath.includes('unwrap') && !validEmail(gift.senderEmail))
                                      || (gift.type === 'chooseOne' && optionChosen === -1)
                                    ),
                                    'secondary',
                                    1,
                                    1,
                                    4,
                                    '150px',
                                    '55px',
                                    handleSendResponses
                                  )}
                                </Box>

                                {/* Instructions */}
                                {(optionChosen === -1 && type === 'chooseOne') && 
                                  standardErrorContainer(
                                    '*Check the box underneath your favorite gift',
                                    2,
                                    0
                                  )
                                }

                                {(urlPath.includes('unwrap') && !validEmail(gift.senderEmail) && !gift.wasOpened) && 
                                  standardErrorContainer(
                                    '*Sender email address is required',
                                    2,
                                    0
                                  )
                                }

                                {/* Errors if there are errors */}
                                {errors && 
                                  standardErrorContainer(
                                    'Could not send responses. Check your Internet connection.',
                                    2,
                                    0
                                  )
                                }

                              </Box>

                            </Box>
                          </>
                        }

                        {/* Responses Sent */}
                        {sentResponses === 1 &&
                          responsesSent(navigate, width, continueAsGuest)
                        }

                      </Box>
                    </>
                    :
                    // If not authenticated, user sees inProcessAuth view
                    <Box
                      sx={{
                        mt: 4,
                        pb: 8,
                        maxHeight: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {(loadingGoogle || loadingFacebook) ?
                        standardSpinner()
                        : 
                        <>
                          {inProcessAllAuth(
                            'unwrap', 
                            loginViewIndex,
                            googleLoginErrors, facebookLoginErrors, setGoogleLoginErrors, setFacebookLoginErrors, setLoadingGoogle, setLoadingFacebook,
                            loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin,
                            registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS,
                            setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated, setContinueAsGuest
                          )}
                        </>
                      }
                    </Box>
              }
            </Paper>
          </Fade>
        </Modal>
      </Box>
    </>
  )
}

export default ShowGift