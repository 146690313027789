/* eslint-disable react/no-unknown-property */

import { PerspectiveCamera, Text } from '@react-three/drei'
import { angleToRadians } from './animations'
import { positionChangeHeightSm, positionChangeWidthSm, positionChangeWidthMd } from './variableDefaults'

// Point Lights
export const pointLights = () => {
  return (
    <>
      <pointLight position = {[10, 10, 10]} intensity = {.8} />
      <pointLight position = {[-10, -10, -10]} intensity = {.8} />
      <pointLight position={[10, 10, -10]} intensity={.5} />
      <pointLight position={[-10, -10, 10]} intensity={.5} />
    </>
  )
}

// Camera Position Unwrap Views
export const cameraPositionUnwrap = (screenWidth, screenHeight, urlPath) => {
  return (
    <>
      {((screenWidth < positionChangeWidthSm || screenHeight < positionChangeHeightSm) && (urlPath !== '/')) ?
        <PerspectiveCamera makeDefault position={[0, 7, 15]} />
        :
        ((screenWidth < positionChangeWidthSm || screenHeight < positionChangeHeightSm) && (urlPath === '/' || urlPath === '/example')) ?
          <PerspectiveCamera makeDefault position={[0, 3, 8]} />
          :
          <PerspectiveCamera makeDefault position={[0, 5, 8]} />
      }
    </>
  )
}

// Camera Position Wrap Views
// Chose not to merge this into one camera position because it's more nimble to be able to play with the positioning from here
export const cameraPositionWrap = (screenWidth, wrapStep) => {
  // console.log('wrap step ->', wrapStep)
  return (
    <>
      { wrapStep > 3 || screenWidth >= positionChangeWidthMd ?
        (wrapStep === 6 && screenWidth < positionChangeWidthMd) ?
          <PerspectiveCamera makeDefault position={[0, 5, 8]} />
          :
          <PerspectiveCamera makeDefault position={[0, 5, 10]} />
        :
        <PerspectiveCamera makeDefault position={[0, 3, 6]} />
      }
    </>
  )
}

// Text Beneath Gift for Unwrap Views
export const textBeneathGiftUnwrap = (screenWidth, screenHeight, animationIndex, urlPath) => {
  return (
    <Text
      scale={(urlPath === '/' || urlPath === '/example') ? (screenWidth < positionChangeWidthSm || screenHeight < positionChangeHeightSm) ? [5, 5, 5] : [4, 4, 4] : (screenWidth < positionChangeWidthSm || screenHeight < positionChangeHeightSm) ? [4, 4, 4] : [3, 3, 3]}
      color="black"
      anchorX="center" 
      anchorY="bottom" 
      position={screenHeight < positionChangeHeightSm ? (urlPath === '/' || urlPath === '/example') ? [0, -3.2, 0] : [0, -4, 0] : [0, -4, 0]}
      rotation={[angleToRadians(-30), 0, 0]}
    >
      { 
        animationIndex === 0 ? 
          'Click Envelope'
          :
          animationIndex === 1 ? 
            'Click Ribbon'
            :
            animationIndex === 2 ? 
              'Click Wrapping'
              :
              animationIndex === 3 ? 
                'Click Box'
                :
                ''
      }
    </Text>
  )
}

// Text Beneath Gift for Wrap Views
export const textBeneathGiftWrap = (activeBottomStep, animationIndex, screenWidth) => {
  return (
    <Text
      scale={(screenWidth < positionChangeWidthMd) ? [6, 6, 6] : [4, 4, 4]}
      color="black"
      anchorX="center" 
      anchorY="bottom" 
      position={screenWidth < positionChangeWidthMd && activeBottomStep === 1 ? [0, -3.6, 0] : [0, -4, 0]}
      rotation={[angleToRadians(-30), 0, 0]}
    >
      {activeBottomStep === 1 ? 
        'Click to play animation' 
        : 
        (activeBottomStep === 2 && animationIndex === 0) ? 
          'Click Envelope'
          :
          (activeBottomStep === 2 && animationIndex === 1) ? 
            'Click Ribbon'
            :
            (activeBottomStep === 2 && animationIndex === 2) ? 
              'Click Wrapping'
              :
              (activeBottomStep === 2 && animationIndex === 3) ? 
                'Click Box'
                :
                ''
      }
    </Text>
  )
}