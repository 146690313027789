import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'


// Selecting feedback responses during unwrapping
// No Labels
export const selectAnswer = (name, disabled, value, item, changeHandler, questionIndex) => {
  return (
    <FormControl required fullWidth>
      <Select
        disabled={disabled}
        size='small'
        id={name}
        name={name}
        value={value}
        onChange={(e) => changeHandler(e, questionIndex)}
      >
        {item}
      </Select>
    </FormControl>
  )
}

// Selecting cardText options during wrapping
// Yes Labels
export const selectElement = (elementName, elementValue, elementArrayOptions, changeHandler) => {
  const upperCaseElementName = elementName.charAt(0).toUpperCase() + elementName.slice(1)
  return (
    <Grid item xs={8} md={10}>
      <FormControl required fullWidth size="small">
        <InputLabel id={`${elementName}-label`}>{upperCaseElementName}</InputLabel>
        <Select
          labelId={`${elementName}-label`}
          id={`${elementName}`}
          name={`${elementName}`}
          value={elementValue}
          label={upperCaseElementName}
          onChange={(e) => changeHandler(e)}
          className={elementValue ? `${elementName} text-${elementValue}` : `${elementName} text-` }
        >
          {elementArrayOptions.map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
        </Select>
      </FormControl>
    </Grid>
  )
}