// React
import React, { useState } from 'react'

// Axios
import axios from 'axios'

import { getTokenFromLocalStorage } from './storage'

// AWS Requirement — Needs to be here to prevent weird errors
window.Buffer = window.Buffer || require('buffer').Buffer

// input url string, return file
export const urlToFile = async (urlString, fileName = 'image.jpg') => {
  var response = await fetch(urlString)
  var data = await response.blob()
  var metadata = {
    type: 'image/jpeg',
  }
  var file = new File([data], fileName, metadata)
  return file
}

// Input blob, return file
export const blobToFile = async (blobString, fileName = 'image.jpg') => {
  
  var metadata = {
    type: 'image/jpeg',
  }
  var file = new File([blobString], fileName, metadata)
  return file
}

// to call this function: await fileToDataURL(file) — because a Promise returned, 'await' is required
// Input file, return dataURL
export const fileToDataURL = async (file) => {

  return new Promise((resolve, reject) => {
    
    const reader = new FileReader()
    reader.onloadend = async () => {
      try {
        resolve(reader.result)
      } catch (err) {
        reject(err)
      }
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}

// Input Data URL, return blob
function dataURItoBlob(dataURI) {
  
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else
    byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

// Center and crop an image into a 600x600 square; 
// Set form with the data URL for the centered and cropped image
export const makeSquareImage = (file, formData, setFormData) => {

  // Give the selected image a blob URL
  const urlString = URL.createObjectURL(file)
  const img = new Image()
  img.src = urlString


  img.onload = async function() {
    const canvasResize = document.createElement('canvas')

    const imageResizeDimension = 600
    const aspectRatio = img.width / img.height

    if (aspectRatio < 1) {
      canvasResize.width = imageResizeDimension
      canvasResize.height = ~~(img.height * (imageResizeDimension / img.width))
    } else if (aspectRatio > 1) {
      canvasResize.width = ~~(img.width * (imageResizeDimension / img.height))
      canvasResize.height = imageResizeDimension
    } else {
      canvasResize.width = imageResizeDimension
      canvasResize.height = imageResizeDimension
    }
    const context = canvasResize.getContext('2d', { alpha: false })
    context.drawImage(img, 0, 0, canvasResize.width, canvasResize.height)

    // Convert the square image to a data url
    const resizedImageURL = canvasResize.toDataURL('image/jpg', 1)

    // Convert the data url to an image that can be cropped
    const resizedImageObjectURL = dataURItoBlob(resizedImageURL)
    const urlCropString = URL.createObjectURL(resizedImageObjectURL)
    const imgCrop = new Image()
    imgCrop.src = urlCropString

    // Crop the image onload
    imgCrop.onload = async function() {
  
      // crop image
      if (aspectRatio !== 1) {
        const canvasCrop = document.createElement('canvas')
        canvasCrop.height = imageResizeDimension
        canvasCrop.width = imageResizeDimension
        const contextCrop = canvasCrop.getContext('2d', { alpha: false })
  
        let startX
        let startY
        if (aspectRatio < 1) {
          startX = 0
          startY = (imgCrop.height - imageResizeDimension) / 2
        } else if (aspectRatio > 1) {
          startX = (imgCrop.width - imageResizeDimension) / 2
          startY = 0
        }
    
        contextCrop.drawImage(imgCrop, startX, startY, imageResizeDimension, imageResizeDimension, 0, 0, imageResizeDimension, imageResizeDimension)
  
        // Convert the square image to a data url
        const squareCropImageURL = canvasCrop.toDataURL('image/jpg', 1)
        
        // Set the selected form image index to the squared and centered image data URL
        setFormData({ ...formData, profilePicture: squareCropImageURL })
      } else {
        setFormData({ ...formData, profilePicture: resizedImageURL })
      }
    }
  }
}

// Center and crop a gift item image while wrapping a gift into a 600x600 square
export const makeSquareOptionImage = (file, optionIndex, itemIndex, setGiftOptions, giftOptions) => {

  // Give the selected image a blob URL
  const urlString = URL.createObjectURL(file)
  const img = new Image()
  img.src = urlString

  img.onload = async function() {
    const canvasResize = document.createElement('canvas')

    const imageResizeDimension = 600
    const aspectRatio = img.width / img.height
    // console.log('aspect ratio ->', aspectRatio)

    if (aspectRatio < 1) {
      canvasResize.width = imageResizeDimension
      canvasResize.height = ~~(img.height * (imageResizeDimension / img.width))
    } else if (aspectRatio > 1) {
      canvasResize.width = ~~(img.width * (imageResizeDimension / img.height))
      canvasResize.height = imageResizeDimension
    } else {
      canvasResize.width = imageResizeDimension
      canvasResize.height = imageResizeDimension
    }
    const context = canvasResize.getContext('2d', { alpha: false })
    context.drawImage(img, 0, 0, canvasResize.width, canvasResize.height)

    // Convert the square image to a data url
    const resizedImageURL = canvasResize.toDataURL('image/jpg', 1)

    // Convert the data url to an image that can be cropped
    const resizedImageObjectURL = dataURItoBlob(resizedImageURL)
    const urlCropString = URL.createObjectURL(resizedImageObjectURL)
    const imgCrop = new Image()
    imgCrop.src = urlCropString

    // Crop the image onload
    imgCrop.onload = async function() {
  
      // crop image
      if (aspectRatio !== 1) {
        const canvasCrop = document.createElement('canvas')
        canvasCrop.height = imageResizeDimension
        canvasCrop.width = imageResizeDimension
        const contextCrop = canvasCrop.getContext('2d', { alpha: false })
  
        let startX
        let startY
        if (aspectRatio < 1) {
          startX = 0
          startY = (imgCrop.height - imageResizeDimension) / 2
        } else if (aspectRatio > 1) {
          startX = (imgCrop.width - imageResizeDimension) / 2
          startY = 0
        }
    
        contextCrop.drawImage(imgCrop, startX, startY, imageResizeDimension, imageResizeDimension, 0, 0, imageResizeDimension, imageResizeDimension)
  
        // Convert the square image to a data url
        const squareCropImageURL = canvasCrop.toDataURL('image/jpg', 1)

        // Set the selected form image index to the squared and centered image data URL
        const newArray = [ ...giftOptions ]
        newArray[optionIndex][itemIndex]['image'] = squareCropImageURL

        setGiftOptions(newArray)
      } else {
        // Set the selected form image index to the squared and centered image data URL
        const newArray = [ ...giftOptions ]
        newArray[optionIndex][itemIndex]['image'] = resizedImageURL

        setGiftOptions(newArray)
      }
    }
  }
}

// Center and crop an image received from Facebook or Google Login into a 600x600 square
// To call, you must use 'await' because what's being returned is a Promise
// Not used at the moment but might be used in the future for uploading Facebook and Google login images to AWS bucket
export const makeSquareLoginImage = (file) => {

  return new Promise((resolve, reject) => {

    // Give the selected image a blob URL
    const urlString = URL.createObjectURL(file)
    const img = new Image()
    img.src = urlString
  
  
    img.onload = async function() {
      const canvasResize = document.createElement('canvas')
  
      const imageResizeDimension = 600
      const aspectRatio = img.width / img.height
  
      if (aspectRatio < 1) {
        canvasResize.width = imageResizeDimension
        canvasResize.height = ~~(img.height * (imageResizeDimension / img.width))
      } else if (aspectRatio > 1) {
        canvasResize.width = ~~(img.width * (imageResizeDimension / img.height))
        canvasResize.height = imageResizeDimension
      } else {
        canvasResize.width = imageResizeDimension
        canvasResize.height = imageResizeDimension
      }
      const context = canvasResize.getContext('2d', { alpha: false })
      context.drawImage(img, 0, 0, canvasResize.width, canvasResize.height)
  
      // Convert the square image to a data url
      const resizedImageURL = canvasResize.toDataURL('image/jpg', 1)
  
      // Convert the data url to an image that can be cropped
      const resizedImageObjectURL = dataURItoBlob(resizedImageURL)
      const urlCropString = URL.createObjectURL(resizedImageObjectURL)
      const imgCrop = new Image()
      imgCrop.src = urlCropString
  
      // Crop the image onload
      imgCrop.onload = async function() {
    
        // crop image
        if (aspectRatio !== 1) {
          const canvasCrop = document.createElement('canvas')
          canvasCrop.height = imageResizeDimension
          canvasCrop.width = imageResizeDimension
          const contextCrop = canvasCrop.getContext('2d', { alpha: false })
    
          let startX
          let startY
          if (aspectRatio < 1) {
            startX = 0
            startY = (imgCrop.height - imageResizeDimension) / 2
          } else if (aspectRatio > 1) {
            startX = (imgCrop.width - imageResizeDimension) / 2
            startY = 0
          }
      
          contextCrop.drawImage(imgCrop, startX, startY, imageResizeDimension, imageResizeDimension, 0, 0, imageResizeDimension, imageResizeDimension)
    
          // Convert the square image to a data url
          const squareCropImageURL = canvasCrop.toDataURL('image/jpg', 1)
          
          // Set the selected form image index to the squared and centered image data URL
          resolve(squareCropImageURL)
        } else {
          resolve(resizedImageURL)
        }
      }
    }
  })
}

// Create AWS S3 url and then upload image to this url
export const uploadAWSFileReactS3 = async (dataURL, bucketType, fileName) => {

  // Data URL to blob to file
  const blob = await dataURItoBlob(dataURL)
  const file = await blobToFile(blob, fileName)

  // Object to upload to AWS S3 server to create an image URL
  const requestObject = {
    bucketType: bucketType,
    fileName: fileName ,
  }

  try {
    // Upload data to server, retrieve a reserved image URL from the AWS S3 bucket
    // const { data } = await axios.put(`/api/awsImageURL`, requestObject, {
    const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/awsImageURL`, requestObject, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    })

    let finalURL = 'No URL' //Don't change this name because it is used elsewhere in the code
    
    // Upload the file to the reserved AWS S3 URL
    await fetch(data.url , {
      method: 'PUT',
      body: file,
    }).then((res) => {
      // set finalURL equal to the part of the string before the question mark
      finalURL = res.url.substring(0, res.url.indexOf('?'))
    })

    // Return the finalURL
    return finalURL

  } catch (error) {
    console.log(error)
    return 'No URL' //Don't change this name because it is used elsewhere in the code
  }
}