/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { emailTo } from './sendViews/emailTo'
import { successfulSend } from './sendViews/successfulSend'
import { inProcessAllAuth, createGoogleSignInButton } from '../../../helpers/inProcessLoginRegister'
import { userIsAuthenticated, getPayload, getTokenFromLocalStorage, getUsernameFromLocalStorage, getEmailFromLocalStorage, getProfPicFromLocalStorage } from '../../../helpers/storage'
import { uploadAWSFileReactS3 } from '../../../helpers/imageHandling'
import { profPicDefaultURL } from '../../../helpers/variableDefaults'
import { customAnalyticsEvent } from '../../../helpers/analytics'
import { reloadView } from '../../../helpers/globalHelpers'

import useWindowDimensions from '../../../helpers/windowDimensions'

import { standardSpinner } from '../../../helpers/spinners'

import Box from '@mui/material/Box'


const Send = (props) => {

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate // Here only to make the global inProcessAuth view work, not used by this view
  const navigate = useNavigate()

  // Payload
  const payload = getPayload()

  // Destructure Props
  const { send, setSend, sendViewIndex, setSendViewIndex, 
    typeChosen, giftOptions, questions, note,
    boxType, wrapping, ribbon, envelope, cardText, environment, 
    setActiveBottomStep, backArrow, forwardArrow,
    continueAsGuest, setContinueAsGuest,
  } = props

  // Email/password Login
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
    loginMethod: 'emailLogin',
    acceptedTOS: true,
  })
  const [ loginErrors, setLoginErrors ] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)

  // Email/Password Register
  const [registerForm, setRegisterForm] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    loginMethod: 'emailRegister',
    acceptedTOS: true,
  })
  const [ registerErrors, setRegisterErrors ] = useState(false)
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [acceptedTOS, setAcceptedTOS] = useState(false)

  // For toggling between Login and Register screens
  const [loginViewIndex, setLoginViewIndex] = useState(0)

  // Google Login
  const [ loadingGoogle, setLoadingGoogle ] = useState(false)
  const [ googleLoginErrors, setGoogleLoginErrors ] = useState(false)

  // Facebook Login
  const [ loadingFacebook, setLoadingFacebook ] = useState(false)
  const [ facebookLoginErrors, setFacebookLoginErrors ] = useState(false)

  // Only to make the inProcessAuth consistent for use across multiple views, not used in this view
  const [authenticated, setAuthenticated] = useState(false)

  // Security code and Gift Link
  const [securityCode, setSecurityCode] = useState('')
  const [giftLink, setGiftLink] = useState('')

  // non-login-related Loading and errors
  const [loading, setLoading] = useState(false)
  const [ sendErrors, setSendErrors ] = useState(false)

  // For auto-filling the email address on the first showing of the emailTo page
  const [ loginOptionChosen, setLoginOptionChosen ] = useState(false)
  

  // Consolidatates the accumulated data into a Gift object to upload
  const createGiftObject = async () => {

    // Filter for 'checked' questions, and then delete the 'checked' property for each 'question'
    const filteredAndMappedQuestions = questions.filter(question => {
      return question.checked === true
    }).map( question => {
      delete question.checked
      return question
    })

    // Upload product images to AWS and receive the links
    const editedGiftOptions = [ ...giftOptions ]
    for (let i = 0; i < editedGiftOptions.length; i++) {
      for (let x = 0; x < editedGiftOptions[i].length; x++) {
        if (!editedGiftOptions[i][x].image.includes('s3.amazonaws.com')) {
          editedGiftOptions[i][x].image = await uploadAWSFileReactS3(editedGiftOptions[i][x].image, 'product', `${getEmailFromLocalStorage() ? getEmailFromLocalStorage().slice(0, getEmailFromLocalStorage().indexOf('@')) : send.senderEmail.length > 0 ? send.senderEmail.slice(0, send.senderEmail.indexOf('@')) : getUsernameFromLocalStorage() ? getUsernameFromLocalStorage() : 'guest'}-${Date.now()}`)
        }

        //If picture fails to upload, make the 3D Wrap logo the default
        if (editedGiftOptions[i][x].image === 'No URL') {
          editedGiftOptions[i][x].image = profPicDefaultURL
        }

        // Set link to image link if 'No gift link'
        if (editedGiftOptions[i][x].link === 'No gift link') {
          editedGiftOptions[i][x].link = editedGiftOptions[i][x].image
        }
      }
    }

    // Make security code; Random 6-digit string
    let securityCodeString = ''
    for (let i = 0; i < 6; i++) {
      const randomDigitString = Math.floor(Math.random() * 10).toString()
      securityCodeString = securityCodeString + randomDigitString
    }
    setSecurityCode(securityCodeString)

    // Return the organized Gift object
    const giftToReturn = {
      type: typeChosen,
      presentType: 'basicPresent',
      securityCode: securityCodeString,
      note: note,
      wasOpened: false,
      options: editedGiftOptions,
      package: {
        confettiColor: environment.confettiColor,
        boxType: boxType,
        wrappingType: wrapping.type,
        wrappingColor: wrapping.color,
        ribbonColor: ribbon,
        envelopeColor: envelope,
        backgroundColor: environment.backgroundColor,
        cardText: cardText,
      },
      feedback: {
        qAndA: filteredAndMappedQuestions,
      },
      price: {
        total: '0',
        elements: {
          allElse: '0',
          tax: '0',
        },
      },
    }

    // If user is logged in, add in sender identification, if not, the defaults will be created in the backend
    if (userIsAuthenticated()) {
      giftToReturn.sender = payload.sub
      giftToReturn.senderUsername = getUsernameFromLocalStorage()
      giftToReturn.senderEmail = (send.senderEmail.length > 0) ? send.senderEmail : getEmailFromLocalStorage()
      giftToReturn.senderProfilePicture = getProfPicFromLocalStorage()
    } else {
      giftToReturn.senderEmail = (send.senderEmail.length > 0) ? send.senderEmail : 'No Email'
    }

    // If recipient email is given, add this into the giftToReturn; if not, the default of 'No Email' will be created on the backend
    if (send.recipientEmail.length > 0) {
      giftToReturn.recipientEmail = send.recipientEmail
    }


    return giftToReturn
  }

  // Create autoEmail objects and upload them to the server upon sending the present; Called in handleSendPresent
  const sendSpecifiedEmails = (giftId, giftSecurityCode) => {
    
    // Don't send emails if user selects copyLinkOnly
    if (!send.copyLinkOnly) {
      
      // Object to upload to mailgun in server
      let autoEmailObject = {}

      // Define the autoEmailObject
      if (send.recipientEmail.length > 0 && send.senderEmail.length > 0) {
        // If the sender or recipient email exists, create an auto-email object

        autoEmailObject = {
          emailTo: 'sender and recipient',
          senderName: cardText.senderName,
          recipientName: cardText.recipientName,
          senderEmailAddress: send.senderEmail,
          recipientEmailAddress: send.recipientEmail,
          link: `www.3dwrap.com/unwrap/${giftId}`,
          securityCode: giftSecurityCode,
        }

      } else if (send.senderEmail.length > 0) {
        // If only emailing to the sender

        autoEmailObject = {
          emailTo: 'sender only',
          senderName: cardText.senderName,
          recipientName: cardText.recipientName,
          senderEmailAddress: send.senderEmail,
          link: `www.3dwrap.com/unwrap/${giftId}`,
          securityCode: giftSecurityCode,
        }
      } else if (send.recipientEmail.length > 0) {
        // Emailing only to recipient
        
        autoEmailObject = {
          emailTo: 'recipient only',
          senderName: cardText.senderName,
          recipientName: cardText.recipientName,
          recipientEmailAddress: send.recipientEmail,
          link: `www.3dwrap.com/unwrap/${giftId}`,
          securityCode: giftSecurityCode,
        }

      }

      // Upload autoEmailObject to mailgun, catch error if unsuccessful
      try {
        // axios.post('/api/autoEmail/', autoEmailObject, {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/autoEmail/`, autoEmailObject, {})
      } catch (err) {
        console.log('err ->', err)
        console.log('auto email failed')
      }
    }
  }

  // Executes the 'Send Another' button from successfulSend.js
  const handleSendAnother = async (e) => {
    e.preventDefault()

    // Reset Loading and Errors
    setLoading(false)
    setSendErrors(false)

    // Reloading /sendgift brings User back to the start of the send gift process
    reloadView()
  }

  // Executes send options selections, from emailTo view
  const handleSetSend = async (e) => {
    e.preventDefault()

    // get name and value from the event target
    const { name, value } = e.target

    // destructure the send object
    const editedSend = { ...send }

    // Edit the send object appropriately
    if (name === 'recipientEmail') {
      editedSend.copyLinkOnly = false
      editedSend.recipientEmail = value
    } else if (name === 'senderEmail') {
      editedSend.copyLinkOnly = false
      editedSend.senderEmail = value
    } else if (name === 'copyLinkOnly') {
      editedSend.copyLinkOnly = !editedSend.copyLinkOnly
      editedSend.senderEmail = ''
      editedSend.recipientEmail = ''
    } 

    // set the send object with editedSend
    setSend(editedSend)
  }

  // Executes uploading the created Gift to the server
  const handleSendPresent = async (e) => {
    e.preventDefault()

    // Only upload Gift to server if user is authenticated, else direct user to login/register view
    if (userIsAuthenticated() || continueAsGuest) {
        
      // Set loading to true
      setLoading(true)

      // create a Gift object that's ready to upload to the server
      const objectToUpload = await createGiftObject()

      // If there's an object to upload, try uploading it to the server
      if (objectToUpload) {

        try {

          // POST Gift object to server
          // const response = await axios.post('/api/gifts/', objectToUpload, {
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/gifts/`, objectToUpload, {})

          // Set the gift link, appears in successfulSend.js and in the auto emails
          setGiftLink(`www.3dwrap.com/unwrap/${response.data._id}`)

          //Send Gift-Created Emails
          sendSpecifiedEmails(response.data._id, objectToUpload.securityCode)

          // Navigate to successfulSend view
          setSendViewIndex(1)

          // Update activeBottomStep so they all appear completed
          setActiveBottomStep(4)
  
          // Set loading and send errors to false
          setLoading(false)
          setSendErrors(false)

        } catch (err) {
          console.log(err)
  
          // set loading to false errors to true if the email or password is wrong
          setLoading(false)
          setSendErrors(true)

        }
      } else {
  
        // Set loading to false errors to true if the email or password is missing
        setLoading(false)
        setSendErrors(true)

      }
    } else {
      // Not authenticated

      // Set Loading to False
      setLoading(false)

      // Direct User to inProcessAllAuth
      setSendViewIndex(2)
    }
  }

  // Loads Google Sign-In Button and collects analytics
  useEffect(() => {

    // Instantiate GSI Button
    createGoogleSignInButton('wrap', setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated)

    // Collect Analytics
    customAnalyticsEvent(
      sendViewIndex === 0 ? 'send_gift_emailTo' : sendViewIndex === 1 ? 'send_gift_success' : 'send_gift_login', 
      sendViewIndex === 0 ? 'send_gift_emailTo' : sendViewIndex === 1 ? 'send_gift_success' : 'send_gift_login', 
      'send_gift_process', 
      sendViewIndex === 0 ? 'send_gift_emailTo' : sendViewIndex === 1 ? 'send_gift_success' : 'send_gift_login', 
      'Send Gift'
    ) 

  }, [sendViewIndex])
  

  return (
    <>
      {/* Email To */}
      {sendViewIndex === 0 && emailTo(send, setSend, handleSetSend, handleSendPresent, sendErrors, backArrow, forwardArrow, width, loading, setLoading, loginOptionChosen, setLoginOptionChosen)}

      {/* Successful Send */}
      {sendViewIndex === 1 && successfulSend(send, securityCode, handleSendAnother, giftLink, send.senderEmail.length > 0 ? send.senderEmail : '', send.recipientEmail.length > 0 ? send.recipientEmail : '', width)}

      {/* Login */}
      {sendViewIndex === 2 
        && 
        <Box
          sx={{
            mt: 4,
            pb: 8,
            maxHeight: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {(loadingGoogle || loadingFacebook) ?
            standardSpinner()
            : 
            <>
              {inProcessAllAuth(
                'wrap', 
                loginViewIndex,
                googleLoginErrors, facebookLoginErrors, setGoogleLoginErrors, setFacebookLoginErrors, setLoadingGoogle, setLoadingFacebook,
                loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin,
                registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS,
                setLoginViewIndex, navigate, setSendViewIndex, setAuthenticated, setContinueAsGuest
              )}
            </>
          }
        </Box>
      }
    </>
  )
}

export default Send