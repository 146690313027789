import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../helpers/windowDimensions.js'

// Components
import ShowPresent from '../canvas/ShowPresent.jsx'
import ShowGift from '../unwrap/ShowGift.js'

// Helpers
import { socialMediaButtons } from '../../helpers/buttons.js'
import { userIsAuthenticated } from '../../helpers/storage.js'
import { profPicDefaultURL, navbarHeight, confettiColorDefault, canvasBgDefaultColorUnwrap, cardTextColorDefault, ribbonColorDefault, envelopeColorDefault, wrappingColorDefault, boxTypeDefault, wrapTypeDefault, positionChangeWidthSm, positionChangeHeightSm, positionChangeWidthMd, bgColors, wrapColors, ribbonColors, confettiColors, envelopeColors, cardTextColors, wrapTypes, boxTypes, giftTypes } from '../../helpers/variableDefaults.js'
import { standardButton } from '../../helpers/buttons.js'
import { standardSpinner } from '../../helpers/spinners.js'
import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics.js'
import { randomPackage, randomGiftOptions, giftObjectDefault } from '../../helpers/randomGift.js'

// mui
import Box from '@mui/material/Box'
import { requestError } from '../../helpers/errors.js'


// Example
const Example = (props) => {

  // use Naviage
  const navigate = useNavigate()

  // Window Dimensions
  const { height, width } = useWindowDimensions()
  const bottomButtonsDisplacement = width >= positionChangeWidthSm ? '40px' : '120px'

  //loading and error state
  const [loading, setLoading] = useState(false) 
  const [errors, setErrors] = useState(false)

  const [finishedUnwrap, setFinishedUnwrap] = useState(false)
  const [revealGift, setRevealGift] = useState(false)

  // Package Design
  const [confettiColor, setConfettiColor] = useState(confettiColorDefault)
  const [backgroundColor, setBackgroundColor] = useState(canvasBgDefaultColorUnwrap)
  const [cardText, setCardText] = useState({
    salutation: '',
    recipientName: '',
    message: '',
    signOff: '',
    senderName: '',
    color: cardTextColorDefault,
  })
  const [envelope, setEnvelope] = useState(envelopeColorDefault)
  const [boxType, setBoxType] = useState(boxTypeDefault)
  const [wrappingColor, setWrappingColor] = useState(wrappingColorDefault)
  const [wrappingType, setWrappingType] = useState(wrapTypeDefault)
  const [ribbon, setRibbon] = useState(ribbonColorDefault)

  // Default gift object
  const [gift, setGift] = useState(giftObjectDefault())

  // If user is authenticated, navigate to /giftideas
  // If not authenticated, create a random package and gift for unwrapping
  useEffect(() => {

    // Design a random package
    randomPackage(gift, cardText, setWrappingColor, setRibbon, setEnvelope, setCardText, setConfettiColor, setWrappingType, setBoxType, setGift)

    // Retrieve gift ideas and create a random giftObject
    const getData = async () => {
      try {
        // Retrieve gift ideas
        // const { data } = await axios.get('/api/giftIdeas')
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/giftIdeas`)

        // Create a random gift to be revealed
        randomGiftOptions(data, gift, setGift)

      } catch (error) {
        // console.log(error)
        setErrors(true)
      }
    }

    getData()

  }, [navigate])

  // Navigate to /sendgift when Send Gift button Clicked
  const handleSendGift = async (e) => {
    e.preventDefault()
    navigate('/sendgift')
  }

  return (
    <>
      {/* Helmet — for Page Title, Analytics, and SEO */}
      {seoPageTags(
        'Example'
      )}

      {/* Example — Body */}
      <Box
        // alignItems={'stretch'}
        sx={{
          // backgroundColor: 'orange',
          // height: (width < positionChangeWidthSm || height < positionChangeHeightSm) ? `calc(100vh - ${navbarHeight} - 48px)` : `calc(100vh - ${navbarHeight})`,
          // maxHeight: (width < positionChangeWidthSm || height < positionChangeHeightSm) ? `calc(100vh - ${navbarHeight} - 48px)` : `calc(100vh - ${navbarHeight})`,
          height: `calc(100vh - ${navbarHeight})`,
          maxHeight: `calc(100vh - ${navbarHeight})`,
          // height: `calc(100% - ${navbarHeight})`,
          // maxHeight: `calc(100% - ${navbarHeight})`,
          // height: '100%',
          // maxHeight: '100%',
          overflow: 'auto',
          position: 'fixed',
          width: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {
          loading ?
            standardSpinner()
            : 
            errors ?
              requestError()
              :
              <Box 
                sx={{ 
                  height: (width < positionChangeWidthSm || height < positionChangeHeightSm) ? `calc(100% - ${navbarHeight})` : '100%',
                  maxHeight: (width < positionChangeWidthSm || height < positionChangeHeightSm) ? `calc(100% - ${navbarHeight})` : '100%',
                  // height: `calc(100% - ${navbarHeight})`,
                  // maxHeight: `calc(100% - ${navbarHeight})`,
                  // backgroundColor: 'pink',
                  width: '100%',
                  // height: '100%',
                  // maxHeight: '100%',
                }}
              >
                <ShowPresent 
                  finishedUnwrap={finishedUnwrap} setFinishedUnwrap={setFinishedUnwrap} setRevealGift={setRevealGift}
                  confettiColor={confettiColor} backgroundColor={backgroundColor} cardText={cardText} envelope={envelope} ribbon={ribbon} wrappingType={wrappingType} wrappingColor={wrappingColor} boxType={boxType} 
                />
                {finishedUnwrap 
                && revealGift 
                &&
                  <ShowGift 
                    finishedUnwrap={finishedUnwrap} setFinishedUnwrap={setFinishedUnwrap} 
                    gift={gift} setGift={setGift}
                  />
                }
              </Box>
        }

        {/* Buttons */}
        <Box 
          sx={{ 
            width: '100vw',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
            position: 'absolute', bottom: bottomButtonsDisplacement,
          }}
        >
          <Box 
            sx={{ 
              width: '90vw', 
              display: 'flex', flexDirection: 'row', alignItems: 'center',
              justifyContent: width >= positionChangeWidthSm ? 'space-between' : 'flex-end',  
            }}
          >
            
            {/* Send Button */}
            { width >= positionChangeWidthSm && 
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {standardButton(
                  'Send Gift', 
                  'button',
                  'contained',
                  false,
                  'secondary', 
                  0,
                  0,
                  0,
                  '150px', 
                  '55px', 
                  handleSendGift
                )}
              </Box>
            }

            {/* Social Media Buttons */}
            {socialMediaButtons('row', 'flex-end', 'center', 0, false)}
            
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Example