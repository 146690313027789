import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import AddIcon from '@mui/icons-material/Add'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import GiftItem from './GiftItem'

import { Input } from '../../../helpers/globalHelpers'
import useWindowDimensions from '../../../helpers/windowDimensions'


const GiftOptions = (props) => {

  // Destructure Props
  const { giftOptions, setGiftOptions, typeChosen, allGiftOptionElementsAdded, allSingleOptionElementsAdded } = props

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  //Upload errors
  const [putErrors, setPutErrors] = useState(false)

  // Handler for adding a new Gift Option
  const handleAddOption = async (e) => {
    e.preventDefault()

    // Destructure giftOptions array
    const newArray = [ ...giftOptions ]

    // Push a new option onto the newArray
    newArray.push(
      [
        {
          link: '',
          image: '',
          brand: '',
          product: '',
        }
      ]
    )

    // setGiftOptions with the newArray
    setGiftOptions(newArray)
  }

  // Handler for adding a new item within a giftOption
  const handleAddItem = async (e, optionIndex) => {
    e.preventDefault()

    // Destructure giftOptions array
    const newArray = [ ...giftOptions ]

    // Add a new item onto the selected option's index
    newArray[optionIndex].push(
      {
        link: '',
        image: '',
        brand: '',
        product: '',
      }
    )

    // setGiftOptions with the newArray
    setGiftOptions(newArray)
  }

  // Handler for deleting a giftOption
  const handleDeleteOption = async (e) => {
    e.preventDefault()

    // Get the selectedOptionIndex from the event currentTarget classList
    const selectedOptionIndexString = e.currentTarget.classList[0].charAt(0)
    const selectedOptionIndex = parseInt(selectedOptionIndexString)

    // Destructure giftOptions array
    const newArray = [ ...giftOptions ]
    
    // Splice the newArray at the selectedOptionIndex
    if (selectedOptionIndex > -1) { 
      newArray.splice(selectedOptionIndex, 1)
    }

    // setGiftOptions with the newArray
    setGiftOptions(newArray)
  }


  return (
    <>

      {/* This must be first input So that the file upload only fires when you press the button */}
      <Box sx={{ width: '100%' }}>
        <Input type="text" autoFocus="autoFocus" />
      </Box>

      {/* All Gift Options Container Grid */}
      <Grid key={0} container spacing={0} 
        sx={{ 
          borderBottom: '1px solid #e1e1e1',
          width: '100%', 
          maxWidth: '100vw', 
          height: '100%', 
          maxHeight: '70vh',
          display: 'flex', flexDirection: 'row', justifyContent: typeChosen === 'chooseOne' ? 'flex-start' : 'center', alignItems: 'flex-start', flexWrap: 'nowrap',
          // overflow: 'auto',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            // -webkit-appearance: none,
            WebkitAppearance: 'none',
            width: '7px',
            height: giftOptions.length > 2 ? '7px' : width < 500 && giftOptions.length > 1 ? '7px' : '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
          },
        }}
      >
        {/* If there's at least one giftOption, map out the options */}
        {giftOptions[0] && giftOptions.map((option, optionIndex) => (
          
          // Grid for Individual Option
          <Grid 
            key={optionIndex} 
            align="center" 
            item 
            xs={3}
            sx={{ 
              backgroundColor: optionIndex % 2 !== 0 ? '#C5C5C5' : '#E5E4E2',
              boxShadow: 3,
              mt: 1, ml: 1, mb: 1,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', 
              width: '200px',
              minWidth: '200px', 
              maxWidth: '200px', 
              minHeight: '95%',
            }}
          >
            {/* Gift Option */}

            {/* Title */}
            {typeChosen === 'chooseOne' &&
              <Box 
                sx={{ 
                  width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', 
                }}
              >
                
                {/* Option Title */}
                <Typography align='center' sx={{ mb: 0, mt: 1 }}><strong>Option {optionIndex + 1}</strong></Typography>

                {/* Delete option button if more than two options */}
                {optionIndex > 1 &&
                  <Box key={`${optionIndex}`} className={`${optionIndex}-index`} name={`${optionIndex}-index`} onClick={handleDeleteOption} sx={{ borderRadius: '50%', display: 'flex', alignItems: 'center' }} >
                    <IconButton 
                      key={`${optionIndex}`} 
                      aria-label="delete-option" 
                      name={`${optionIndex}-index`}
                      sx={{ 
                        width: '30px', height: '30px', ml: 1, bottom: 0, left: 0, border: 2, borderColor: 'white', boxShadow: 3, 
                      }} 
                    >
                      <CloseOutlinedIcon 
                        key={`${optionIndex}`} 
                        name={`${optionIndex}-index`}
                        fontSize="small" 
                        color='error' 
                        sx={{  }} 
                      />
                    </IconButton>
                  </Box>
                }
              </Box>
            }

            {/* Gift Items */}
            {giftOptions[optionIndex][0] && giftOptions[optionIndex].map((item, itemIndex) => (  
              
              <Box key={itemIndex} 
                sx={{
                  mt: typeChosen === 'oneItem' && itemIndex === 0 ? 1 : 0,
                  width: '100%',
                }}
              >
                <GiftItem setPutErrors={setPutErrors} setGiftOptions={setGiftOptions} giftOptions={giftOptions} optionIndex={optionIndex} itemIndex={itemIndex} allGiftOptionElementsAdded={allGiftOptionElementsAdded} allSingleOptionElementsAdded={allSingleOptionElementsAdded}/>
              </Box>
            ))}

            {/* Add Item button at bottom of Option grid */}
            {allSingleOptionElementsAdded(optionIndex) &&
              <Box key={5} 
                sx={{ 
                  mb: 1,
                  width: '100%', 
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
                }}
              >
                <Button 
                  key={7} 
                  onClick={(e) => handleAddItem(e, optionIndex)}
                  color='primary'
                >
                  ADD ANOTHER ITEM
                </Button>
              </Box>
            }
          </Grid>
        ))}

        {/* Add Option Button */}
        {typeChosen === 'chooseOne' 
        // && allGiftOptionElementsAdded() 
        &&
          <Grid 
            key={13} 
            item
            xs={2}
            alignItems={'stretch'}
            sx={{ 
              // mt: 18.5, 
              // mt: 7.7,
              // mt: 1,
              height: '100%',
              mb: 0, minWidth: '60px',
              display: 'flex', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            <IconButton 
              disabled={!allGiftOptionElementsAdded()}
              color='primary' 
              key={15} 
              onClick={handleAddOption} 
              aria-label="add option" 
              sx={{ boxShadow: 3, mb: 0 }}
            >
              <AddIcon />
            </IconButton>
          </Grid> 
        }
      </Grid>
    </>
  )
}

export default GiftOptions