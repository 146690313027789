
import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

// Checkboxes when choosing questions during wrap process
export const questionChoiceCheckbox = (disabled, name, checked, clickHandler, question, questionIndex) => {
  return (
    <FormControlLabel 
      control={
        <Checkbox 
          disabled= {disabled}
          name={name}
          checked={checked}
          onClick={(e) => clickHandler(e, question, questionIndex)}
          sx={{ pl: 2, width: '40px' }} />
      } 
    />
  )
}

// Checkboxes when choosing a gift when receiving a chooseOne gift
export const chooseGiftCheckbox = (disabled, checked, clickHandler, optionIndex) => {
  return (
    <FormControlLabel 
      control={
        <Checkbox 
          disabled= {disabled}
          size="small"
          checked={checked}
          onClick={(e) => clickHandler(e, optionIndex)}
        />
      } 
      sx={{
        ml: '16px',
        width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    />
  )
}

// Full Checkbox and text elements for choosing who to send copy-links to in emailTo.js
export const emailToCheckboxElement = (name, value, text, clickHandler, stateObj, setStateObj, textAlign, marginLeft) => {
  return (
    <Box key={name} 
      sx={{ 
        pl: 1, pr: 1,
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap',
        overflow: 'visible',
      }}
    >
      {/* Checkbox */}
      <FormControlLabel 
        control={
          <Checkbox 
            disabled={false}
            value={value} 
            name={name}
            checked={value}
            onClick={(e) => clickHandler(e, stateObj, setStateObj)}
            sx={{ pl: 2, width: '40px' }} />
        } 
      />
      
      {/* Option Text */}
      <Typography 
        variant='p' 
        textAlign={textAlign}
        sx={{ 
          ml: marginLeft, mr: 0, width: 'calc(100% - 60px)', 
          // backgroundColor: 'orange',
        }}
      >
        {ReactHtmlParser(text)}
      </Typography>
    </Box>
  )
}