/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import Types from './gift/Types'
import Options from './gift/Options'
import Questions from './gift/Questions'
import Note from './package/Note'

import BoxType from './package/BoxType'
import Wrapping from './package/Wrapping'
import Ribbon from './package/Ribbon'
import Envelope from './package/Envelope'
import CardText from './package/CardText'
import Environment from './package/Environment'

import Review from './review/Review'
import Send from './send/Send'

import Unavailable from '../common/Unavailable'

import useWindowDimensions from '../../helpers/windowDimensions'

import { incrementIndex, decrementIndex } from '../../helpers/globalHelpers'
import { resetSendSpecificGift } from '../../helpers/sendSpecificGift'
import { bottomSteps, canvasBgDefaultColorWrap, confettiColorDefault, positionChangeWidthSm, questionsOneItemDefaultArray, boxTypeDefault, wrapTypeDefault, wrappingColorDefault, ribbonColorDefault, envelopeColorDefault, positionChangeWidthMd, permittedCountryCodes } from '../../helpers/variableDefaults'
import { arrowElement } from '../../helpers/buttons'
import { packageViewMobileStepperAndArrows } from '../../helpers/mobileSteppers'
import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'
import { userIsAuthenticated } from '../../helpers/storage'

// Send Gift Page
const SendGift = (props) => {

  // Destructure Props
  const { sendSpecificGift, setSendSpecificGift, countryCode } = props
  // console.log('countryCode ->', countryCode)
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Stepper
  const [activeBottomStep, setActiveBottomStep] = useState(0)
  const [miniStepsGift, setMiniStepsGift] = useState(sendSpecificGift.link.length > 0 ? 1 : 0)
  const [miniStepsPackage, setMiniStepsPackage] = useState(0)

  // Gift States
  const [typeChosen, setTypeChosen] = useState(sendSpecificGift.link.length > 0 ? 'oneItem' : '')
  const [giftOptions, setGiftOptions] = useState([
    [
      {
        link: sendSpecificGift.link.length > 0 ? sendSpecificGift.link : '',
        image: sendSpecificGift.image.length > 0 ? sendSpecificGift.image : '',
        brand: sendSpecificGift.brand.length > 0 ? sendSpecificGift.brand : '',
        product: sendSpecificGift.product.length > 0 ? sendSpecificGift.product : '',
      }
    ]
  ])
  resetSendSpecificGift(sendSpecificGift, setSendSpecificGift)

  const [questions, setQuestions] = useState(sendSpecificGift.link.length > 0 ? questionsOneItemDefaultArray : [])
  

  // Package States
  const [forwardArrowBgColor, setForwardArrowBgColor] = useState('white')
  const [blinkCount, setBlinkCount] = useState(0)
  const [boxType, setBoxType] = useState(boxTypeDefault)
  const [wrapping, setWrapping] = useState({
    type: wrapTypeDefault,
    color: wrappingColorDefault,
  })
  const [ribbon, setRibbon] = useState(ribbonColorDefault)
  const [envelope, setEnvelope] = useState(envelopeColorDefault)
  const [cardText, setCardText] = useState({
    salutation: '',
    recipientName: '',
    message: '',
    signOff: '',
    senderName: '',
    color: envelopeColorDefault,
  })
  const [environment, setEnvironment] = useState({
    backgroundColor: canvasBgDefaultColorWrap,
    confettiColor: confettiColorDefault,
  })
  const [note, setNote] = useState('')

  // Preview and Send
  const [send, setSend] = useState({
    recipientEmail: '',
    senderEmail: '',
    copyLinkOnly: false,
  })
  const [sendViewIndex, setSendViewIndex] = useState(userIsAuthenticated() ? 0 : 2)
  const [ continueAsGuest, setContinueAsGuest ] = useState(false) // Continue as Guest Indicator

  // Called in forwardArray 'disabled'
  // Makes sure all gift options/items have a link, image, brand, and product filled in for the forward arrow to be enabled in the Options view
  const allGiftOptionElementsAdded = () => {
    // console.log('allGiftOptionElementsAdded runs')

    let totalItems = 0 // Count of total items
    let totalCompletedItems = 0 // Count of items with link, image, brand, and product filled in

    for (let i = 0; i < giftOptions.length; i++) {
      
      for (let x = 0; x < giftOptions[i].length; x++) {

        totalItems = totalItems + 1 // Increment totalItems

        if (
          (giftOptions[i][x].hasOwnProperty('link') && giftOptions[i][x].link) 
          && (giftOptions[i][x].hasOwnProperty('image') && giftOptions[i][x].image) 
          && (giftOptions[i][x].hasOwnProperty('brand') && giftOptions[i][x].brand)
          && (giftOptions[i][x].hasOwnProperty('product') && giftOptions[i][x].product)
        ) {
          totalCompletedItems = totalCompletedItems + 1 // Increment totalCompletedItems
        }
      }
    }

    return totalItems === totalCompletedItems //Must be true for forward arrow to be enabled
  }

  const allSingleOptionElementsAdded = (optionIndex) => {
    // console.log('allSingleOptionElementsAdded runs')

    let totalItems = 0 // Count of total items
    let totalCompletedItems = 0 // Count of items with link, image, brand, and product filled in
      
    for (let i = 0; i < giftOptions[optionIndex].length; i++) {

      totalItems = totalItems + 1 // Increment totalItems

      if (
        (giftOptions[optionIndex][i].hasOwnProperty('link') && giftOptions[optionIndex][i].link) 
        && (giftOptions[optionIndex][i].hasOwnProperty('image') && giftOptions[optionIndex][i].image) 
        && (giftOptions[optionIndex][i].hasOwnProperty('brand') && giftOptions[optionIndex][i].brand)
        && (giftOptions[optionIndex][i].hasOwnProperty('product') && giftOptions[optionIndex][i].product)
      ) {
        totalCompletedItems = totalCompletedItems + 1 // Increment totalCompletedItems
      }
    }

    // console.log('total items ->', totalItems)
    // console.log('total completed items ->', totalCompletedItems)
    return totalItems === totalCompletedItems //Must be true for forward arrow to be enabled
  }

  // Back Arrow Click Handler
  const handleBack = () => {
    if (activeBottomStep === 0 && miniStepsGift > 0) {
      decrementIndex(miniStepsGift, setMiniStepsGift)
      setMiniStepsPackage(0)
    } else if (activeBottomStep === 1 && miniStepsPackage === 0) {
      setActiveBottomStep(0)
      setMiniStepsGift(2)
    } else if (activeBottomStep === 1) {
      decrementIndex(miniStepsPackage, setMiniStepsPackage)
    } else if (activeBottomStep > 1) {
      decrementIndex(activeBottomStep, setActiveBottomStep)
    }
  }

  // Back Arrow
  const backArrow = arrowElement(
    'back',
    miniStepsGift === 0, 
    handleBack
  )
  
  // Forward Arrow Click Handler
  const handleNext = () => {
    if (activeBottomStep === 0 && miniStepsGift === 2) {
      setActiveBottomStep(1)
      setMiniStepsPackage(0)

      // Collect Analytics
      customAnalyticsEvent(
        'send_gift_questions', 
        'send_gift_questions', 
        'send_gift_process', 
        'send_gift_questions', 
        'Send Gift'
      )
    } else if (activeBottomStep === 0) {
      incrementIndex(miniStepsGift, setMiniStepsGift)

      if (miniStepsGift === 0) {
        setForwardArrowBgColor('lightyellow')
      }

      // Collect Analytics
      customAnalyticsEvent(
        miniStepsGift === 0 ? 'send_gift_type' : 'send_gift_items', 
        miniStepsGift === 0 ? 'send_gift_type' : 'send_gift_items', 
        'send_gift_process', 
        miniStepsGift === 0 ? 'send_gift_type' : 'send_gift_items', 
        'Send Gift'
      )

    } else if (activeBottomStep === 1 
      && miniStepsPackage === 6 
    ) {
      setActiveBottomStep(2)

      // Collect Analytics
      customAnalyticsEvent(
        'send_gift_note', 
        'send_gift_note', 
        'send_gift_process', 
        'send_gift_note', 
        'Send Gift'
      )

    } else if (activeBottomStep === 1) {
      incrementIndex(miniStepsPackage, setMiniStepsPackage)

      // Collect Analytics
      customAnalyticsEvent(
        miniStepsPackage === 0 ? 'send_gift_box' : miniStepsPackage === 1 ? 'send_gift_wrapping' : miniStepsPackage === 2 ? 'send_gift_ribbon' : miniStepsPackage === 3 ? 'send_gift_ribbon' : miniStepsPackage === 3 ? 'send_gift_envelope' : 'send_gift_environment', 
        miniStepsPackage === 0 ? 'send_gift_box' : miniStepsPackage === 1 ? 'send_gift_wrapping' : miniStepsPackage === 2 ? 'send_gift_ribbon' : miniStepsPackage === 3 ? 'send_gift_ribbon' : miniStepsPackage === 3 ? 'send_gift_envelope' : 'send_gift_environment', 
        'send_gift_process', 
        miniStepsPackage === 0 ? 'send_gift_box' : miniStepsPackage === 1 ? 'send_gift_wrapping' : miniStepsPackage === 2 ? 'send_gift_ribbon' : miniStepsPackage === 3 ? 'send_gift_ribbon' : miniStepsPackage === 3 ? 'send_gift_envelope' : 'send_gift_environment', 
        'Send Gift'
      )

    } else if (activeBottomStep === 2) {
      setActiveBottomStep(3)

      // Collect Analytics
      customAnalyticsEvent(
        'send_gift_review', 
        'send_gift_review', 
        'send_gift_process', 
        'send_gift_review', 
        'Send Gift'
      )
    }
  }

  const forwardArrow = arrowElement(
    'forward',
    (
      (activeBottomStep > 2)
      || (activeBottomStep === 0 && miniStepsGift === 0 && typeChosen.length === 0)
      || (activeBottomStep === 0 && miniStepsGift === 1 && !allGiftOptionElementsAdded()) 
      || (activeBottomStep === 1 && miniStepsPackage === 0 && boxType.length === 0) 
      || (activeBottomStep === 1 && miniStepsPackage === 1 && (wrapping.type.length === 0 || wrapping.color.length === 0)) 
      || (activeBottomStep === 1 && miniStepsPackage === 2 && ribbon.length === 0) 
      || (activeBottomStep === 1 && miniStepsPackage === 3 && envelope.length === 0)
      || (activeBottomStep === 1 && miniStepsPackage === 4 && (cardText.salutation.length === 0 || cardText.recipientName.length === 0 || cardText.message.length === 0 || cardText.signOff.length === 0 || cardText.senderName.length === 0 || cardText.color.length === 0))
      || (activeBottomStep === 1 && miniStepsPackage === 5 && (environment.backgroundColor.length === 0 || environment.confettiColor.length === 0))  
      || (activeBottomStep === 1 && miniStepsPackage === 6 && note.length === 0) 
    ),
    handleNext,
    forwardArrowBgColor
  )

  // Forward Arrow blinking
  useEffect(() => {
    if (activeBottomStep === 0 && miniStepsGift === 0 && typeChosen.length > 0 && blinkCount < 101) {
      
      setForwardArrowBgColor(blinkCount === 100 ? 'lightyellow' : forwardArrowBgColor !== 'yellow' ? 'yellow' : 'white')
      
      setTimeout(() => {
        setBlinkCount(blinkCount + 1)
      }, 300)
    }
  }, [blinkCount])

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Send Gift'
      )}

      {/* Send Gift Body Elements */}
      <Box sx={{ 
        width: '100vw',
        // backgroundColor: 'yellow',
        height: width < positionChangeWidthMd  && (miniStepsPackage === 1 || miniStepsPackage === 4 || activeBottomStep === 2) ? 'none' : 'calc(100vh - 80px)',
        overflow: 'auto',
        display: 'flex', flexDirection: 'column', 
        justifyContent: width < positionChangeWidthMd ? 'flex-start' : 'space-around', 
        alignItems: 'center', 
      }}>
        
        <Box sx={{ 
          width: '95%', 
          maxWidth: 'md',
          height: width < positionChangeWidthMd && (miniStepsPackage === 1 || miniStepsPackage === 4 || activeBottomStep === 2) ? 'none' : '75vh',
          // backgroundColor: 'yellow',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
        }}>
          
          {/* Back Arrow */}
          {/* {activeBottomStep < 4 && width >= positionChangeWidthSm &&
            backArrow
          } */}

          {/* Main Selection Area */}
          <Paper sx={{ 
            boxShadow: 3, 
            // backgroundColor: 'yellow',
            width: '100vw', 
            maxWidth: '100vw',
            minHeight: '70vh',
            height: width < positionChangeWidthMd && (miniStepsPackage === 1 || miniStepsPackage === 4 || activeBottomStep === 2) ? 'none' : '70vh',
            maxHeight: width < positionChangeWidthMd && (miniStepsPackage === 1 || miniStepsPackage === 4 || activeBottomStep === 2) ? 'none' : '70vh',
            overflow: 'auto',
            overflowY: 'scroll',
            // '&::-webkit-scrollbar': {
            //   WebkitAppearance: 'none',
            //   width: '7px',
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   borderRadius: '4px',
            //   backgroundColor: 'rgba(0, 0, 0, .35)',
            //   webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            // },
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', 
          }}>
            
            {/* Gift */}
            {activeBottomStep === 0 && miniStepsGift === 0 && 
              <Types typeChosen={typeChosen} setTypeChosen={setTypeChosen} setGiftOptions={setGiftOptions} setQuestions={setQuestions} blinkCount={blinkCount} setBlinkCount={setBlinkCount}/>
            }
            {activeBottomStep === 0 && miniStepsGift === 1 && 
              <Options giftOptions={giftOptions} setGiftOptions={setGiftOptions} typeChosen={typeChosen} allGiftOptionElementsAdded={allGiftOptionElementsAdded} allSingleOptionElementsAdded={allSingleOptionElementsAdded}/>
            }
            {activeBottomStep === 0 && miniStepsGift === 2 && 
              <Questions questions={questions} setQuestions={setQuestions} typeChosen={typeChosen}/>
            }

            {/* Package */}
            {activeBottomStep === 1 && miniStepsPackage === 0 && 
              <BoxType 
                boxType={boxType} setBoxType={setBoxType} backArrow={backArrow} forwardArrow={forwardArrow}
                wrapping={wrapping} ribbon={ribbon} envelope={envelope} cardText={cardText} environment={environment} 
                miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}
              />
            }
            {activeBottomStep === 1 && miniStepsPackage === 1 && 
              <Wrapping 
                wrapping={wrapping} setWrapping={setWrapping} backArrow={backArrow} forwardArrow={forwardArrow}
                boxType={boxType} ribbon={ribbon} envelope={envelope} cardText={cardText} environment={environment} 
                setRibbon={setRibbon} setEnvelope={setEnvelope} setEnvironment={setEnvironment} setCardText={setCardText}
                miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}
              />
            }
            {activeBottomStep === 1 && miniStepsPackage === 2 && 
              <Ribbon 
                ribbon={ribbon} setRibbon={setRibbon} setEnvironment={setEnvironment} backArrow={backArrow} forwardArrow={forwardArrow}
                boxType={boxType} wrapping={wrapping} envelope={envelope} cardText={cardText} environment={environment} 
                setEnvelope={setEnvelope} setCardText={setCardText}
                miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}
              />
            }
            {activeBottomStep === 1 && miniStepsPackage === 3 && 
              <Envelope 
                envelope={envelope} setEnvelope={setEnvelope} setCardText={setCardText} backArrow={backArrow} forwardArrow={forwardArrow}
                boxType={boxType} wrapping={wrapping} ribbon={ribbon} cardText={cardText} environment={environment} 
                miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}
              />
            }
            {activeBottomStep === 1 && miniStepsPackage === 4 && 
              <CardText 
                cardText={cardText} setCardText={setCardText} backArrow={backArrow} forwardArrow={forwardArrow}
                boxType={boxType} wrapping={wrapping} ribbon={ribbon} envelope={envelope} environment={environment} 
                miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}
              />
            }
            {activeBottomStep === 1 && miniStepsPackage === 5 && 
              <Environment 
                environment={environment} setEnvironment={setEnvironment} backArrow={backArrow} forwardArrow={forwardArrow}
                boxType={boxType} wrapping={wrapping} ribbon={ribbon} envelope={envelope} cardText={cardText} 
                miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}
              />
            }
            {activeBottomStep === 1 && miniStepsPackage === 6 && 
              <Note 
                note={note} setNote={setNote}
                width={width} miniStepsPackage={miniStepsPackage} backArrow={backArrow} forwardArrow={forwardArrow}
              />
            }

            {/* Review */}
            {activeBottomStep === 2 &&
              <Review 
                backArrow={backArrow} forwardArrow={forwardArrow}
                typeChosen={typeChosen} giftOptions={giftOptions} questions={questions} note={note}
                boxType={boxType} wrapping={wrapping} ribbon={ribbon} envelope={envelope} cardText={cardText} environment={environment} 
                miniStepsPackage={miniStepsPackage} activeBottomStep={activeBottomStep}
              />
            }

            {/* Sent */}
            {activeBottomStep > 2 && 
              (permittedCountryCodes.includes(countryCode) ?
                <Send 
                  send={send} setSend={setSend} sendViewIndex={sendViewIndex} setSendViewIndex={setSendViewIndex} 
                  typeChosen={typeChosen} giftOptions={giftOptions} questions={questions} note={note}
                  boxType={boxType} wrapping={wrapping} ribbon={ribbon} envelope={envelope} cardText={cardText} environment={environment} 
                  setActiveBottomStep={setActiveBottomStep} backArrow={backArrow} forwardArrow={forwardArrow}
                  continueAsGuest={continueAsGuest} setContinueAsGuest={setContinueAsGuest}
                />
                :
                <Unavailable />
              )
            }

            {/* Mini steps */}
            {activeBottomStep < 1 &&
              packageViewMobileStepperAndArrows(width, miniStepsGift, backArrow, forwardArrow, false, true)
            }

          </Paper>

          {/* Forward Arrow */}
          {/* {activeBottomStep < 4 && width >= positionChangeWidthSm &&
            forwardArrow
          } */}

        </Box>
        
        {/* Bottom Steps */}
        <Box 
          sx={{ width: '100%', pb: 2, 
            // backgroundColor: 'orange',
            pt: width < positionChangeWidthMd 
              && (miniStepsPackage === 1 || miniStepsPackage === 4 || activeBottomStep === 2) 
              ? 
              3 : 0, 
          }}
        >
          <Stepper alternativeLabel activeStep={activeBottomStep}>
            {bottomSteps.map((label) => (
              <Step key={label}> 
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </>
  )
}

export default SendGift