import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { noteTextTypography } from '../../helpers/typographies'
import { seoPageTags } from '../../helpers/analytics'
import { videoInsert } from '../../helpers/videos'
import useWindowDimensions from '../../helpers/windowDimensions'

// FAQ Page
const FAQ = (props) => {

  // Window Dimensions
  const { height, width } = useWindowDimensions()
  // console.log('width ->', width)

  // URL Path
  const urlPath = window.location.pathname

  // Standard Question and Answer Element
  // Here and not in a helper file for convenience — it only appears here
  const questionAnswerComponent = (question = '', answer = '', expandedDefault = false, isVideo = false, videoLink = '', blogLink = '', altText = '', cardWidth = '95%' , width = 0) => {
    // console.log('isVideo ->', isVideo)
    // console.log('cardWidth ->', cardWidth)
    
    return (
      // Instantiate an Accordion
      <Accordion
        defaultExpanded={expandedDefault}
        sx={{ 
          width: '100%',
          boxShadow: 4, 
        }}
      >
        {/* Question appears on Accordion Summary */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: 'lightyellow',
            border: 2,
            borderBottom: 2,
            borderColor: 'plum',
          }}
        >
          <Typography>
            <strong>{question}</strong>
          </Typography>
        </AccordionSummary>

        {/* Answer Appears in Accordion Details */}
        <AccordionDetails
          sx={{
            backgroundColor: 'lavender',
            border: 2,
            borderTop: 0,
            borderColor: 'plum',
            display: 'flex', flexDirection: 'column',
          }}
        >
          {!isVideo && noteTextTypography(answer, '18px', 'Lato')}
          {isVideo && videoInsert(videoLink, altText, cardWidth, width)}
          {blogLink.length > 0 &&
            <Typography sx={{ mt: 4, mb: 2, fontSize: '18px' }}>
              For more on this subject, check out <a target='#' href={blogLink} style={{ color: 'purple' }}  >this post on the 3D Wrap blog</a>.
            </Typography>
          }
        </AccordionDetails>

      </Accordion>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {urlPath === '/faq' && 
        seoPageTags(
          'FAQ'
        )
      }

      {/* FAQ Body */}
      <Container sx={{ 
        // backgroundColor: 'orange',
        // height: `calc(100vh - ${navbarHeight})`, 
        width: '100vw', 
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
      }}>

        {/* This Box is necessary for scrolling and view height normalization */}
        <Box
          sx={{
            // backgroundColor: 'orange',
            width: '100vw',
            height: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >

          {/* Box sets max width and new background color */}
          <Box
            sx={{
              backgroundColor: 'whitesmoke',
              boxShadow: 4, 
              borderRadius: 1,
              mb: urlPath === '/faq' ? 4 : 6, mt: 4, pt: urlPath === '/faq' ? 4 : 2,
              width: urlPath === '/faq' ? '80vw' : '95%',
              maxWidth: urlPath === '/faq' ? 600 : 'md',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >

            {/* Box sets smaller interior width for the question accordions */}
            <Box
              sx={{ 
                pb: 5,
                width: .90, 
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Title */}
              {urlPath === '/' && 
                <Typography>
                  <h3>FAQ</h3>
                </Typography>
              }

              {/* FAQ Accordions */}

              {/* Panel 1 */}
              {questionAnswerComponent(
                'What is 3D Wrap?',
                '3D Wrap is a digital platform for wrapping and opening gifts online, in 3D.',
                false
              )}

              {/* Panel 2 */}
              {questionAnswerComponent(
                'How do I wrap a gift?',
                '',
                false,
                true,
                'https://www.youtube.com/embed/yMRTvrLg10g',
                'https://medium.com/@3dwrap/how-to-wrap-a-gift-digitally-with-3d-wrap-e51cfb348a98',
                'How To Wrap a Single Gift',
                '100%',
                width
              )}

              {/* Panel 3 */}
              {questionAnswerComponent(
                'How do I unwrap a gift?',
                '',
                false,
                true,
                'https://www.youtube.com/embed/WgMlh1mhyas',
                'https://medium.com/@3dwrap/how-to-unwrap-a-gift-digitally-with-3d-wrap-bacd2ae53e55',
                'How To Unwrap a Single Gift',
                '100%',
                width
              )}

              {/* Panel 4 */}
              {questionAnswerComponent(
                'How do I wrap a choose-one gift?',
                '',
                false,
                true,
                'https://www.youtube.com/embed/zW7Of3SpjhM',
                'https://medium.com/@3dwrap/how-to-float-ideas-for-a-present-without-ruining-the-surprise-42b969126b8d',
                'How To Wrap a Choose-One Gift',
                '100%',
                width
              )}

              {/* Panel 5 */}
              {questionAnswerComponent(
                'How do I unwrap a choose-one gift?',
                '',
                false,
                true,
                'https://www.youtube.com/embed/55BQs-tDgTc',
                'https://medium.com/@3dwrap/how-to-give-a-gift-the-recipient-will-definitely-want-9a6009fbd3b1',
                'How To Unwrap a Choose-One Gift',
                '100%',
                width
              )}

              {/* Panel 6 */}
              {questionAnswerComponent(
                'Why use 3D Wrap?',
                ReactHtmlParser(`— 3D Wrap allows for an unwrapping experience when <a target="#" href="https://medium.com/@3dwrap/the-easiest-way-to-wrap-a-present-61b0a335dab0" style="color:purple"}>physical wrapping isn't possible</a>, such as when a gift arrives late, or when it's a digital item, like a giftcard. \n— 3D Wrap allows a sender to ask <a target="#" href="https://medium.com/@3dwrap/how-to-give-a-gift-the-recipient-will-definitely-want-9a6009fbd3b1" style="color:purple"}>questions to the recipient</a> about things like size, color, and shipping address before actually purchasing the gift, thereby reducing the likelihood that it'll be returned without sacrificing the experience of opening a box with a surprise inside it. \n— 3D Wrap allows you to <a target="#" href="https://medium.com/@3dwrap/how-to-float-ideas-for-a-present-without-ruining-the-surprise-42b969126b8d" style="color:purple"}>send a present with multiple ideas for gifts</a>, where the recipient can choose their favorite. \n— If you want to <a target="#" href="https://medium.com/@3dwrap/the-greenest-most-eco-friendly-way-to-wrap-a-present-e28d823be375" style="color:purple"}>give in an eco-friendly way</a>, 3D Wrap doesn't use paper and will reduce the likelihood of returns.`),
                false
              )}
              
              {/* Panel 7 */}
              {questionAnswerComponent(
                'Is it free to send a 3Dwrap?',
                `Yes, consider it our gift to you.`,
                false
              )}
              
              {/* Panel 8 */}
              {questionAnswerComponent(
                'What is a Choose-One gift?',
                `If you have multiple gift ideas, and you aren't sure which is the best, you can send a present with all of the ideas and allow the recipient to choose their favorite.`,
                false,
                false,
                '',
                'https://medium.com/@3dwrap/how-to-float-ideas-for-a-present-without-ruining-the-surprise-42b969126b8d',
                '',
                '',
                width
              )}
              
              
              {/* Panel 9 */}
              {questionAnswerComponent(
                'Is 3D Wrap good for the planet?',
                `Well, 3D Wrap definitely saves paper. It also stands to reason that asking about things like size, color, and shipping address before purchasing a gift will reduce returns, thereby reducing emissions and waste.`,
                false,
                false,
                '',
                'https://medium.com/@3dwrap/the-greenest-most-eco-friendly-way-to-wrap-a-present-e28d823be375',
                '',
                '',
                width
              )}


            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default FAQ