
import React from 'react'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import GiftIdeasCard from '../components/giftIdeas/GiftIdeasCard.js'
import GiftsCard from '../components/user/GiftsCard.js'
import FriendListItem from '../components/friends/FriendListItem.js'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import StarIcon from '@mui/icons-material/Star'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import SendIcon from '@mui/icons-material/Send'
import PropTypes from 'prop-types'

import { standardSpinner } from './spinners.js'
import { positionChangeWidthSm } from './variableDefaults.js'


// Called in UserProfile.js wishlist tab and in GiftIdeas.js
// Creates an array of GiftIdeasCard.js elements with different numbers of cards per line depending on the view width
export const populateWishlistTab = (fromComponent = '', wishlistArray = [], setSendSpecificGift, setWishlist, width) => {

  return (
    <Grid key={0} container spacing={width < positionChangeWidthSm ? 0 : 2} 
      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}
    >
      {wishlistArray.map((wishlistedItem, index) => (
        <Grid 
          key={index} 
          align="center" 
          item 
          xs={12} 
          sm={wishlistArray.length > 1 ? 6 : 12}
          md={wishlistArray.length > 2 ? 4 : wishlistArray.length > 1 ? 6 : 12}
        >
          <GiftIdeasCard giftIdea={wishlistedItem} setSendSpecificGift={setSendSpecificGift} wishlist={wishlistArray} setWishlist={setWishlist} />
        </Grid>
      ))}
    </Grid>
  )
}

// Called in UserProfile.js Given and Received tabs
// Creates an array of GiftsCard.js elements with different numbers of cards per line depending on the view width
export const populateGivenOrReceivedTab = (giftsArray = [], setSendSpecificGift, username, myUsername) => {

  return (
    <Grid key={0} container spacing={2}>
      {giftsArray.map((giftedItem, index) => (
        <Grid 
          key={index} 
          align="center" 
          item 
          xs={12} 
          sm={giftsArray.length > 1 ? 6 : 12}
          md={giftsArray.length > 2 ? 4 : giftsArray.length > 1 ? 6 : 12}
        >
          <GiftsCard gift={giftedItem} setSendSpecificGift={setSendSpecificGift} username={username} myUsername={myUsername} />
        </Grid>
      ))}
    </Grid>
  )
}

// Called in the UserProfile.js Friends tab and the FindFriends.js search results
// Creates an array of FriendListItem.js elements with different buttons on the righthand side depending on the view and the friend status of the users
export const populateFriendsTab = (friendsArray = [], source = '') => {

  // Depending on whether its from UserProfile or FindFriends, the 'friend' input into FriendListItem will need different parameters
  const getFriendListItemInput = (friendItem) => {
    const finalUsernameAndProfilePicture = {}
    if (source === 'userProfile') {
      // friends array from userProfile is a list of Friend objects
      finalUsernameAndProfilePicture.username = friendItem.friendProfile.username
      finalUsernameAndProfilePicture.profilePicture = friendItem.friendProfile.profilePicture
      finalUsernameAndProfilePicture.requestStatus = friendItem.requestStatus
      finalUsernameAndProfilePicture.requestedBy = friendItem.requestedBy
      finalUsernameAndProfilePicture.requestId = friendItem._id
    } else {
      // friends array from FindFriends is a custom object
      finalUsernameAndProfilePicture.username = friendItem.username
      finalUsernameAndProfilePicture.profilePicture = friendItem.profilePicture
      finalUsernameAndProfilePicture.id = friendItem.id
      finalUsernameAndProfilePicture.isFriend = friendItem.isFriend
    }
    // console.log('final username and profile picture ->', finalUsernameAndProfilePicture)
    return finalUsernameAndProfilePicture
  } 

  return (
    <>
      {friendsArray.map((friend, index) => (
        <Paper key={index} sx={{ borderRadius: 0, borderBox: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1, alignItems: 'center' }}>
          {index > 0 &&
            <Divider key={`${index}-1`} sx={{ width: '100%', mt: 0, mb: 0 }} />
          }
          <FriendListItem friend={getFriendListItemInput(friend)} source={source} />
        </Paper>
      ))}
    </>
  )
}

// User Profile Tab Panels

// Tab Panel Element
function TabPanel(props) {
  const { children, value, index, ...other } = props
  const numberValue = parseFloat(value)

  return (
    <div
      role="tabpanel"
      hidden={numberValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {numberValue === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

// Standardized Tab Element
// Used for Wishlist, Given, Received, and Friends tabs on UserProfile.js
export const tabElement = (panelName, panelArray, setPanelArray, clickHandler, value, index, loading, errors, username, myUsername, accountPrivate, showWishlist, showGiftsGiven, showGiftsReceived, showFriends, setSendSpecificGift, width) => {

  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Container>
          : accountPrivate && username !== myUsername ?
            <>
              <Typography variant='p'>
                {username} has a private account
              </Typography>
            </>

            : 
            (
              (panelName === 'wishlist' && showWishlist === false)
              || (panelName === 'given' && showGiftsGiven === false) 
              || (panelName === 'received' && showGiftsReceived === false)
              || (panelName === 'friends' && showFriends === false)
            ) 
            && username !== myUsername ?
              <>
                {panelName === 'wishlist' && <Typography variant='p'>{username} has chosen not to show their wishlist</Typography>}
                {panelName === 'given' && <Typography variant='p'>{username} has chosen not to show gifts given</Typography>}
                {panelName === 'received' && <Typography variant='p'>{username} has chosen not to show gifts received</Typography>}
                {panelName === 'friends' && <Typography variant='p'>{username} has chosen not to show their friends list</Typography>}
              </>
              : 
              <>
                {/* Add Top Button */}
                {username === myUsername &&
                  <Box key={13} sx={{ mt: 0, mb: 2, display: 'flex', justifyContent: 'center' }}>
                    <IconButton color='primary' key={15} onClick={clickHandler} aria-label="add to favorites" sx={{ boxShadow: 3, mb: 0 }}>
                      {panelName === 'wishlist' ? 
                        <StarIcon /> 
                        : 
                        // panelName === 'friends' ? 
                        //   <PersonAddAlt1Icon /> 
                        //   : 
                        <SendIcon />
                      }
                    </IconButton>
                  </Box> 
                }
              

                {panelArray.length > 0 ?
                  <>

                    {/* Panel Items */}
                    {panelName !== 'friends' &&
                      <Box sx={{ 
                        mb: 4,
                        maxWidth: panelArray.length > 2 ? '850px' : panelArray.length > 1 ? '570px' : '300px', 
                        display: 'flex', justifyContent: 'center', flexGrow: 1, alignItems: 'center',
                      }}>
                        {panelName === 'wishlist' && populateWishlistTab('userProfile', panelArray, setSendSpecificGift, setPanelArray, width)}
                        {(panelName === 'given' || panelName === 'received') && populateGivenOrReceivedTab(panelArray, setSendSpecificGift, username, myUsername)}
                      </Box>
                    }
                    <Box sx={{ mb: 4 }}>
                      {panelName === 'friends' && populateFriendsTab(panelArray, 'userProfile')}
                    </Box>
                    
                    
                  </>
                  : username === myUsername ?
                    <Box 
                      sx={{ 
                        maxWidth: '350px', 
                      }}
                    >
                      {panelName === 'wishlist' && <Typography variant='p'>Click the star on items you like on the <a href='/giftideas' style={{ color: 'mediumorchid' }}>Gift Ideas</a> page to add them to your wishlist</Typography>}
                      {panelName === 'given' && <Typography variant='p'>You have not given any gifts yet, you Grinch.</Typography>}
                      {panelName === 'received' && <Typography variant='p'>You have not received any gifts yet. Send your wishlist to some friends to change that.</Typography>}
                      {panelName === 'friends' && <Typography variant='p'>Friending automatically occurs when two people with profiles exchange a 3Dwrap. If you exchanged a gift with a “guest” of the site, they will appear as a friend once they create a profile.</Typography>}
                    </Box>
                    :
                    <Box 
                      sx={{ 
                        maxWidth: '350px', 
                      }}
                    >
                      {panelName === 'wishlist' && <Typography variant='p'>{username} has not added any items to their wishlist</Typography>}
                      {panelName === 'given' && <Typography variant='p'>{username} is a Grinch and has not given any gifts yet</Typography>}
                      {panelName === 'received' && <Typography variant='p'>{username} has not received any gifts yet. So what are you waiting for? Go ahead, be the first!</Typography>}
                      {panelName === 'friends' && <Typography variant='p'>{username} has no friends yet. Send them a gift to be their first!</Typography>}
                    </Box>
                }
              </>
      }
    </TabPanel>
  )
}