/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'

import { angleToRadians } from '../../../helpers/animations'

export const messageOnCardElements = (position, yRotation, textMesh, fullMessage, textOptions, color) => {
  return (
    <mesh
      ref={textMesh} 
      position={position} 
      rotation={[0, angleToRadians(yRotation), 0]}
    >
      <textGeometry attach='geometry' args={[fullMessage, textOptions]}/>
      <meshLambertMaterial 
        attach='material' 
        color={color ? color : '#ffd700'}
      />
    </mesh>
  )
}